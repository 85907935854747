import { Component } from '@angular/core';
import { QuestionFacade } from '../../../../core/facade/question.facade';
import { totalElec } from '../../../../core/state/state';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-product-summary',
  templateUrl: './product-summary.component.html',
  styleUrls: ['./product-summary.component.scss'],
})
export class ProductSummaryComponent {
  zipCode$: Observable<number>;
  averagePeak$: Observable<number>;
  gas$: Observable<number>;
  elec$: Observable<number>;
  injections$: Observable<number>;

  constructor(private readonly questionFacade: QuestionFacade) {
    this.zipCode$ = this.questionFacade.findSiteData((site) => site.zipCode);
    this.averagePeak$ = this.questionFacade.findSiteData((site) => site.averagePeak);
    this.gas$ = this.questionFacade.findSiteData((site) => site.consumptions.gasConsumption);
    this.elec$ = this.questionFacade.findSiteData((site) => totalElec(site.consumptions));
    this.injections$ = this.questionFacade.findSiteData(
      (site) =>
        (Number(site.consumptions.injectionLow) || 0) +
        (Number(site.consumptions.injectionHigh) || 0) +
        (Number(site.consumptions.injectionMono) || 0)
    );
  }

}
