<div class="px-8 xl:max-w-screen-xl xl:w-full xl:m-auto">
  <app-title class="block w-full max-w-5xl mx-auto text-center"
             title="pages.tooMuchSites.title"
             subtitle="pages.tooMuchSites.subtitle"></app-title>
  <div class="flex flex-col md:flex-row md:justify-center mt-12 gap-8 md:gap-16 mb-12">
    <app-card (contentClicked)="onPhoneClicked()" [card]="contactTelCard"></app-card>
    <app-card (contentClicked)="onEmailClicked()" [card]="contactMailCard"></app-card>
  </div>
  <app-navigation [stepValidity]="true"
                  [automaticTransition]="false"
                  (nextClicked)="onNextClicked()"
                  (previousClicked)="onPreviousClicked()">
  </app-navigation>
</div>
