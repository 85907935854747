import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-form-card',
    templateUrl: './form-card.component.html',
    styleUrls: ['./form-card.component.scss']
})
export class FormCardComponent {

    @Input() icon: string;
    @Input() iconTopPercent = -10;
    @Input() iconLeftPercent = 47;

}
