import { HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { captureMessage } from '@sentry/angular-ivy';
import { Injectable } from '@angular/core';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    return next.handle(request).pipe(
      retry(1),
      catchError((error: HttpErrorResponse) => {
        let errorMessage = '';

        if (error.error instanceof ErrorEvent) {
          // client-side error
          errorMessage = `Error: ${error.error.message}`;
        } else {
          // server-side error
          errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }

        // catch the localities API errors by the component for showing input fields error message
        if (error.url && error.url.includes('localities')) {
          return throwError(error);
        }

        // Send error to Sentry
        captureMessage(errorMessage);
        // const eventId = Sentry.captureException(error.originalError || error);
        // Sentry.showReportDialog({ eventId });

        return throwError(errorMessage);
      }),
    );
  }
}
