import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SiteDisplay } from '../../results/sites/sites.component';

@Component({
  selector: 'app-site-details-multisite-wrapper',
  templateUrl: './site-details-multisite-wrapper.component.html',
  styleUrls: ['./site-details-multisite-wrapper.component.scss']
})
export class SiteDetailsMultisiteWrapperComponent  {

  @Output() deleteClicked = new EventEmitter<SiteDisplay>();
  @Output() updateSiteName = new EventEmitter<SiteDisplay>();
  @Input() siteDisplay: SiteDisplay;
  @Input() siteNumber: number;
}

