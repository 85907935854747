<footer class="bg-white py-6">
  <div class="px-8 text-sm xl:max-w-screen-xl xl:w-full xl:m-auto md:flex">
    <div class="text-center flex-1 md:text-left">{{ 'footer.copyright' | translate: { value: year } }}</div>
    <ul class="text-center md:text-right">
      <li class="md:inline-block md:ml-16" [innerHTML]="'footer.legal.link1.'+( (b2b$ | async) ? 'b2b': 'b2c') | translate"></li>
      <li class="md:inline-block md:ml-16" [innerHTML]="'footer.legal.link2.'+( (b2b$ | async) ? 'b2b': 'b2c') | translate"></li>
      <li class="md:inline-block md:ml-16" [innerHTML]="'footer.legal.link3.'+( (b2b$ | async) ? 'b2b': 'b2c') | translate"></li>
    </ul>
  </div>
</footer>
