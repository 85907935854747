<div class="relative inline-block w-full h-full">
  <div @fadeInFadeOut [hidden]="load">
    <section class="content-wrapper mb-12 md:mb-40 md:-mt-10 md:p-0 p-16">
      <div class="text-center">
        <h2
          class="mb-4 font-display font-normal text-4xl te-text-gradient-home inline-block w-full"
          [innerHTML]="'pages.result.title' | translate"
        ></h2>
        <span class="inline-block w-full">
          {{ 'pages.result.becomeClientTitle' | translate }}
          <button class="btn--inline" (click)="scrollToMoreInfos()">{{ 'pages.result.moreInfo' | translate }}</button>
        </span>
      </div>
      <div class="mt-12">
        <app-alert
          *ngIf="showFriendReferalPromoAlert"
          content="pages.result.friendReferalDiscount.content"
          title="pages.result.friendReferalDiscount.title"
        ></app-alert>
      </div>
    </section>

    <section class="bg-white py-0 md:py-20">
      <div class="content-wrapper">
        <app-bundles (goToOlReg)="onGoToOlreg($event.code, $event.totalAmount)"> </app-bundles>
        <div class="mt-8">
          <app-product-summary></app-product-summary>
        </div>
        <app-b2c-contact></app-b2c-contact>
      </div>
    </section>

    <section class="content-wrapper py-12 md:py-20 text-grey-dark">
      <div class="mb-12">
        <h3 class="text-3xl font-medium pb-8">
          {{ 'pages.result.infosAndConditions.title' | translate }}
        </h3>
        <p>
          {{ 'pages.result.infosAndConditions.simulationDate' | translate: currentDate }}
        </p>
        <ng-container *ngIf="isBrussels$ | async">
          {{ 'footer.disclaimer.sibelga' | translate }}
        </ng-container>
      </div>
      <div #moreInfoAnchor>
        <h3 class="text-2xl font-medium mb-8">
          {{ 'pages.result.infosAndConditions.howIsCalculateTitle' | translate }}
        </h3>
        <div class="column-2">
          <span [innerHTML]="'pages.result.infosAndConditions.b2c.infosAndConditions1' | translate"></span>
          <span [innerHTML]="'pages.result.infosAndConditions.b2c.infosAndConditions2' | translate"></span>
        </div>
      </div>
    </section>
  </div>
  <app-loader *ngIf="load"></app-loader>
</div>
