<div class="flex-1 w-full py-40 px-8 text-center items-center relative mt-16 md:mt-0">
  <img src="./assets/img/unhappy-bg.svg" alt="error" class="unhappy unhappy1" />
  <img src="./assets/img/unhappy-bg.svg" alt="error" class="unhappy unhappy2" />
  <img src="./assets/img/unhappy-logo.png" alt="error" class="mx-auto mb-8" />
  <h2
    class="mb-8 font-display font-normal text-4xl te-text-gradient-home inline-block w-full md:mb-8"
    [innerHTML]="errorTitle | translate"
  ></h2>
  <ng-content></ng-content>
</div>
