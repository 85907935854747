import { Component, EventEmitter, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { QuestionFacade } from '../../../core/facade/question.facade';
import { Observable } from 'rxjs';
import { TypeDetectorService } from '../../../core/service/type-detector.service';
import { Type } from '../../../core/state/state';
import { map, switchMap, tap } from 'rxjs/operators';
import { BundlesListService } from './bundles-list.service';
import { Bundles, BundleDetails, BundleType } from 'src/app/core/bundle/bundle';

@Component({
  selector: 'app-bundles',
  templateUrl: './bundles-list.component.html',
  styleUrls: ['./bundles-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class BundlesListComponent implements OnInit {
  readonly isB2c = TypeDetectorService.detectType() === Type.B2C;
  @Output() goToOlReg = new EventEmitter<{ code: string; totalAmount: number }>();

  bundles$: Observable<Bundles[]>;

  bundlesDetails$: Observable<BundleDetails[]>;
  bundles: Bundles[];
  private readonly maxDisplayedBundles = 3;

  constructor(
    public translateService: TranslateService,
    private readonly questionFacade: QuestionFacade,
    private readonly bundlesListService: BundlesListService,
  ) {}

  ngOnInit(): void {
    this.bundlesDetails$ = this.bundlesListService.getBundles().pipe(
      tap((bundles) => (this.bundles = bundles)),
      map((bundles) =>
        bundles.filter((bundle) => (bundle.linkedBundle != null ? bundle.bundleType === BundleType.VARIABLE : true)),
      ),
      switchMap((bundles) => this.bundlesListService.getBundleDetails(bundles)),
      map((bundlesDetails: BundleDetails[]) => {
        // remove duplicates if exist
        bundlesDetails = bundlesDetails.filter(
          (bundleDetailValue: BundleDetails, index: number, self: BundleDetails[]) =>
            index === self.findIndex((bundleDetail) => bundleDetail.code === bundleDetailValue.code),
        );

        if (this.questionFacade.getCurrentSite().hasElectricVehicle) {
          // sort bundles with EV by weighting and slice bundles with high weighting (remove a new product)
          bundlesDetails = this.questionFacade
            .sortBundleDetailsByWeighting(bundlesDetails)
            .slice(-this.maxDisplayedBundles);
        } else {
          // sort bundles without EV by weighting and slice bundles with lower weighting (remove a mobility product)
          bundlesDetails = this.questionFacade
            .sortBundleDetailsByWeighting(bundlesDetails)
            .slice(0, this.maxDisplayedBundles);
        }

        return bundlesDetails;
      }),
    );
  }

  onDiscoverOfferClick(): void {
    window.location.href =
      this.translateService.currentLang === 'fr'
        ? 'https://totalenergies.be/fr/particuliers/produits-et-services/my-home/electricite-et-gaz/offre-electricite-et-gaz'
        : 'https://totalenergies.be/nl/particulieren/producten-en-diensten/my-home/elektriciteit-en-aardgas/aanbod-elektriciteit-en-aardgas';
  }

  onRetrySimulationCLik(): void {
    this.questionFacade.reset();
  }

  gotToOlReg(bundleType: BundleType, bundleDetails: Bundles): void {
    const bundle = this.getBundleOrBundleLinked(bundleType, bundleDetails) || bundleDetails;
    this.goToOlReg.emit({ code: bundle?.code, totalAmount: bundle?.electricityAmount + bundle?.gasAmount });
  }

  private getBundleOrBundleLinked(bundleType: BundleType, bundleDetails: Bundles): Bundles {
    return (
      this.bundles.length &&
      this.bundles.find(
        (bundle: Bundles) =>
          (bundle?.code === bundleDetails?.code || bundle?.linkedBundle?.code === bundleDetails?.code) &&
          bundle?.bundleType === bundleType,
      )
    );
  }
}
