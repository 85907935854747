<div class="xl:px-10 px-8 xl:max-w-screen-xl xl:w-full xl:m-auto">
    <app-title class="block w-full max-w-5xl mx-auto text-center"
               title="pages.multisite.title"
               subtitle="pages.multisite.subtitle"
    ></app-title>
    <ng-container *ngIf="sites$ | async as sites">
            <div class="sites-wrapper">
                <div class="sites-container">
                    <div class="item w-1/3" *ngFor="let site of sites, let i=index">
                        <app-site-rename-wrapper
                                *ngIf="siteWithRenameView === site"
                                (saveSiteName)="onSaveSiteName($event, i)"
                                [siteDisplay]="site"
                                [siteNumber]="i +1"
                                (cancel)="siteWithRenameView = null">

                        </app-site-rename-wrapper>
                        <app-site-details-multisite-wrapper
                                *ngIf="siteWithRenameView !== site"
                                [siteDisplay]="site"
                                (updateSiteName)="this.siteWithRenameView = site"
                                (deleteClicked)="onDeleteClicked(i)"
                                [siteNumber]="i +1">
                        </app-site-details-multisite-wrapper>
                    </div>
                    <div class="item 1/3 add">
                        <app-add-site *ngIf="sites.length < 8" (addClicked)="onAddClicked()"></app-add-site>
                    </div>
                </div>
            </div>
    </ng-container>
    <app-navigation
            *ngIf="(sites$ | async).length !== 0"
            nextLabel="navigation.computeMyPrice"
            [stepValidity]="true"
            [automaticTransition]="false"
            (nextClicked)="onNextClicked()"
            (previousClicked)="onPreviousClicked()">
    </app-navigation>
</div>



