<div class="fixed top-0 left-0 w-full h-full z-20">
    <div class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-10 w-full sm:max-w-2xl">
        <div class="relative mx-4">
            <ng-content></ng-content>
            <span class="close" (click)="closeSiteDetails()">
                <img src="./assets/img/cancel.svg" class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-10" alt="">
            </span>
        </div>
    </div>
    <div class="absolute bg-white bg-opacity-30 backdrop-blur-sm w-full h-full fixed top-0 left-0 w-full h-full "></div>

</div>

