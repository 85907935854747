import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function min(minValue: number): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) {
      return null;
    }
    const value = String(control.value).replace('+', '').replace('-', '').replace(',', '.');
    return Number(value) < minValue ? { min: true } : null;
  };
}

export function max(maxValue: number): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) {
      return null;
    }
    const value = String(control.value).replace('+', '').replace('-', '').replace(',', '.');
    return Number(value) > maxValue ? { max: true } : null;
  };
}

export function toNumber(target: string): number {
  if (!target) {
    return null;
  }
  return Number(String(target).replace('+', '').replace('-', '').replace(',', '.'));
}

export const snakeToCamel = (str: string): string =>
  str.toLowerCase().replace(/([-_][a-z])/g, (group) => group.toUpperCase().replace('-', '').replace('_', ''));

export const isBoolean = (value: boolean): boolean => typeof value === 'boolean';
