import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-te-switcher',
  templateUrl: './switcher.component.html',
  styleUrls: ['./switcher.component.scss'],
})
export class SwitcherComponent {
  @Input() options: string[];
  @Input() active: string;
  @Input() translationPrefix = '';
  @Input() isMobile? = false;
  @Input() outlined = true;

  @Output() switchAction: EventEmitter<string> = new EventEmitter<string>();

  switch(option: string) {
    this.switchAction.emit(option);
  }
}
