import { Component, OnInit } from '@angular/core';
import { InputRadioConfig } from '../../shared/custom-form-control/input-radio-wrapper/input-radio-wrapper.component';
import { BoilerFuelYear, BoilerYear, HeatingType, Site } from '../../core/state/state';
import { UntypedFormControl, Validators } from '@angular/forms';
import { QuestionFacade } from '../../core/facade/question.facade';
import { CompleteObservables } from 'src/app/shared/complete-observables';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-boiler-year',
  templateUrl: './boiler-year.component.html',
})
export class BoilerYearComponent extends CompleteObservables implements OnInit {
  readonly optionsGas = [
    BoilerYear.UNKNOWN,
    BoilerYear.BEFORE_1985,
    BoilerYear.BETWEEN_1986_2000,
    BoilerYear.BETWEEN_2001_2015,
    BoilerYear.AFTER_2016,
  ];
  readonly optionsFuel = [
    BoilerFuelYear.UNKNOWN,
    BoilerFuelYear.BEFORE_1975,
    BoilerFuelYear.BETWEEN_1976_AND_1985,
    BoilerFuelYear.BETWEEN_1986_AND_2000,
    BoilerFuelYear.AFTER_2001,
  ];
  options: InputRadioConfig[] = [];
  formControl: UntypedFormControl;
  site: Site;

  constructor(private readonly questionFacade: QuestionFacade) {
    super();
  }

  ngOnInit(): void {
    this.questionFacade
      .findSiteData((site) => site)
      .pipe(takeUntil(this.destroy$))
      .subscribe((site: Site) => {
        this.site = site;
        const choices =
          site.unknownConsumption.heatingEnergy.type === HeatingType.GAS ? this.optionsGas : this.optionsFuel;
        this.options = this.setOptions(choices);
        this.formControl = new UntypedFormControl(
          site?.unknownConsumption?.heatingEnergy?.installationDate,
          Validators.required,
        );
      });
  }

  onPreviousClicked(): void {
    this.questionFacade.previous();
  }

  onNextClicked(): void {
    const site = {
      ...this.site,
      unknownConsumption: {
        ...this.site?.unknownConsumption,
        heatingEnergy: {
          ...this.site?.unknownConsumption?.heatingEnergy,
          installationDate: this.formControl.value,
        },
      },
    };
    this.questionFacade.updateSiteData(site);
    this.questionFacade.next();
  }

  private setOptions(choices: BoilerYear[] | BoilerFuelYear[]): InputRadioConfig[] {
    return choices.map((choice: BoilerYear | BoilerFuelYear) => ({
      id: choice,
      value: choice,
      caption: `pages.unknownConsumptions.heatingEnergy.installationDate.choices.${choice.toLowerCase()}`,
    }));
  }
}
