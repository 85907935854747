<div class="block-group">
    <div class="block-contact">
        <div class="row">
            <div class="col-sm-12 col-md-6 block-contact__left">
                <div class="block-contact__title" [innerHTML]="'pages.result.becomeClientB2c' | translate">
                </div>
            </div>

            <div class="col-sm-12 col-md-6 block-contact__right">
                <p class="block-contact__phone">
                    <a [innerHTML]="'header.B2C.phone'| translate"></a>
                </p>
                <p class="block-contact__open-days" [innerHTML]="'header.B2C.openingHours.desktop.week' | translate"></p>
            </div>
        </div>
    </div>
</div>
