<div class="title-container inline-block bg-turquoise rounded-4xl p-8 w-full" [ngClass]="{'rounded-4xl' : fullRadius}">
    <div class="flex items-center">
          <span class="bg-white flex justify-center items-center w-20 h-20 rounded-full text-primary text-3xl font-display font-medium text-center sm:w-24 sm:h-24 sm:text-m">
            {{ numberToDisplay }}
          </span>
        <div class="flex-1 ml-8">
            <ng-container *ngIf="!siteDisplay?.site?.siteName; else showCustomName">
                <span class="text-sm text-grey-dark60 inline-block w-full font-medium sm:text-base">{{ 'pages.result.companyLocation' | translate }}</span>
                <span class="inline-block w-full text-l font-medium sm:text-l">{{'pages.result.zipCode' | translate}} - {{ siteDisplay.site.zipCode }}</span>
            </ng-container>
        </div>
        <ng-template #showCustomName>
            <span class="inline-block w-full text-m font-medium">
              {{ siteDisplay?.site?.siteName }}
            </span>
            <span class="text-grey-dark60 inline-block w-full">
                {{'pages.result.zipCode' | translate}} - {{ siteDisplay?.site?.zipCode }}
            </span>
        </ng-template>
        <div class="cursor-pointer" *ngIf="displayChevron">
            <img src="../../../../../assets/img/arrow-right.svg" alt="" />
        </div>
    </div>
</div>

