<div class="px-8 xl:max-w-screen-xl xl:w-full xl:m-auto" *ngIf="formGroup">
  <app-title
    [title]="
      (knowConsumption$ | async)
        ? 'pages.consumptions.title.comingFromKnown'
        : 'pages.consumptions.title.comingFromUnknown'
    "
    [subtitle]="getSubtitleText"
  >
  </app-title>
  <div class="my-2 center md:flex justify-center" [formGroup]="formGroup">
    <app-form-card
      class="mb-8 md:mb-0"
      [ngClass]="{ 'md:mr-16': hasEnergy(Energy.ELEC_GAS) }"
      icon="elecConsumption"
      *ngIf="hasEnergy(Energy.ELEC)"
    >
      <div class="form__error">
        <ng-container *ngIf="formGroup.invalid && formGroup.touched && formGroup.errors">
          <ng-container *ngIf="formGroup.errors['maxElecConsumptionValidity']">
            {{ 'pages.consumptions.electricity.error' | translate: { maxKwh: '50 000' } }}
          </ng-container>
        </ng-container>
      </div>
      <app-input-text
        [formControl]="hasMeter(MeterType.DUAL) ? elecConsumptionHigh : elecConsumptionMono"
        [id]="hasMeter(MeterType.DUAL) ? 'elecHighInput' : 'elecMonoInput'"
        [inputTextConfig]="hasMeter(MeterType.DUAL) ? ELEC_CONSUMPTION_HIGH_CONFIG : ELEC_CONSUMPTION_MONO_CONFIG"
      >
      </app-input-text>
      <app-input-text
        *ngIf="hasMeter(MeterType.DUAL)"
        id="elecLowInput"
        [formControl]="elecConsumptionLow"
        [inputTextConfig]="ELEC_CONSUMPTION_LOW_CONFIG"
      >
      </app-input-text>
      <app-input-text
        *ngIf="exclusiveNight$ | async"
        id="elecExcluNightInput"
        [formControl]="elecConsumptionExclusiveNight"
        [inputTextConfig]="ELEC_NIGHT_CONSUMPTION_CONFIG"
      >
      </app-input-text>
      <app-input-text
        *ngIf="showInjectionFields"
        [id]="hasMeter(MeterType.DUAL) ? 'elecInjectionHighInput' : 'elecInjectionMonoInput'"
        [formControl]="hasMeter(MeterType.DUAL) ? elecInjectionHigh : elecInjectionMono"
        [inputTextConfig]="hasMeter(MeterType.DUAL) ? INJECTION_CONSUMPTION_HIGH_CONFIG : INJECTION_CONSUMPTION_MONO_CONFIG"
      >
      </app-input-text>
      <app-input-text
        *ngIf="hasMeter(MeterType.DUAL) && showInjectionFields"
        id="elecInjectionLowInput"
        [formControl]="elecInjectionLow"
        [inputTextConfig]="INJECTION_CONSUMPTION_LOW_CONFIG"
      >
      </app-input-text>
    </app-form-card>

    <app-form-card icon="gasConsumption" iconLeftPercent="43" iconTopPercent="-12" *ngIf="hasEnergy(Energy.GAS)">
      <div class="form__error">
        <ng-container *ngIf="formGroup.invalid && formGroup.touched && formGroup.errors">
          <ng-container *ngIf="formGroup.errors['maxGasConsumptionValidity']">
            {{ 'pages.consumptions.gas.error' | translate: { maxKwh: '50 000' } }}
          </ng-container>
        </ng-container>
      </div>
      <app-input-text
        id="gasConsumptionInput"
        [formControl]="gasConsumption"
        [inputTextConfig]="GAS_CONSUMPTION_CONFIG"
      >
      </app-input-text>
    </app-form-card>
  </div>

  <app-navigation
    [stepValidity]="formGroup.valid"
    [automaticTransition]="false"
    (nextClicked)="onNextClicked()"
    (previousClicked)="onPreviousClicked()"
    [lastStep]="true"
  >
  </app-navigation>
</div>
