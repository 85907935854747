<div *ngIf="promoDetails" class="xl:max-w-screen-xl xl:w-full xl:m-auto">
  <div class="layout">
    <div>
      <img src="./assets/img/promo.png" alt="promo icon"  />
    </div>
    <div
        *ngIf="promoDetails?.title && promoDetails?.title[translateService?.currentLang]"
        class="text ml-5"
        [innerHTML]="promoDetails?.title[translateService?.currentLang]"
      >
    </div>
    <div class="ml-auto mt-2 relative pr-2">
      <app-tooltip [text]="promoDetails.description[translateService?.currentLang]"></app-tooltip>
    </div>
  </div>
</div>
