import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { InputTextConfig } from '../../../shared/custom-form-control/zip-code-input/input-text.component';
import { QuestionFacade } from '../../../core/facade/question.facade';
import { TypeDetectorService } from '../../../core/service/type-detector.service';
import { Type } from '../../../core/state/state';
import { max, min, toNumber } from '../../../core/utils';
import { takeUntil } from 'rxjs/operators';
import { CompleteObservables } from 'src/app/shared/complete-observables';

@Component({
  selector: 'app-solar-panel-kva',
  templateUrl: './solar-panel-kva.component.html',
  styleUrls: ['./solar-panel-kva.component.scss'],
})
export class SolarPanelKvaComponent extends CompleteObservables implements OnInit {
  // constants
  private readonly defaultValue = {
    b2c: 4.5,
    b2b: 6.1,
  };

  private readonly solarValueRatio = {
    min: 0.1,
    max_b2c: 10,
    max_b2b: 56,
  };

  private readonly appType = TypeDetectorService.detectType();

  solarRatio = {
    min: this.solarValueRatio.min,
    max: this.appType === Type.B2C ? this.solarValueRatio.max_b2c : this.solarValueRatio.max_b2b,
  };

  INPUT_CONFIG: InputTextConfig = {
    labelKey: 'pages.solarPanelKva.formElements.power.label',
    placeHolder: 'pages.solarPanelKva.placeHolder',
    placeHolderValues: this.solarRatio,
    errors: new Map([
      ['required', 'pages.solarPanelKva.formElements.power.error'],
      ['pattern', 'pages.solarPanelKva.formElements.power.min'],
      ['min', 'pages.solarPanelKva.formElements.power.min'],
      ['max', 'pages.solarPanelKva.formElements.power.min'],
    ]),
    inputSuffix: 'pages.solarPanelKva.formElements.power.unit',
  };
  formControl: UntypedFormControl;
  numericNumberReg = '^[0-9]\\d*((\\.|\\,)+\\d{1,2})?$';

  constructor(private readonly questionFacade: QuestionFacade) {
    super();
  }

  ngOnInit(): void {
    this.questionFacade
      .findSiteData((site) => site.solarPanelKva)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (value) =>
          (this.formControl = new UntypedFormControl(value, [
            Validators.required,
            min(this.solarRatio.min),
            max(this.solarRatio.max),
            Validators.pattern(this.numericNumberReg),
          ])),
      );
  }

  onPreviousClicked(): void {
    this.questionFacade.previous();
  }

  defaultSolarPanelKva(): void {
    this.formControl.setValue(this.defaultValue[this.appType.toLocaleLowerCase()]);
  }

  onNextClicked(): void {
    this.questionFacade.updateSiteData({ solarPanelKva: toNumber(String(this.formControl.value)) });
    this.questionFacade.next();
  }
}
