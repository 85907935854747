import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { map, shareReplay, startWith } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../../environments/environment';
import { Router } from '@angular/router';
import { QuestionFacade } from '../../facade/question.facade';
import { Type } from '../../state/state';
import { I18nService } from '../../service/i18n/i18n.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  language$: Observable<string>;
  websiteUrl: string;
  type: Type;

  constructor(
    private readonly titleService: Title,
    private readonly translateService: TranslateService,
    private readonly router: Router,
    private readonly i18nService: I18nService,
    private readonly questionFacade: QuestionFacade,
  ) {
    this.type = this.questionFacade.state$.value?.type;
  }

  ngOnInit(): void {
    this.websiteUrl = this.getWebsiteUrl(this.translateService.currentLang, this.type);
    this.language$ = this.translateService.onLangChange.pipe(
      map((event) => event.lang),
      startWith(this.translateService.currentLang),
      shareReplay(1),
    );
    this.setPageTitle()
  }

  onLangChanged(lang: string): void {
    this.i18nService.setLanguage(lang);
    this.translateService.use(lang).subscribe(() => {
        this.websiteUrl = this.getWebsiteUrl(lang, this.type)
        this.setPageTitle()
      }
    );
    this.router.navigate([this.router.url], {
      queryParamsHandling: 'merge',
    });
  }

  private getWebsiteUrl(lang: string, type: Type): string {
    return environment[`WEBSITE_${type.toUpperCase()}_${lang.toUpperCase()}`];
  }

  private setPageTitle(): void {
    this.translateService.get('site.title').subscribe((title: string) => {
      this.titleService.setTitle(title);
    })
  }
}
