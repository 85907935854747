<div class="px-8 xl:max-w-screen-xl xl:w-full xl:m-auto" *ngIf="formControl">
  <app-title class="block w-full max-w-5xl mx-auto text-center"
             [title]=" b2b ? 'pages.zipCode.title.b2b' : 'pages.zipCode.title.b2c'"
             subtitle="pages.zipCode.subtitle"></app-title>

  <div class="mb-2 center">
    <app-form-card icon="zipcode_picture">
            <app-input-text
                    [formControl]="formControl"
                    [inputTextConfig]="INPUT_CONFIG">
    </app-input-text></app-form-card>
    </div>
    <app-navigation [stepValidity]="formControl.valid && next"
                    [automaticTransition]="false"
                    [disableNextStyle]="true"
                  (nextClicked)="onNextClicked()"
                  (previousClicked)="onPreviousClicked()">
  </app-navigation>
</div>
