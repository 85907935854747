<div class="px-8 xl:max-w-screen-xl xl:w-full xl:m-auto" *ngIf="formControl">
  <app-title class="block w-full max-w-5xl mx-auto text-center"
             title="pages.consumptionsHelp.title"
             subtitle="pages.consumptionsHelp.subtitle"
  ></app-title>
  <app-profils
    class="mt-12 inline-block w-full"
    (getProfil)="setProfil($event)"
    [energy]="energy$ | async"
    [meterType]="meterType$ | async"
    [exclusiveNight]="exclusiveNight$ | async"
    [value]="formControl.value"
    [flow]="flow$ | async"
  ></app-profils>
  <p class="text-center mt-16">
    <button
      class="btn btn-primary"
      (click)="profilNotFound()"
    >
      {{ 'pages.consumptionsHelp.noProfilBtn' | translate }}
    </button>
  </p>
  <app-navigation [stepValidity]="formControl.valid"
                  (nextClicked)="onNextClicked()"
                  (previousClicked)="onPreviousClicked()">
  </app-navigation>
</div>
