import { Type } from '../state/state';


export class TypeDetectorService {

  static detectType(): Type {
    const segments: string[] = window.location.pathname.split('/').filter(segment => segment !== '');
   return segments.find(segment => ['professionnels', 'professioneel'].includes(segment)) ? Type.B2B : Type.B2C;
  }
}
