import { Component, Input, OnChanges } from '@angular/core';
import { Consumptions, Energy, totalElec, totalInjection } from '../../../core/state/state';
import { SiteDisplay } from '../../../features/results/sites/sites.component';

@Component({
  selector: 'app-site-details',
  templateUrl: './site-details.component.html',
  styleUrls: ['./site-details.component.scss'],
})
export class SiteDetailsComponent implements OnChanges {
  readonly Energy = Energy;
  @Input() siteDisplay: SiteDisplay;
  @Input() siteNumber: number;
  @Input() fullRoundedTitle: boolean;
  consumptions: ComputedConsumptions | null = null;

  ngOnChanges() {
    this.consumptions = {
      gasTotalConsumptions: this.computeGas(this.siteDisplay.site.consumptions),
      elecTotalConsumptions: this.computeElecConsumption(this.siteDisplay.site.consumptions),
      elecTotalInjection: this.computeElecInjection(this.siteDisplay.site.consumptions),
    };
  }

  private computeElecConsumption(consumptions: Consumptions): number {
    return totalElec(consumptions);
  }

  private computeElecInjection(consumptions: Consumptions): number {
    return totalInjection(consumptions);
  }

  private computeGas(consumptions: Consumptions): number {
    return consumptions.gasConsumption !== undefined ? Number(consumptions.gasConsumption) : 0;
  }
}

interface ComputedConsumptions {
  elecTotalConsumptions: number;
  gasTotalConsumptions: number;
  elecTotalInjection: number;
}
