import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import localities from '../../../assets/files/localities.json';

@Injectable({
    providedIn: 'root'
})
export class LocalityApi {
    private readonly localities: Localities[] = localities;

    get(zipCode: number): Observable<Localities> {
        return of(this.getLocalLocalities(zipCode));
    }

    private getLocalLocalities(zipcode: number): Localities {
        const locality = this.localities.find((local) => local.zipcode === zipcode.toString());
        return locality ?? null;
    }

}

export interface Localities {
    zipcode: string;
    localities: string[];
    region?: string;
    dgo?: {
        power: string;
        gas: string;
    };
}
