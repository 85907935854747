import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { QuestionFacade } from '../../core/facade/question.facade';
import { TypeDetectorService } from '../../core/service/type-detector.service';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.css'],
})
export class ErrorComponent implements OnInit {

  @Input() title = 'pages.404.unavailable';
  readonly type = TypeDetectorService.detectType();

  constructor(public translate: TranslateService, private readonly questionFacade: QuestionFacade) {}

  ngOnInit(): void {
    this.questionFacade.onErrorPageReached();
  }
}
