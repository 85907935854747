import { ChangeDetectorRef, Component, Injector, Input, Type } from '@angular/core';
import { AbstractControl, ControlValueAccessor } from '@angular/forms';
import * as uuid from 'uuid';

/**
 * this class avoid boiler code on the creation of a custom control value accessor.
 */
@Component({ template: '' })
export class CustomFormParentComponent<T> implements ControlValueAccessor {
  @Input()
  cid: string = uuid.v4();

  @Input()
  disabled: boolean;

  @Input()
  readonly: boolean;

  @Input()
  formControl: AbstractControl;

  @Input()
  set value(value: T) {
    this._value = value;
    this.notifyValueChange();
  }

  get value(): T {
    return this._value;
  }

  onChange: (value: T) => unknown;
  onTouched: () => unknown;

  protected _value: T;
  protected cdRef: ChangeDetectorRef;

  constructor(protected injector: Injector) {
    this.cdRef = injector.get<ChangeDetectorRef>(ChangeDetectorRef as Type<ChangeDetectorRef>);
  }

  notifyValueChange(): void {
    if (this.onChange) {
      this.onChange(this.value);
    }
  }

  writeValue(value: T): void {
    this._value = value;
    setTimeout(() => {
      if (!this.cdRef['destroyed']) {
        this.cdRef.detectChanges();
      }
    });
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  registerOnChange(fn: (value: T) => unknown): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => unknown): void {
    this.onTouched = fn;
  }
}
