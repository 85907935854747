import { Component, OnInit } from '@angular/core';
import { InputRadioConfig } from '../../shared/custom-form-control/input-radio-wrapper/input-radio-wrapper.component';
import { HabitationType, Site } from '../../core/state/state';
import { UntypedFormControl, Validators } from '@angular/forms';
import { QuestionFacade } from '../../core/facade/question.facade';
import { takeUntil } from 'rxjs/operators';
import { CompleteObservables } from 'src/app/shared/complete-observables';

@Component({
  selector: 'app-habitation-type',
  templateUrl: './habitation-type.component.html',
})
export class HabitationTypeComponent extends CompleteObservables implements OnInit {
  options: InputRadioConfig[] = [
    {
      id: HabitationType.FLAT,
      value: HabitationType.FLAT,
      image: './assets/img/unknown-consumption/building-ico.svg',
      caption: `pages.unknownConsumptions.building.buildingType.choices.flat`,
    },
    {
      id: HabitationType.TWO,
      value: HabitationType.TWO,
      image: './assets/img/unknown-consumption/house2facades-ico.svg',
      caption: `pages.unknownConsumptions.building.buildingType.choices.two`,
    },
    {
      id: HabitationType.THREE,
      value: HabitationType.THREE,
      image: './assets/img/unknown-consumption/house3facades-ico.svg',
      caption: `pages.unknownConsumptions.building.buildingType.choices.three`,
    },
    {
      id: HabitationType.FOUR,
      value: HabitationType.FOUR,
      image: './assets/img/unknown-consumption/house4facades-ico.svg',
      caption: `pages.unknownConsumptions.building.buildingType.choices.four`,
    },
  ];
  formControl: UntypedFormControl;
  site: Site;

  constructor(private readonly questionFacade: QuestionFacade) {
    super();
  }

  ngOnInit(): void {
    this.questionFacade
      .findSiteData((site) => site)
      .pipe(takeUntil(this.destroy$))
      .subscribe((site) => {
        this.site = site;
        this.formControl = new UntypedFormControl(
          site?.unknownConsumption?.building?.buildingType,
          Validators.required,
        );
      });
  }

  onPreviousClicked(): void {
    this.questionFacade.previous();
  }

  onNextClicked(): void {
    const site = {
      ...this.site,
      unknownConsumption: {
        ...this.site?.unknownConsumption,
        building: {
          ...this.site?.unknownConsumption?.building,
          buildingType: this.formControl.value,
        },
      },
    };
    this.questionFacade.updateSiteData(site);
    this.questionFacade.next();
  }
}
