import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { InputTextConfig } from 'src/app/shared/custom-form-control/zip-code-input/input-text.component';
import { debounceTime, filter, switchMap, takeUntil, tap } from 'rxjs/operators';
import { Localities } from '../../core/api/locality.api';
import { QuestionFacade } from '../../core/facade/question.facade';
import { TypeDetectorService } from '../../core/service/type-detector.service';
import { Type } from '../../core/state/state';
import { CompleteObservables } from 'src/app/shared/complete-observables';

export interface Address {
  zipCode: number;
  town: string;
}

@Component({
  selector: 'app-zip-code',
  templateUrl: './zip-code.component.html',
  styleUrls: ['./zip-code.component.scss'],
})
export class ZipCodeComponent extends CompleteObservables implements OnInit {
  next: boolean;
  readonly INPUT_CONFIG: InputTextConfig = {
    labelKey: 'pages.zipCode.field-name',
    placeHolder: 'pages.zipCode.placeholder',
    errors: new Map([
      ['incorrect', 'pages.zipCode.error.incorrect'],
      ['max', 'pages.zipCode.error.range'],
      ['min', 'pages.zipCode.error.range'],
    ]),
    info: 'pages.zipCode.info',
  };
  b2b = TypeDetectorService.detectType() === Type.B2B;
  formControl: UntypedFormControl;

  constructor(private readonly questionFacade: QuestionFacade) {
    super();
  }

  ngOnInit(): void {
    this.questionFacade
      .findSiteData((site) => site.zipCode)
      .pipe(takeUntil(this.destroy$))
      .subscribe((value) => {
        this.formControl = new UntypedFormControl(value, [Validators.required, Validators.pattern('^[0-9]{1,4}$')]);
        this.next = value !== null && value !== undefined;
      });

    this.formControl.valueChanges
      .pipe(
        tap((zipCode: number) => {
          this.updateZipcode(null);
          this.handleErrorsDisplay(zipCode);
        }),
        filter((zipCode: number) => this.isZipCodeInRange(zipCode)),
        debounceTime(500),
        switchMap((zipCode: number) => this.questionFacade.getLocalities(zipCode)),
      )
      .subscribe((locality: Localities | null) => this.handleLocalityResponse(locality));
  }

  updateZipcode(locality: Address): void {
    if (locality) {
      this.questionFacade.updateSiteData({ zipCode: locality.zipCode, town: locality.town });
      this.next = true;
    } else {
      this.next = false;
    }
  }

  onPreviousClicked() {
    this.questionFacade.previous();
  }

  onNextClicked() {
    this.questionFacade.updateSiteData({ zipCode: this.formControl.value });
    this.questionFacade.next();
  }

  handleErrorsDisplay(zipCode: number) {
    if (!this.isZipCodeInRange(zipCode)) {
      this.formControl.setErrors({ max: true });
    }
  }

  isZipCodeInRange(zipCode: number) {
    return !isNaN(zipCode) && zipCode > 999 && zipCode < 10000;
  }

  private handleLocalityResponse(locality: Localities | null): void {
    if (locality) {
      const local = { zipCode: +locality.zipcode, town: locality.localities[0] };
      this.updateZipcode(local);
    } else {
      this.updateZipcode(null);
      this.formControl.setErrors({ incorrect: true });
    }
  }
}
