const basePath = 'https://die-www.totalenergies.be';
export const environment = {
  production: false,
  envName: 'die',
  BACKEND_URL: 'https://public.besimulatorpgdie.alzp.tgscloud.net',
  PRODUCT_CATALOG_URL: 'https://public.beproductcatalogdie.alzp.tgscloud.net',
  CONTACT_EMAIL_B2B: 'welcome.pro@totalenergies.be',
  CONTACT_EMAIL_B2C: 'support@totalenergies.be',
  sentry: 'https://cb6d416761cd4fad922093ef4c533928@o542700.ingest.sentry.io/5917361',
  languageAssetsPath: './assets/i18n/',
  B2B_OLREG_NL: `${basePath}/nl/professioneel/producten-en-diensten/my-business/ons-energieaanbod/inschrijving`,
  B2B_OLREG_FR: `${basePath}/fr/professionnels/produits-et-services/my-business/notre-offre-energie/inscription`,
  B2C_OLREG_NL: `${basePath}/nl/particulieren/producten-en-diensten/my-home/elektriciteit-en-aardgas/aanbod-electriciteit-en-aardgas/inschrijving/contact`,
  B2C_OLREG_FR: `${basePath}/fr/particuliers/produits-et-services/my-home/electricite-et-gaz/offre-electricite-et-gaz/inscription/contact`,
  WEBSITE_B2B_NL: `${basePath}/nl/professioneel`,
  WEBSITE_B2B_FR: `${basePath}/fr/professionnels`,
  WEBSITE_B2C_NL: `${basePath}/nl/particulieren`,
  WEBSITE_B2C_FR: `${basePath}/fr/particuliers`,
};
