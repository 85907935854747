import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { NavigationComponent } from '../../shared/navigation/navigation.component';
import { QuestionFacade } from '../../core/facade/question.facade';
import { Observable } from 'rxjs';
import { Energy, MeterType } from '../../core/state/state';
import { Profil } from './profils/profils.component';
import { takeUntil } from 'rxjs/operators';
import { CompleteObservables } from 'src/app/shared/complete-observables';

@Component({
  selector: 'app-consumptions-help',
  templateUrl: './consumptions-help.component.html',
})
export class ConsumptionsHelpComponent extends CompleteObservables implements OnInit {
  @ViewChild(NavigationComponent) navigationComponent: NavigationComponent;
  flow$: Observable<string>;
  energy$: Observable<Energy>;
  meterType$: Observable<MeterType>;
  exclusiveNight$: Observable<boolean>;
  formControl: UntypedFormControl;
  private profilSelected: Profil;

  constructor(private readonly questionFacade: QuestionFacade) {
    super();
    this.energy$ = this.questionFacade.findSiteData((site) => site.energy);
    this.meterType$ = this.questionFacade.findSiteData((site) => site.meterType);
    this.exclusiveNight$ = this.questionFacade.findSiteData((site) => site.exclusiveNight);
  }

  ngOnInit(): void {
    this.flow$ = this.questionFacade.findData((state) => state.numberOfSites);
    this.questionFacade
      .findSiteData((site) => site.consumptionHelp)
      .pipe(takeUntil(this.destroy$))
      .subscribe((value) => (this.formControl = new UntypedFormControl(value, Validators.required)));
  }

  setProfil(profilData: { profil: Profil; site: number }) {
    this.formControl.setValue(profilData.profil.id);
    this.profilSelected = profilData.profil;
  }

  profilNotFound(): void {
    this.questionFacade.next();
  }

  onPreviousClicked() {
    this.questionFacade.previous();
  }

  onNextClicked() {
    const consumptionHelp = this.formControl.value;
    this.questionFacade.updateConsumption(
      {
        consumptionHigh: this.profilSelected.elecConsumptionHigh,
        consumptionLow: this.profilSelected.elecConsumptionLow,
        consumptionMono: this.profilSelected.elecConsumptionMono,
        consumptionExclusiveNight: this.profilSelected.elecConsumptionExclusiveNight,
        gasConsumption: this.profilSelected.gasConsumption,
      },
      consumptionHelp,
    );
    this.questionFacade.next();
  }
}
