import { Component, Input } from '@angular/core';
import { CustomFormParentComponent } from '../custom-form-parent.component';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-input-checkbox',
  templateUrl: './input-checkbox.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: InputCheckboxComponent,
    },
  ],
})
export class InputCheckboxComponent extends CustomFormParentComponent<boolean> {
  @Input() id: string;
  @Input() label: string;

  onInputChange($event: Event) {
    this.value = $event.currentTarget['checked'];
  }
}
