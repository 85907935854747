<div class="relative inline-block w-full h-full">
  <div @fadeInFadeOut [hidden]="load">
    <section class="content-wrapper pb-12 md:pb-36 md:-mt-10" *ngIf="(sites$ | async) as sites">
      <div class="text-center">
        <h2
          class="mb-4 font-display font-normal text-4xl te-text-gradient-home inline-block w-full"
          [innerHTML]="'pages.result.title' | translate"
        ></h2>
        <span
          *ngIf="sites?.length > 1"
          [innerHTML]="'pages.result.subtitle' | translate: { value: sites?.length }"
          class="inline-block w-full"
        >
        </span>
        <span [innerHTML]="'pages.result.becomeClient' | translate" class="inline-block w-full"> </span>
      </div>
      <app-sites [sitesDisplay]="sites"></app-sites>
    </section>

    <section class="bg-white py-0 md:py-20">
      <div class="content-wrapper">
        <app-bundles
          (goToOlReg)="onGoToOlreg($event.code, $event.totalAmount)"
        >
        </app-bundles>
        <div
          *ngIf="isProsumerInWalloniaOrFlanders$ | async"
          class="mx-auto max-w-7xl border-t border-grey border-solid mt-16 pt-16 text-center"
        >
          {{ 'pages.result.tariffIncluded' | translate }}
        </div>
        <div
          *ngIf="isProsumerInBrussels$ | async"
          class="mx-auto max-w-7xl border-t border-grey border-solid mt-16 pt-16 text-center"
        >
          {{ 'pages.result.tariffIncludedBrussels' | translate }}
        </div>
        <div class="mx-auto max-w-5xl text-center mt-24 relative md:pb-0 pb-8">
          <h3 class="bg-white px-8 inline-block relative z-10">{{ 'footer.becomeCustomerByTel' | translate }}</h3>
          <hr class="absolute w-full h-px border-0 bg-grey top-8" />
          <div class="mx-auto sm:flex max-w-3xl mt-10 text-sm">
            <div class="flex-1 text-primary font-medium">
              <a [attr.href]="'mailto:' + contactEmail" class="link-item">
                <img src="./assets/img/mail-icon.svg" class="inline-block mr-2" width="20" alt="" />
                {{ contactEmail }}
              </a>
            </div>
            <span class="my-8 inline-block sm:my-0 flex-1 font-medium text-grey">{{ 'footer.or' | translate }}</span>
            <div class="flex-1 text-sm">
              <a [innerHTML]="'header.B2B.phone' | translate"></a>
              <span class="inline-block w-full text-xs mt-2">
                {{ 'footer.mondayToFriday' | translate }} <br />{{ 'footer.horary' | translate }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="content-wrapper py-12 md:py-20 text-grey-dark">
      <div class="mb-12">
        <h3 class="text-3xl font-medium pb-8">
          {{ 'pages.result.infosAndConditions.title' | translate }}
        </h3>
        <p>
          {{ 'pages.result.infosAndConditions.simulationDate' | translate: currentDate }}
        </p>
        <ng-container *ngIf="isBrussels$ | async">
          {{ 'footer.disclaimer.sibelga' | translate }}
        </ng-container>
      </div>
      <div>
        <h3 class="text-2xl font-medium mb-8">
          {{ 'pages.result.infosAndConditions.howIsCalculateTitle' | translate }}
        </h3>
        <p class="column-2">
          {{ 'pages.result.infosAndConditions.howIsCalculateContent' | translate }}
          {{ 'pages.result.infosAndConditions.disclaimerContent' | translate }}
        </p>
      </div>
    </section>
  </div>
  <app-loader *ngIf="load"></app-loader>
</div>
