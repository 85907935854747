import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LocalizedValue } from 'src/app/core/bundle/bundle';

@Component({
  selector: 'app-promo',
  templateUrl: './promo.component.html',
  styleUrls: ['./promo.component.scss'],
})
export class PromoComponent {
  @Input() promoDetails: { title: LocalizedValue; description: LocalizedValue };

  constructor(public translateService: TranslateService) {}
}
