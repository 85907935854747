import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { TranslateModule } from '@ngx-translate/core';
import { LayoutComponent } from './layout.component';
import { LangSwitcherComponent } from './header/lang-switcher/lang-switcher.component';
import { ContactComponent } from './header/contact/contact.component';
import {SalesforceChatModule} from "@totalenergiescode/springbox-salesforce-chat";


@NgModule({
    declarations: [
        HeaderComponent,
        FooterComponent,
        BreadcrumbComponent,
        LayoutComponent,
        LangSwitcherComponent,
        ContactComponent
    ],
  exports: [
    BreadcrumbComponent,
    LayoutComponent,
    ContactComponent
  ],
  imports: [
    CommonModule,
    TranslateModule.forChild(),
    SalesforceChatModule
  ]
})
export class LayoutModule {
}
