<div class="content-wrapper text-center">
  <h1 class="resume__simulation-header" [innerHTML]="'pages.result.summaryProduct.title' | translate"></h1>
</div>

<div class="flex flex-wrap justify-center gap-x-16 gap-y-8">
  <app-simulation-info-card
    *ngIf="elec$ | async as elec"
    [title]="'pages.result.summary.elec' | translate"
    img="./assets/img/result/result-elec.svg"
  >
    <span class="resume-list__value">{{ elec | number: '1.0-0' }} {{ 'common.unit.kwhPerYear' | translate }}</span>
  </app-simulation-info-card>
  <app-simulation-info-card
    *ngIf="gas$ | async as gas"
    [title]="'pages.result.summary.gas' | translate"
    img="./assets/img/result/result-gas.svg"
  >
    <span class="resume-list__value">{{ gas | number: '1.0-0' }} {{ 'common.unit.kwhPerYear' | translate }}</span>
  </app-simulation-info-card>
  <app-simulation-info-card
    [title]="'pages.result.summary.locality' | translate"
    img="./assets/img/result/result-locality.svg"
  >
    <span class="resume-list__value">{{ zipCode$ | async }}</span>
  </app-simulation-info-card>
  <app-simulation-info-card
    *ngIf="averagePeak$ | async as averagePeak"
    [title]="'pages.result.summary.averagePeak' | translate"
    img="./assets/img/result/result-average-peak.svg"
  >
    <span class="resume-list__value">{{ averagePeak }}</span>
  </app-simulation-info-card>
  <app-simulation-info-card
    *ngIf="injections$ | async as injection"
    [title]="'pages.result.summary.injection' | translate"
    img="./assets/img/result/result-injections.svg"
  >
    <span class="resume-list__value">{{ injection | number: '1.2-2' }} {{ 'common.unit.kwhPerYear' | translate }}</span>
  </app-simulation-info-card>
</div>
