import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
})
export class CardComponent {
  @Input() card: Card;
  @Output() contentClicked = new EventEmitter<void>();
}

export class Card {
  constructor(
    public title: string,
    public content: string,
    public linkLabel: string,
    public linkURL: string,
    public background: string,
    public isClickable = false,
    public modifiers?: string,
    public contentParams?: {
      param: string | number;
    },
  ) {}
}
