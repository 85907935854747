import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { SiteDisplay } from '../../results/sites/sites.component';

@Component({
  selector: 'app-site-rename-wrapper',
  templateUrl: './site-rename-wrapper.component.html',
  styleUrls: ['./site-rename-wrapper.component.scss']
})
export class SiteRenameWrapperComponent {
  @Input() siteDisplay: SiteDisplay;
  @Input() siteNumber: number;
  @Output() saveSiteName = new EventEmitter<string>();
  @Output() cancel = new EventEmitter<void>();
  private renameSiteFormControl: UntypedFormControl;

  onSaveSiteName() {
    this.saveSiteName.emit(this.renameSiteFormControl.value);
  }

  onFormReady(formControl: UntypedFormControl) {
    this.renameSiteFormControl = formControl;
  }

}
