import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SiteDisplay } from '../../../../features/results/sites/sites.component';
import { Energy } from 'src/app/core/state/state';

@Component({
  selector: 'app-site-info',
  templateUrl: './site-info.component.html',
  styleUrls: ['./site-info.component.scss'],
})
export class SiteInfoComponent {
  @Input() siteDisplay: SiteDisplay;
  @Input() siteIndex: number;
  @Input() totalGas: number;
  @Input() totalInjection: number;
  @Input() totalElec: number;
  @Output() closeInfo = new EventEmitter<boolean>();
  @Output() saveName = new EventEmitter<{ siteIndex: number; name: string }>();
  readonly Energy = Energy;
}
