import { Component } from '@angular/core';
import {TypeDetectorService} from '../service/type-detector.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent {
  readonly TypeDetectorService = TypeDetectorService;
  constructor(public translate: TranslateService) {}
}
