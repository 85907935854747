import { Component, OnInit } from '@angular/core';
import { InputRadioConfig } from '../../shared/custom-form-control/input-radio-wrapper/input-radio-wrapper.component';
import { HabitantNumber, Site } from '../../core/state/state';
import { UntypedFormControl, Validators } from '@angular/forms';
import { QuestionFacade } from '../../core/facade/question.facade';
import { takeUntil } from 'rxjs/operators';
import { CompleteObservables } from 'src/app/shared/complete-observables';

@Component({
  selector: 'app-habitant-number',
  templateUrl: './habitant-number.component.html',
})
export class HabitantNumberComponent extends CompleteObservables implements OnInit {
  options: InputRadioConfig[] = [
    {
      id: HabitantNumber.ONE,
      value: HabitantNumber.ONE,
      image: './assets/img/unknown-consumption/solo-ico.svg',
      caption: `pages.unknownConsumptions.building.occupiedBy.choices.one`,
    },
    {
      id: HabitantNumber.TWO,
      value: HabitantNumber.TWO,
      image: './assets/img/unknown-consumption/duo-ico.svg',
      caption: `pages.unknownConsumptions.building.occupiedBy.choices.two`,
    },
    {
      id: HabitantNumber.THREE,
      value: HabitantNumber.THREE,
      image: './assets/img/unknown-consumption/3-persons-ico.svg',
      caption: `pages.unknownConsumptions.building.occupiedBy.choices.three`,
    },
    {
      id: HabitantNumber.FOUR,
      value: HabitantNumber.FOUR,
      image: './assets/img/unknown-consumption/4-persons-ico.svg',
      caption: `pages.unknownConsumptions.building.occupiedBy.choices.four`,
    },
    {
      id: HabitantNumber.FIVE,
      value: HabitantNumber.FIVE,
      image: './assets/img/unknown-consumption/5-plus-persons-ico.svg',
      caption: `pages.unknownConsumptions.building.occupiedBy.choices.five`,
    },
  ];
  formControl: UntypedFormControl;
  site: Site;

  constructor(private readonly questionFacade: QuestionFacade) {
    super();
  }

  ngOnInit(): void {
    this.questionFacade
      .findSiteData((site) => site)
      .pipe(takeUntil(this.destroy$))
      .subscribe((site) => {
        this.site = site;
        this.formControl = new UntypedFormControl(site?.unknownConsumption?.building?.occupiedBy, Validators.required);
      });
  }

  onPreviousClicked(): void {
    this.questionFacade.previous();
  }

  onNextClicked(): void {
    const site = {
      ...this.site,
      unknownConsumption: {
        ...this.site?.unknownConsumption,
        building: {
          ...this.site?.unknownConsumption?.building,
          occupiedBy: this.formControl.value,
        },
      },
    };
    this.questionFacade.updateSiteData(site);
    this.questionFacade.next();
  }
}
