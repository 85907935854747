<div [ngClass]="{ 'text-left': inputTextConfig.multisiteEnabled }">
  <label class="block mb-2" [for]="id">{{ inputTextConfig.labelKey | translate }} </label>
  <div class="flex">
    <input
      [id]="id"
      [formControl]="formControl"
      (blur)="inputTextBlur.emit()"
      autocomplete="off"
      [pattern]="inputTextConfig.pattern ? inputTextConfig.pattern : '.*'"
      [maxlength]="inputTextConfig.maxLength ? inputTextConfig.maxLength : null"
      class="form__input"
      [ngClass]="{ square: inputTextConfig.inputSuffix }"
      [placeholder]="inputTextConfig.placeHolder | translate: inputTextConfig.placeHolderValues"
    />
    <div class="list__suffix" *ngIf="inputTextConfig.inputSuffix">{{ inputTextConfig.inputSuffix | translate }}</div>
  </div>
  <div class="form__error">
    <ng-container *ngIf="formControl.invalid && (formControl.touched || formControl.dirty)">
      <ng-container *ngFor="let entry of inputTextConfig.errors | keyvalue">
        <ng-container *ngIf="formControl.errors[entry.key]">
          {{ entry.value | translate }}
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
  <div class="info">{{ inputTextConfig.info | translate }}</div>
</div>
