import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

export enum InputRadioType {
  image = 'image',
  text = 'text',
}

@Component({
  selector: 'app-input-radio',
  templateUrl: './input-radio.component.html',
  styleUrls: ['./input-radio.component.scss']
})
export class InputRadioComponent implements OnInit {
  @Input() value: string | boolean;
  @Input() type: InputRadioType = InputRadioType.image;
  @Input() image: string;
  @Input() desktopImage: string;
  @Input() imageAltText: string;
  @Input() caption: string;
  @Input() checked: boolean;
  @Output() emitValue: EventEmitter<string> = new EventEmitter<string>();
  readonly inputRadioType = InputRadioType;

  ngOnInit(): void {
    if (this.caption && !this.imageAltText) {
      this.imageAltText = this.caption;
    }
    if (this.desktopImage && !this.image) {
      this.image = this.desktopImage;
    }
    if (this.image && !this.desktopImage) {
      this.desktopImage = this.image;
    }
    this.desktopImage = this.desktopImage + ' 640w';
  }

  onSelect(value) {
    this.emitValue.emit(value);
  }
}
