<div class="px-8 xl:max-w-screen-xl xl:w-full xl:m-auto" *ngIf="formControl">
  <app-title title="pages.solarPanelKva.title" subtitle="pages.solarPanelKva.subtitle"> </app-title>
  <div class="mb-2 center">
    <app-form-card icon="solarPower">
      <app-input-text id="solarPanelKvaInput" [formControl]="formControl" [inputTextConfig]="INPUT_CONFIG">
      </app-input-text>
    </app-form-card>
  </div>
  <span class="average"></span>
  <p class="list__dontKnow" (click)="defaultSolarPanelKva()">
    <span tabindex="2">
      <span>{{ 'pages.solarPanelKva.averageTitle' | translate }}</span>
      <span class="list__dontKnow--subtitle">
        {{ 'pages.solarPanelKva.formElements.power.average' | translate: solarRatio }}
      </span>
    </span>
  </p>
  <app-navigation
    [stepValidity]="formControl.valid"
    [automaticTransition]="false"
    (nextClicked)="onNextClicked()"
    [disableNextStyle]="true"
    (previousClicked)="onPreviousClicked()"
  >
  </app-navigation>
</div>
