import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SiteNumberComponent } from './features/site-number/site-number.component';
import { ResultsComponent } from './features/results/results.component';
import { ConsumptionsHelpComponent } from './features/consumptions-help/consumptions-help.component';
import { ConsumptionsComponent } from './features/consumptions/consumptions.component';
import { MeterTypeComponent } from './features/meter-type/meter-type.component';
import { KnowConsumptionsComponent } from './features/know-consumptions/know-consumptions.component';
import { EnergyComponent } from './features/energy/energy.component';
import { ZipCodeComponent } from './features/zip-code/zip-code.component';
import { ErrorComponent } from './features/error/error.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { TooMuchSitesComponent } from './features/too-much-sites/too-much-sites.component';
import { ProfilNotFoundComponent } from './features/profil-not-found/profil-not-found.component';
import { HasSolarPanelComponent } from './features/solar/has-solar-panel/has-solar-panel.component';
import { SolarPanelKvaComponent } from './features/solar/solar-panel-kva/solar-panel-kva.component';
import { SmartMeterComponent } from './features/smart-meter/smart-meter.component';
import { AveragePeakComponent } from './features/peak-info/average-peak.component';
import { TranslateLocationStrategy } from './route-translate.strategy';
import { LocationStrategy } from '@angular/common';
import {
  AveragePeakB2B,
  ConsumptionHelpB2B,
  ConsumptionsB2B,
  EnergyB2B,
  HasSolarPanelsB2B,
  KnowConsumptionB2B,
  MeterTypeB2B,
  MoreThan8Sites,
  Multisite,
  SiteNumberB2B,
  SmartMeterB2B,
  SolarPanelKvaB2B,
  ZipCodeB2B,
} from './core/service/questions/config/questions-config-b2b';
import { Result } from './core/service/questions/questions-common';
import { MultisiteComponent } from './features/multisite/multisite.component';
import { HasElectricVehicleComponent } from './features/has-electric-vehicle/has-electric-vehicle.component';
import {
  BoilerYearB2C,
  CertificationPVB2C,
  HabitantNumberB2C,
  HabitationTypeB2C,
  HabitationVolumeB2C,
  HabitationYearB2C,
  HasElectricVehicleB2C,
  HeatingPumpTypeB2C,
  HeatingTypeB2C,
} from './core/service/questions/config/questions-config-b2c';
import { HabitationVolumeComponent } from './features/habitation-volume/habitation-volume.component';
import { HabitationYearComponent } from './features/habitation-year/habitation-year.component';
import { HabitantNumberComponent } from './features/habitant-number/habitant-number.component';
import { HabitationTypeComponent } from './features/habitation-type/habitation-type.component';
import { HeatingTypeComponent } from './features/heating-type/heating-type.component';
import { BoilerYearComponent } from './features/boiler-year/boiler-year.component';
import { HeatingPumpTypeComponent } from './features/heating-pump-type/heating-pump-type.component';
import { PvCertificationComponent } from './features/pv-certification/pv-certification.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: new SiteNumberB2B().getUrl().replace('/', ''),
    pathMatch: 'full',
  },
  {
    path: new SiteNumberB2B().getUrl().replace('/', ''),
    component: SiteNumberComponent,
    pathMatch: 'full',
  },
  {
    path: new ZipCodeB2B().getUrl().replace('/', ''),
    component: ZipCodeComponent,
    pathMatch: 'full',
  },
  {
    path: new EnergyB2B().getUrl().replace('/', ''),
    component: EnergyComponent,
    pathMatch: 'full',
  },
  {
    path: new KnowConsumptionB2B().getUrl().replace('/', ''),
    component: KnowConsumptionsComponent,
    pathMatch: 'full',
  },
  {
    path: new HasSolarPanelsB2B().getUrl().replace('/', ''),
    component: HasSolarPanelComponent,
    pathMatch: 'full',
  },
  {
    path: new CertificationPVB2C().getUrl().replace('/', ''),
    component: PvCertificationComponent,
    pathMatch: 'full',
  },
  {
    path: new SolarPanelKvaB2B().getUrl().replace('/', ''),
    component: SolarPanelKvaComponent,
    pathMatch: 'full',
  },
  {
    path: new HasElectricVehicleB2C().getUrl().replace('/', ''),
    component: HasElectricVehicleComponent,
    pathMatch: 'full',
  },
  {
    path: new SmartMeterB2B().getUrl().replace('/', ''),
    component: SmartMeterComponent,
    pathMatch: 'full',
  },
  {
    path: new MeterTypeB2B().getUrl().replace('/', ''),
    component: MeterTypeComponent,
    pathMatch: 'full',
  },
  {
    path: new AveragePeakB2B().getUrl().replace('/', ''),
    component: AveragePeakComponent,
    pathMatch: 'full',
  },
  {
    path: new ConsumptionsB2B().getUrl().replace('/', ''),
    component: ConsumptionsComponent,
    pathMatch: 'full',
  },
  {
    path: new ConsumptionHelpB2B().getUrl().replace('/', ''),
    component: ConsumptionsHelpComponent,
    pathMatch: 'full',
  },
  {
    path: new Multisite().getUrl().replace('/', ''),
    component: MultisiteComponent,
    pathMatch: 'full',
  },
  {
    path: new Result().getUrl().replace('/', ''),
    component: ResultsComponent,
    pathMatch: 'full',
  },
  {
    path: new MoreThan8Sites().getUrl().replace('/', ''),
    component: TooMuchSitesComponent,
    pathMatch: 'full',
  },
  {
    path: new HabitationTypeB2C().getUrl().replace('/', ''),
    component: HabitationTypeComponent,
    pathMatch: 'full',
  },
  {
    path: new HabitationVolumeB2C().getUrl().replace('/', ''),
    component: HabitationVolumeComponent,
    pathMatch: 'full',
  },
  {
    path: new HabitationYearB2C().getUrl().replace('/', ''),
    component: HabitationYearComponent,
    pathMatch: 'full',
  },
  {
    path: new HabitantNumberB2C().getUrl().replace('/', ''),
    component: HabitantNumberComponent,
    pathMatch: 'full',
  },
  {
    path: new HeatingTypeB2C().getUrl().replace('/', ''),
    component: HeatingTypeComponent,
    pathMatch: 'full',
  },
  {
    path: new BoilerYearB2C().getUrl().replace('/', ''),
    component: BoilerYearComponent,
    pathMatch: 'full',
  },
  {
    path: new HeatingPumpTypeB2C().getUrl().replace('/', ''),
    component: HeatingPumpTypeComponent,
    pathMatch: 'full',
  },
  {
    path: 'profil-not-found',
    component: ProfilNotFoundComponent,
    pathMatch: 'full',
  },
  {
    path: 'error',
    component: ErrorComponent,
    pathMatch: 'full',
  },
  {
    path: '**',
    component: ResultsComponent,
    pathMatch: 'prefix',
  },
];

@NgModule({
  imports: [
    BrowserModule,
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: 'reload',
    }),
    BrowserAnimationsModule,
  ],

  exports: [RouterModule],
  providers: [{ provide: LocationStrategy, useClass: TranslateLocationStrategy }],
})
export class AppRoutingModule {}
