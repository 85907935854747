import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputTextComponent } from './zip-code-input/input-text.component';
import { InputRadioWrapperComponent } from './input-radio-wrapper/input-radio-wrapper.component';
import { InputRadioComponent } from './input-radio/input-radio.component';
import { InputCheckboxComponent } from './input-checkbox/input-checkbox.component';
import { TranslateModule } from '@ngx-translate/core';
import { ReactiveFormsModule } from '@angular/forms';



@NgModule({
  declarations: [
    InputTextComponent,
    InputRadioWrapperComponent,
    InputRadioComponent,
    InputCheckboxComponent
  ],
  exports: [
    InputTextComponent,
    InputRadioWrapperComponent,
    InputRadioComponent,
    InputCheckboxComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    ReactiveFormsModule
  ]
})
export class CustomFormControlModule { }
