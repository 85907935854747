import { Component } from '@angular/core';
import { Type } from '../../core/state/state';
import { TypeDetectorService } from '../../core/service/type-detector.service';

@Component({
  selector: 'app-results',
  templateUrl: './results.component.html'
})
export class ResultsComponent {
  isB2c = TypeDetectorService.detectType() === Type.B2C;
}
