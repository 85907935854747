<div class="scrollable-content simulator">
  <div class="content-wrapper">
    <app-breadcrumb></app-breadcrumb>
    <app-header></app-header>
    <div class="main-content">
      <ng-content></ng-content>
    </div>
  </div>
  <app-footer></app-footer>
</div>
<lib-salesforce-chat
  [lang]="translate.currentLang"
  [customerType]="TypeDetectorService.detectType()"
>
</lib-salesforce-chat>
