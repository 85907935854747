import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { InputRadioConfig } from '../../shared/custom-form-control/input-radio-wrapper/input-radio-wrapper.component';
import { QuestionFacade } from '../../core/facade/question.facade';
import { withLatestFrom } from 'rxjs/operators';
export enum CertifiactionPvType {
  'Less24' = 'Less24',
  'More24' = 'More24',
}

@Component({
  selector: 'app-pv-certification',
  templateUrl: './pv-certification.component.html',
  styleUrls: ['./pv-certification.component.scss'],
})
export class PvCertificationComponent implements OnInit {
  options: InputRadioConfig[] = [
    {
      id: CertifiactionPvType.Less24,
      value: false,
      image: './assets/img/certificateLess24.svg',
      caption: 'pages.solarPanelsInstallation.formElements.less24.label',
      trackingValue: `CertificationPV${CertifiactionPvType.Less24}`,
    },
    {
      id: CertifiactionPvType.More24,
      value: true,
      image: './assets/img/certificateMore24.svg',
      caption: 'pages.solarPanelsInstallation.formElements.more24.label',
      trackingValue: `CertificationPV${CertifiactionPvType.More24}`,
    },
  ];
  formGroup: UntypedFormGroup;

  constructor(
    private readonly questionFacade: QuestionFacade,
    private readonly fb: UntypedFormBuilder,
  ) {}

  ngOnInit(): void {
    this.questionFacade
      .findSiteData((site) => site.solarPanelsInstallationDateStarting2024)
      .pipe(withLatestFrom(this.questionFacade.findSiteData((site) => site.injectionTariff)))
      .subscribe(([solarPanelsInstallationDateStarting2024, injectionTariff]) => {
        this.formGroup = this.fb.group({
          solarPanelsInstallationDateStarting2024: [solarPanelsInstallationDateStarting2024, [Validators.required]],
          injectionTariff: [injectionTariff || false],
        });
      });
  }

  onPreviousClicked() {
    this.questionFacade.previous();
  }

  onNextClicked() {
    this.questionFacade.updateSiteData({ ...this.formGroup.value });
    this.questionFacade.sendSimulatorInteractionTrackingData(
      this.formGroup.get('solarPanelsInstallationDateStarting2024').value
        ? `CertificationPV${CertifiactionPvType.Less24}`
        : `CertificationPV${CertifiactionPvType.More24}`,
    );
    this.questionFacade.next();
  }
}
