<div
  class="radio-btn relative sm:w-full sm:h-96 not-checked"
  [ngClass]="{ checked, 'radio-btn__text': type === inputRadioType.text }"
  (click)="onSelect(value)"
>
  <div class="circle" [class.opacity-0]="!image"></div>
  <div class="flex self-center justify-start w-auto sm:justify-center sm:flex-col">
    <picture *ngIf="image" class="pr-8 sm:pr-0 sm:pb-12">
      <source [srcset]="desktopImage" media="(min-width: 640px)" />
      <img [src]="image" [alt]="imageAltText" class="w-24 sm:min-w-32 sm:w-40 sm:m-auto" />
    </picture>
    <span class="font-medium text-base self-center text-left sm:text-center" [innerHTML]="caption"> </span>
    <ng-content></ng-content>
  </div>
</div>

