import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { QuestionFacade } from '../../facade/question.facade';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent {
   numberOfStepExecuted$: Observable<number>;
   numberOfSteps$: Observable<number>;

   constructor(private readonly questionFacade: QuestionFacade) {
    this.numberOfStepExecuted$ =  this.questionFacade.findData(state => state.numberOfStepExecuted);
    this.numberOfSteps$ = this.questionFacade.findData(state => state.numberOfStep);
   }

}
