import {
  AveragePeak,
  CertificationPV,
  Consumptions,
  Energy,
  HasElectricVehicle,
  HasSolarPanels,
  KnowConsumption,
  MeterType,
  Question,
  Result,
  SmartMeter,
  SolarPanelKva,
  ZipCode,
} from '../questions-common';
import { findCurrentSite, HeatingType, State } from '../../../state/state';
import { ZipCodeService } from '../../zip-code.service';

export class EnergyB2C extends Energy {
  displayCondition(): boolean {
    return true;
  }
}

export class KnowConsumptionB2C extends KnowConsumption {
  displayCondition(): boolean {
    return true;
  }
}

export class ZipCodeB2C extends ZipCode {
  displayCondition(): boolean {
    return true;
  }
}

export class HasSolarPanelsB2C extends HasSolarPanels {
  displayCondition(state: State): boolean {
    return (findCurrentSite(state).energy || '' || '').includes('ELEC');
  }
}

export class CertificationPVB2C extends CertificationPV {
  displayCondition(state: State): boolean {
    return (
      (findCurrentSite(state).energy || '').includes('ELEC') &&
      ZipCodeService.isWallonia(findCurrentSite(state).zipCode || undefined) &&
      findCurrentSite(state).hasSolarPanels
    );
  }
}

export class SolarPanelKvaB2C extends SolarPanelKva {
  displayCondition(state: State): boolean {
    return (findCurrentSite(state).hasSolarPanels || '') && (findCurrentSite(state).energy || '').includes('ELEC');
  }
}

export class HasElectricVehicleB2C extends HasElectricVehicle {
  displayCondition(state: State): boolean {
    return (findCurrentSite(state).energy || '').includes('ELEC');
  }
}

export class SmartMeterB2C extends SmartMeter {
  displayCondition(state: State): boolean {
    return (
      (findCurrentSite(state).energy || '').includes('ELEC') &&
      !(ZipCodeService.isBrussels(findCurrentSite(state).zipCode || undefined) && findCurrentSite(state).hasSolarPanels)
    );
  }
}

export class AveragePeakB2C extends AveragePeak {
  displayCondition(state: State): boolean {
    return (
      ZipCodeService.isFlanders(findCurrentSite(state).zipCode || undefined) &&
      (findCurrentSite(state).smartMeter || '') &&
      (findCurrentSite(state).energy || '').includes('ELEC')
    );
  }
}

export class MeterTypeB2C extends MeterType {
  displayCondition(state: State): boolean {
    return (findCurrentSite(state).energy || '').includes('ELEC');
  }
}

export class ConsumptionsB2C extends Consumptions {
  displayCondition(state: State): boolean {
    return findCurrentSite(state).knowConsumption;
  }
}

export class HabitationVolumeB2C extends Question {
  displayCondition(state: State): boolean {
    return !findCurrentSite(state).knowConsumption;
  }

  getUrl(): string {
    return '/habitation-volume';
  }

  getDisplayTackingDataValue(): string {
    return 'house-size';
  }

  getNlTranslatedUrl(): string {
    return 'type-woning-volume-jaar';
  }

  getFrTranslatedUrl(): string {
    return 'type-habitation-volume-annee';
  }
}

export class HabitationTypeB2C extends Question {
  displayCondition(state: State): boolean {
    return !findCurrentSite(state).knowConsumption;
  }

  getUrl(): string {
    return '/habitation-type';
  }

  getDisplayTackingDataValue(): string {
    return 'house-type';
  }

  getSimulatorInteractionTrackingDataValue(): string {
    return null;
  }

  getNlTranslatedUrl(): string {
    return 'type-woning-gevels';
  }

  getFrTranslatedUrl(): string {
    return 'type-habitation-facade';
  }
}

export class HabitationYearB2C extends Question {
  displayCondition(state: State): boolean {
    return !findCurrentSite(state).knowConsumption;
  }

  getUrl(): string {
    return '/habitation-year';
  }

  getDisplayTackingDataValue(): string {
    return 'house-age';
  }

  getNlTranslatedUrl(): string {
    return 'type-woning-jaar';
  }

  getFrTranslatedUrl(): string {
    return 'type-habitation-annee';
  }
}

export class HabitantNumberB2C extends Question {
  displayCondition(state: State): boolean {
    return !findCurrentSite(state).knowConsumption;
  }

  getUrl(): string {
    return '/habitant-number';
  }

  getDisplayTackingDataValue(): string {
    return 'inhabitant';
  }

  getSimulatorInteractionTrackingDataValue(): string {
    return null;
  }

  getNlTranslatedUrl(): string {
    return 'aantal-bewoners';
  }

  getFrTranslatedUrl(): string {
    return 'nombre-habitants';
  }
}

export class HeatingTypeB2C extends Question {
  displayCondition(state: State): boolean {
    return !findCurrentSite(state).knowConsumption;
  }

  getUrl(): string {
    return '/heating-type';
  }

  getDisplayTackingDataValue(): string {
    return 'heating-type';
  }

  getSimulatorInteractionTrackingDataValue(): string {
    return null;
  }

  getNlTranslatedUrl(): string {
    return 'type-verwarming';
  }

  getFrTranslatedUrl(): string {
    return 'type-de-chauffage';
  }
}

export class BoilerYearB2C extends Question {
  displayCondition(state: State): boolean {
    const heatingType = findCurrentSite(state).unknownConsumption?.heatingEnergy?.type || null;
    return !findCurrentSite(state).knowConsumption && [HeatingType.GAS, HeatingType.FUEL_OIL].includes(heatingType);
  }

  getUrl(): string {
    return '/boiler-year';
  }

  getDisplayTackingDataValue(): string {
    return 'heating-year';
  }

  getNlTranslatedUrl(): string {
    return 'datum-installatie-verwarming';
  }

  getFrTranslatedUrl(): string {
    return 'date-installation-chauffage';
  }
}

export class HeatingPumpTypeB2C extends Question {
  displayCondition(state: State): boolean {
    const heatingType = findCurrentSite(state).unknownConsumption?.heatingEnergy?.type || null;
    return !findCurrentSite(state).knowConsumption && heatingType === HeatingType.HEATING_PUMP;
  }

  getUrl(): string {
    return '/heating-pump-type';
  }

  getDisplayTackingDataValue(): string {
    return 'heating-pump-type';
  }

  getNlTranslatedUrl(): string {
    return 'type-warmtepomp';
  }

  getFrTranslatedUrl(): string {
    return 'type-de-pompe-a-chaleur';
  }
}

export const ORDERED_QUESTION_B2C_CONFIG: Question[] = [
  new EnergyB2C(),
  new KnowConsumptionB2C(),
  new ZipCodeB2C(),
  new HasSolarPanelsB2C(),
  new CertificationPVB2C(),
  new SolarPanelKvaB2C(),
  new SmartMeterB2C(),
  new HasElectricVehicleB2C(),
  new AveragePeakB2C(),
  new MeterTypeB2C(),
  new ConsumptionsB2C(),
  new HabitationTypeB2C(),
  new HabitationVolumeB2C(),
  new HabitationYearB2C(),
  new HabitantNumberB2C(),
  new HeatingTypeB2C(),
  new BoilerYearB2C(),
  new HeatingPumpTypeB2C(),
  new Result(),
];
