<header>
    <div class="wrap">
        <a [href]=websiteUrl>
            <img class="logo" src="./assets/img/layout/logo.svg" alt="TotalEnergies" height="80"/>
        </a>

        <div class="right-hand-side">
            <app-contact [type]="type"></app-contact>
            <app-lang-switcher [language]="language$ | async" (langChanged)="onLangChanged($event)"></app-lang-switcher>
        </div>
    </div>
</header>
