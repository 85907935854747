<div class="px-8 xl:max-w-screen-xl xl:w-full xl:m-auto" *ngIf="formGroup">
  <app-title
    class="block w-full max-w-5xl mx-auto text-center"
    title="pages.solarPanelsInstallation.title"
    subtitle="pages.solarPanelsInstallation.subtitle"
  ></app-title>
  <div [formGroup]="formGroup" class="mt-8">
    <app-input-radio-wrapper
      class="flex flex-wrap md:my-12 justify-center"
      formControlName="solarPanelsInstallationDateStarting2024"
      [configurations]="options"
    >
    </app-input-radio-wrapper>
    <div class="relative w-full md:max-w-3xl block mx-auto mb-16">
      <app-input-checkbox
        formControlName="injectionTariff"
        [id]="'injectionTariff'"
        [label]="'pages.solarPanelsInstallation.formElements.injectionTariff.label' | translate"
      >
      </app-input-checkbox>
      <div class="absolute right-8 top-10">
        <app-tooltip text="pages.solarPanelsInstallation.tooltip"></app-tooltip>
      </div>
    </div>
  </div>
  <app-navigation
    [stepValidity]="formGroup.valid"
    [automaticTransition]="false"
    (nextClicked)="onNextClicked()"
    (previousClicked)="onPreviousClicked()"
  >
  </app-navigation>
</div>
