import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class OlregApiService {


    redirectB2B(lang: string, queryParams: URLSearchParams) {
        window.location.href = `${environment[`B2B_OLREG_${lang.toUpperCase()}`]}?${queryParams}`;
    }

    redirectB2C(lang: string, queryParams: URLSearchParams) {
        window.location.href = `${environment[`B2C_OLREG_${lang.toUpperCase()}`]}?${queryParams}`;
    }
}
