import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-add-site',
  templateUrl: './add-site.component.html',
  styleUrls: ['./add-site.component.scss'],
})
export class AddSiteComponent {
  @Input() itemNumber: number;
  @Output() addClicked = new EventEmitter<void>();
}
