<div [ngClass]="{ 'centered-multisite': sitesDisplay?.length < 3 }">
  <swiper-container id="swiper" appSwiper #swiper [config]="swiperConfig" init="false" class="mt-12">
    <swiper-slide *ngFor="let siteDisplay of sitesDisplay; let i = index">
      <div (click)="showSiteDetails(i)">
        <app-site-title
          [siteDisplay]="siteDisplay"
          [displayChevron]="displayChevron"
          [numberToDisplay]="i + 1"
        ></app-site-title>
        <div
          *ngIf="siteDisplay?.bundles?.length > 0"
          class="bg-white mx-4 rounded-b-4xl py-2 px-4 text-xs text-grey-dark60 sm:mx-8"
        >
          <div *ngFor="let bundle of siteDisplay?.bundles">
            <span>{{ bundle.name }}</span>
            <span class="font-bold float-right">{{
              ((bundle?.electricityAmount || 0) + (bundle?.gasAmount || 0)) / 12 | currency: 'EUR' : 'symbol' : '1.2-2'
            }}</span>
          </div>
        </div>
      </div>
    </swiper-slide>
  </swiper-container>
</div>

<ng-container *ngIf="selectedSiteNumber !== null">
  <app-site-details-modal (closeDetail)="resetSelectedSiteSimulation()">
    <app-site-details [siteDisplay]="sitesDisplay[selectedSiteNumber]" [siteNumber]="selectedSiteNumber + 1">
    </app-site-details>
  </app-site-details-modal>
</ng-container>
