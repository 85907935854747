<div class="px-8 xl:max-w-screen-xl xl:w-full xl:m-auto" *ngIf="formGroup">
  <app-title class="block w-full max-w-5xl mx-auto text-center"
             title="pages.meterType.title" subtitle="pages.meterType.subtitle"
  ></app-title>
  <div [formGroup]="formGroup" class="mt-8">
    <app-input-radio-wrapper
      class="flex flex-wrap md:my-12 justify-center"
      formControlName="meterType"
      [configurations]="options"
    >
    </app-input-radio-wrapper>
    <app-input-checkbox
      class="w-full md:max-w-3xl block mx-auto mb-16"
      formControlName="exclusiveNight"
      [id]="'EXCLUSIVE_NIGHT'"
      [label]="'pages.meterType.formElements.exclusiveNight' | translate"
    >
    </app-input-checkbox>
  </div>
  <app-navigation [stepValidity]="formGroup.valid"
                  [automaticTransition]="false"
                  (nextClicked)="onNextClicked()"
                  (previousClicked)="onPreviousClicked()">
  </app-navigation>
</div>
