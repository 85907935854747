import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { InputRadioConfig } from '../../shared/custom-form-control/input-radio-wrapper/input-radio-wrapper.component';
import { QuestionFacade } from '../../core/facade/question.facade';
import { NumberOfSite } from '../../core/state/state';

@Component({
    selector: 'app-site-number',
    templateUrl: './site-number.component.html',
    styleUrls: ['./site-number.component.scss'],
})
export class SiteNumberComponent implements OnInit {
    options: InputRadioConfig[] = [
        {id: NumberOfSite.LESSER_OR_EQUAL_THAN_8, value: NumberOfSite.LESSER_OR_EQUAL_THAN_8, image: './assets/img/lesserThan8.svg', caption: 'pages.siteNumber.formElements.lesserThan8.label'},
        {id: NumberOfSite.MORE_THAN_8, value: NumberOfSite.MORE_THAN_8, image: './assets/img/moreThan8.svg', caption: 'pages.siteNumber.formElements.moreThan8.label'},
    ];
    formControl: UntypedFormControl;

    constructor(private readonly questionFacade: QuestionFacade) {
    }

    ngOnInit(): void {
        this.questionFacade.findData(state => state.numberOfSites).subscribe(value =>
            this.formControl = new UntypedFormControl(value, Validators.required)
        );
    }

    get siteNumber() {
        return this.formControl;
    }

    onPreviousClicked() {
        this.questionFacade.previous();
    }

    onNextClicked() {
        this.questionFacade.updateData({numberOfSites: this.formControl.value});
        this.questionFacade.next();
    }

}
