import { Component } from '@angular/core';
import { Card } from '../../shared/card/card.component';
import { QuestionFacade } from '../../core/facade/question.facade';

@Component({
    selector: 'app-profil-not-found',
    templateUrl: './profil-not-found.component.html'
})
export class ProfilNotFoundComponent {
    profilNotFoundCard: Card = new Card(
        `pages.contact.cards.tel.title`,
        `pages.contact.cards.tel.content`,
        null,
        null,
        null,
        false,
        'icon phone-icon'
    );

    constructor(private readonly questionFacade: QuestionFacade) {
    }

    onPreviousClicked() {
        this.questionFacade.previous();
    }

    onNextClicked() {
        this.questionFacade.next();
    }

}
