import { Energy, MeterType, Site } from '../state/state';
import { ZipCodeService } from './zip-code.service';

export const YEARLY_AVERAGE_PRODUCTION = 932;
export const INJECTION_FACTOR = 0.63;

export class InjectionService {
  static hasInjectionFields(currentSite: Site): boolean {
    const hasSolarPanels = currentSite.hasSolarPanels;
    const solarPanelKva = currentSite.solarPanelKva;
    const smartMeter = currentSite.smartMeter;
    const energy = currentSite.energy;
    const zipCode = currentSite.zipCode;
    return (
      (energy != null) &&
      energy.includes(Energy.ELEC) &&
      hasSolarPanels &&
      solarPanelKva > -1 &&
      ((ZipCodeService.isFlanders(zipCode) && smartMeter) ||
        ZipCodeService.isBrussels(zipCode) ||
        this.showIjnjectionForWallonia(zipCode, smartMeter, currentSite))
    );
  }

  static showIjnjectionForWallonia(zipCode: number, smartMeter: boolean, currentSite: Site) {
    return (
      ZipCodeService.isWallonia(zipCode) &&
      smartMeter &&
      (currentSite.solarPanelsInstallationDateStarting2024 ||
        (!currentSite.solarPanelsInstallationDateStarting2024 && currentSite.injectionTariff))
    );
  }

  static getDefaultInjectionValues(site: Site): number[] {
    if (!site || !this.hasInjectionFields(site)) {
      return undefined;
    }
    const solarPanelKva = site.solarPanelKva;
    const meterType = site.meterType;
    const injection = solarPanelKva * YEARLY_AVERAGE_PRODUCTION * INJECTION_FACTOR;
    if (meterType.includes(MeterType.MONO)) {
      return [Number(injection.toFixed(2)), null];
    }
    const injectionDayValue = (injection / 7) * 5;
    const injectionNightValue = (injection / 7) * 2;
    return [Number(injectionDayValue.toFixed(2)), Number(injectionNightValue.toFixed(2))];
  }
}
