import { Component, OnInit } from '@angular/core';
import { InputRadioConfig } from '../../shared/custom-form-control/input-radio-wrapper/input-radio-wrapper.component';
import { HabitationYear, Site } from '../../core/state/state';
import { UntypedFormControl, Validators } from '@angular/forms';
import { QuestionFacade } from '../../core/facade/question.facade';
import { takeUntil } from 'rxjs/operators';
import { CompleteObservables } from 'src/app/shared/complete-observables';

@Component({
  selector: 'app-habitation-year',
  templateUrl: './habitation-year.component.html',
})
export class HabitationYearComponent extends CompleteObservables implements OnInit {
  options: InputRadioConfig[] = [
    {
      id: HabitationYear.UNKNOWN,
      value: HabitationYear.UNKNOWN,
      caption: `pages.unknownConsumptions.building.constructionDate.choices.${HabitationYear.UNKNOWN.toLowerCase()}`,
    },
    {
      id: HabitationYear.BEFORE_1960,
      value: HabitationYear.BEFORE_1960,
      caption: `pages.unknownConsumptions.building.constructionDate.choices.${HabitationYear.BEFORE_1960.toLowerCase()}`,
    },
    {
      id: HabitationYear.BETWEEN_1960_AND_1970,
      value: HabitationYear.BETWEEN_1960_AND_1970,
      caption: `pages.unknownConsumptions.building.constructionDate.choices.${HabitationYear.BETWEEN_1960_AND_1970.toLowerCase()}`,
    },
    {
      id: HabitationYear.BETWEEN_1971_AND_1980,
      value: HabitationYear.BETWEEN_1971_AND_1980,
      caption: `pages.unknownConsumptions.building.constructionDate.choices.${HabitationYear.BETWEEN_1971_AND_1980.toLowerCase()}`,
    },
    {
      id: HabitationYear.BETWEEN_1981_AND_2000,
      value: HabitationYear.BETWEEN_1981_AND_2000,
      caption: `pages.unknownConsumptions.building.constructionDate.choices.${HabitationYear.BETWEEN_1981_AND_2000.toLowerCase()}`,
    },
    {
      id: HabitationYear.BETWEEN_2001_AND_2012,
      value: HabitationYear.BETWEEN_2001_AND_2012,
      caption: `pages.unknownConsumptions.building.constructionDate.choices.${HabitationYear.BETWEEN_2001_AND_2012.toLowerCase()}`,
    },
    {
      id: HabitationYear.AFTER_2012,
      value: HabitationYear.AFTER_2012,
      caption: `pages.unknownConsumptions.building.constructionDate.choices.${HabitationYear.AFTER_2012.toLowerCase()}`,
    },
  ];
  formControl: UntypedFormControl;
  site: Site;

  constructor(private readonly questionFacade: QuestionFacade) {
    super();
  }

  ngOnInit(): void {
    this.questionFacade
      .findSiteData((site) => site)
      .pipe(takeUntil(this.destroy$))
      .subscribe((site) => {
        this.site = site;
        this.formControl = new UntypedFormControl(
          site?.unknownConsumption?.building?.constructionDate,
          Validators.required,
        );
      });
  }

  onPreviousClicked(): void {
    this.questionFacade.previous();
  }

  onNextClicked(): void {
    const site = {
      ...this.site,
      unknownConsumption: {
        ...this.site?.unknownConsumption,
        building: {
          ...this.site?.unknownConsumption?.building,
          constructionDate: this.formControl.value,
        },
      },
    };
    this.questionFacade.updateSiteData(site);
    this.questionFacade.next();
  }
}
