import { Component, Input } from '@angular/core';
import { SiteDisplay } from '../../../../features/results/sites/sites.component';

@Component({
  selector: 'app-site-title',
  templateUrl: './site-title.component.html',
  styleUrls: ['./site-title.component.scss']
})
export class SiteTitleComponent {

  @Input() siteDisplay: SiteDisplay;
  @Input() displayChevron: boolean;
  @Input() numberToDisplay: number;
  @Input() fullRadius = true;

}
