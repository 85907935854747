<div class="root">
    <app-icons
      [iconName]="icon"
      class="top-0 left-1/2 -translate-y-1/2 -translate-x-1/2"
    ></app-icons>
    <div class="form__item">
        <ng-content></ng-content>
    </div>
</div>

