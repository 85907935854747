import { Injectable } from '@angular/core';
import { QuestionFacade } from '../../../core/facade/question.facade';
import { first, map, shareReplay, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { TypeDetectorService } from '../../../core/service/type-detector.service';
import { Type } from '../../../core/state/state';
import { Bundles, BundleDetails, PromotionDetails, BundleAmount } from 'src/app/core/bundle/bundle';

@Injectable({
  providedIn: 'root',
})
export class BundlesListService {
  bundles$: Observable<Bundles[]>;
  bundlesDetails$: Observable<BundleDetails[]>;

  constructor(private readonly questionFacade: QuestionFacade) {}

  getBundles(): Observable<Bundles[]> {
    if (this.bundles$) {
      return this.bundles$;
    }
    this.bundles$ = this.questionFacade.getBundles().pipe(shareReplay(1));

    return this.bundles$;
  }

  getBundlesFrom(code: string): Observable<Bundles> {
    return this.getBundles().pipe(map((bundles) => bundles.find((bundle) => bundle.code === code)));
  }

  getPromoDetails(code: string, commercialCode: string): Observable<PromotionDetails> {
    return this.getBundles().pipe(
      map(
        (bundles) =>
          bundles.find((bundle) => bundle.code === code) || bundles.find((bundle) => bundle.name === commercialCode),
      ),
      map((bundle) => bundle.promoCode),
      switchMap((promoCode) => (promoCode ? this.questionFacade.getPromotionDetails(promoCode) : of(null))),
    );
  }

  getBundleDetails(bundles: Bundles[]): Observable<BundleDetails[]> {
    return this.getBundlesDetails().pipe(
      map((bundlesDetails) =>
        bundlesDetails
          .filter((bundleDetails) => bundles.find((bundle) => bundleDetails.commercialCode === bundle.name))
          .sort((a, b) => b.weighting - a.weighting), // Descending order
      ),
      first(),
    );
  }

  getBundleDetailsFrom(code: string): Observable<BundleDetails> {
    return this.getBundlesDetails().pipe(
      map((bundlesDetails) => bundlesDetails.find((bundle) => code === bundle.code)),
      first(),
    );
  }

  getBundlePriceInfo(code: string, commercialCode: string): Observable<BundleAmount> {
    if (TypeDetectorService.detectType() === Type.B2C) {
      return this.getBundles().pipe(
        map((bundles) => bundles.find((bundle) => bundle.code === code)),
        map((bundle) => {
          const electricityPromoAmountTotal = bundle.electricityPromoAmount || 0;
          const electricityAmountTotal = bundle.electricityAmount || 0;
          const gasAmountTotal = bundle.gasAmount || 0;
          const gasPromoAmountTotal = bundle.gasPromoAmount || 0;

          return {
            totalAmount: electricityAmountTotal + gasAmountTotal,
            totalPromoAmount: electricityPromoAmountTotal + gasPromoAmountTotal,
            electricityAmount: electricityAmountTotal,
            electricityPromoAmount: electricityPromoAmountTotal,
            gasAmount: gasAmountTotal,
            gasPromoAmount: gasPromoAmountTotal,
          };
        }),
      );
    } else {
      return this.getBundles().pipe(
        map((bundles) => bundles.filter((bundle) => bundle.name === commercialCode)),
        map((bundles) => {
          const electricityPromoAmountTotal = bundles.reduce(
            (sum, value) => sum + (value.electricityPromoAmount || 0),
            0,
          );
          const electricityAmountTotal = bundles.reduce((sum, value) => sum + (value.electricityAmount || 0), 0);
          const gasAmountTotal = bundles.reduce((sum, value) => sum + (value.gasAmount || 0), 0);
          const gasPromoAmountTotal = bundles.reduce((sum, value) => sum + (value.gasPromoAmount || 0), 0);

          return {
            totalAmount: electricityAmountTotal + gasAmountTotal,
            totalPromoAmount: electricityPromoAmountTotal + gasPromoAmountTotal,
            electricityAmount: electricityAmountTotal,
            electricityPromoAmount: electricityPromoAmountTotal,
            gasAmount: gasAmountTotal,
            gasPromoAmount: gasPromoAmountTotal,
          };
        }),
      );
    }
  }

  findBundle(bundleType: string, code: string): Observable<Bundles> {
    return this.getBundles().pipe(
      map((current) => current.find((curr) => curr.bundleType === bundleType && curr.code === code)),
      first(),
    );
  }

  getBundlesDetails(): Observable<BundleDetails[]> {
    if (this.bundlesDetails$) {
      return this.bundlesDetails$;
    }
    this.bundlesDetails$ = this.questionFacade.getBundlesDetails().pipe(shareReplay(1));

    return this.bundlesDetails$;
  }
}
