<div class="px-8 xl:max-w-screen-xl xl:w-full xl:m-auto" *ngIf="formControl">
    <app-title class="block w-full max-w-5xl mx-auto text-center"
               title="pages.energy.title"
               subtitle="pages.energy.subtitle"
    ></app-title>
    <div class="mt-8">
        <app-input-radio-wrapper
                class="flex flex-wrap md:my-12 justify-center"
                [formControl]="formControl"
                [configurations]="options"
        >
        </app-input-radio-wrapper>
    </div>
    <app-navigation [stepValidity]="formControl.valid"
                    (nextClicked)="onNextClicked()"
                    (previousClicked)="onPreviousClicked()">
    </app-navigation>
</div>
