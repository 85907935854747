import {
  BoilerYear,
  HabitationType,
  HabitationVolume,
  HabitationYear,
  HeatingPumpType,
  HeatingType,
} from '../state/state';

export interface LocalizedValue {
  fr: string;
  nl: string;
}

export enum BundleType {
  FIX = 'FIX',
  VARIABLE = 'VARIABLE',
}

export enum ProspectEnergy {
  ELEC = 'ELEC',
  GAS = 'GAS',
  ELEC_GAS = 'ELEC_GAS',
  ELECTRICITY_GAS = 'ELECTRICITY_GAS',
  ELECTRICITY = 'ELECTRICITY',
}

export enum ProspectMeterType {
  CONSUMPTION = 'CONSUMPTION',
  INJECTION = 'INJECTION',
}

export enum Register {
  MONO = 'MONO',
  LOW = 'LOW',
  HIGH = 'HIGH',
  EXCLUSIVE_NIGHT = 'EXCLUSIVE_NIGHT',
}

export interface BundleAmount {
  totalAmount: number;
  totalPromoAmount: number;
  electricityAmount: number;
  electricityPromoAmount: number;
  gasAmount: number;
  gasPromoAmount: number;
}

export interface BundleView extends BundleAmount {
  commercialCode: string;
  code: string;
  picture: string;
  color: string;
  gradient: string;
  commercialLinks: LocalizedValue;
  description: LocalizedValue;
  bundleLinked: boolean;
  linkedBundle: null | LinkedBundle;
  bundleType: BundleType;
  weighting: number;
  promo: { title: LocalizedValue; description: LocalizedValue };
}

export interface PromotionDetails {
  title: LocalizedValue;
  description?: LocalizedValue;
}

export interface BundleDetails {
  commercialCode: string;
  code: string;
  color: string;
  gradient: string;
  description: LocalizedValue;
  name: LocalizedValue;
  commercialLinks?: LocalizedValue;
  picture?: string;
  weighting: number;
}

export interface Bundles {
  code: string;
  name: string;
  promoCode?: string;
  gasAmount?: number;
  gasPromoAmount?: number;
  electricityAmount?: number;
  electricityPromoAmount?: number;
  weighting?: number;
  bundleType: BundleType;
  linkedBundle: null | LinkedBundle;
}

export interface LinkedBundle {
  bundleType: BundleType;
  code: string;
}

export interface UnknownConsumptions {
  heatingEnergy?: HeatingEnergy;
  building?: Building;
  appliances?: string[];
  baking?: object;
  hotWater?: { energy: object };
  simulationType?: string;
}

export interface HeatingEnergy {
  type?: HeatingType;
  installationDate?: BoilerYear;
  heatingPumpType?: HeatingPumpType;
}

export interface Building {
  buildingType?: HabitationType | string;
  occupiedBy?: number;
  constructionDate?: HabitationYear;
  area?: HabitationVolume;
  facade?: number;
}
