import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { QuestionFacade } from '../../core/facade/question.facade';
import { delay, filter } from 'rxjs/operators';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent implements OnChanges {
  @Input() stepValidity: boolean;
  @Input() automaticTransition = true;
  @Input() lastStep = false;
  @Input() nextLabel = 'navigation.next';
  @Input() disableNextStyle: boolean;
  @Output() nextClicked = new EventEmitter<void>();
  @Output() previousClicked = new EventEmitter<void>();

  constructor(public questionFacade: QuestionFacade) {}

  ngOnInit(): void {
    if (this.lastStep) {
      this.nextLabel = 'navigation.computeMyPrice';
    }
  }

  ngOnChanges(): void {
    this.questionFacade
      .findData((state) => state)
      .pipe(
        delay(500),
        filter((state) => {
          const { stepsReached } = this.questionFacade.getCurrentSite();
          const isNewStep = !stepsReached.has(state.currentStepUrl);
          return this.automaticTransition && this.stepValidity && isNewStep;
        }),
      )
      .subscribe(() => this.nextClicked.emit());
  }
}
