import { Inject, Injectable, Optional } from '@angular/core';
import { APP_BASE_HREF, PathLocationStrategy, PlatformLocation } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { ORDERED_QUESTION_B2B_CONFIG } from './core/service/questions/config/questions-config-b2b';
import { ORDERED_QUESTION_B2C_CONFIG } from './core/service/questions/config/questions-config-b2c';
import { TypeDetectorService } from './core/service/type-detector.service';
import { Type } from './core/state/state';
import { I18nService } from './core/service/i18n/i18n.service';


@Injectable()
export class TranslateLocationStrategy extends PathLocationStrategy {

  constructor(
    private readonly translateService: TranslateService,
    private readonly i18nService: I18nService,
    _platformLocation: PlatformLocation,
    @Optional() @Inject(APP_BASE_HREF) href?: string
  ) {
    super(_platformLocation, href);
  }

  prepareExternalUrl(internal: string): string {
    if (internal === '/') {
    return internal;
    }
    const b2bPrefixes = {
      'fr': '/fr/professionnels/produits-et-services/my-business/notre-offre-energie/calculez-votre-prix',
      'nl': '/nl/professioneel/producten-en-diensten/my-business/ons-energieaanbod/bereken-jouw-prijs'
    };
    const b2cPrefixes = {
      'fr': '/fr/particuliers/produits-et-services/my-home/electricite-et-gaz/offre-electricite-et-gaz/calculez-votre-prix',
      'nl': '/nl/particulieren/producten-en-diensten/my-home/elektriciteit-en-aardgas/aanbod-electriciteit-en-aardgas/bereken-jouw-prijs'
    };
    const prefixToUse = TypeDetectorService.detectType() === Type.B2B ? b2bPrefixes : b2cPrefixes;
    const prefix = prefixToUse[this.i18nService.language] ? prefixToUse[this.i18nService.language] : prefixToUse['fr'];
    const routeWithoutPrefix = internal.includes(prefix) ? internal.replace(prefix, '') : internal;
    return prefix + '/' + this.translateRoute(this.i18nService.language, decodeURIComponent(routeWithoutPrefix));
  }

  private translateRoute(lang: string, route: string): string {
    const queryParamsStartIndex = decodeURIComponent(route).indexOf('?');
    const routeWithoutParams = queryParamsStartIndex !== -1 ? decodeURIComponent(route.substring(0, queryParamsStartIndex)) : route;

    const routeTranslatePerUri = new Map<string, { 'fr': string, 'nl': string }>();
    (TypeDetectorService.detectType() === Type.B2B ? ORDERED_QUESTION_B2B_CONFIG : ORDERED_QUESTION_B2C_CONFIG).forEach(question => {
      routeTranslatePerUri.set(question.getUrl(), {
        fr: question.getFrTranslatedUrl(),
        nl: question.getNlTranslatedUrl()
      });
      routeTranslatePerUri.set('/' + question.getFrTranslatedUrl(), { // if user refresh page with translated path
        fr: question.getFrTranslatedUrl(),
        nl: question.getNlTranslatedUrl()
      });
      routeTranslatePerUri.set('/' + question.getNlTranslatedUrl(), { // if user refresh page with translated path
        fr: question.getFrTranslatedUrl(),
        nl: question.getNlTranslatedUrl()
      });
    });
    routeTranslatePerUri.set('/profil-not-found', {nl: 'profil-not-found', fr: 'profil-not-found'});
    routeTranslatePerUri.set('/error', {nl: 'error', fr: 'error'});
    if (!routeTranslatePerUri.get(routeWithoutParams)) {
      return decodeURIComponent(route);
    }
    return routeTranslatePerUri.get(routeWithoutParams)[lang] + (queryParamsStartIndex !== -1 ? route.substring(queryParamsStartIndex, route.lastIndexOf('?') !== queryParamsStartIndex ?  route.lastIndexOf('?'): route.length) : '');  }
}
