<div class="site-details p-8">
  <ul class="border-solid border-b border-grey pb-4 mb-8 flex-col" *ngIf="siteDisplay?.site?.meterType">
    <li *ngIf="false">
      <span> <img src="../../../../../assets/img/category-ico.svg" alt="" /> Categorie: </span>
      <strong>Kantoor</strong>
    </li>
    <li *ngIf="siteDisplay?.site?.meterType">
      <span>
        <img src="../../../../../assets/img/meter-ico.svg" alt="" />{{ 'pages.result.meterType' | translate }}:
      </span>
      <strong>
        {{
          'pages.meterType.formElements.' +
            (siteDisplay?.site?.meterType?.includes('MONO') ? 'mono' : 'dual') +
            '.label' | translate
        }}
      </strong>
    </li>
  </ul>
  <ul>
    <li *ngIf="siteDisplay?.site?.energy.includes(Energy.ELEC)">
      <span>
        <img src="../../../../../assets/img/elec-ico.svg" alt="" />
        {{ 'pages.result.elecConsumption' | translate }}:
      </span>
      <strong>
        {{ totalElec | number: '1.2-2' }}
        {{ 'pages.result.consumptionPerYearUnit' | translate }}
      </strong>
    </li>
    <li *ngIf="siteDisplay?.site?.energy.includes(Energy.GAS)">
      <span>
        <img src="../../../../../assets/img/gas-ico.svg" alt="" /> {{ 'pages.result.gasConsumption' | translate }}:
      </span>
      <strong>
        {{ totalGas | number: '1.2-2' }}
        {{ 'pages.result.consumptionPerYearUnit' | translate }}
      </strong>
    </li>
    <li *ngIf="siteDisplay?.site?.energy.includes(Energy.ELEC) && totalInjection !== 0">
      <span>
        <img src="../../../../../assets/img/solar-ico.svg" alt="" /> {{ 'pages.result.injection' | translate }}:
      </span>
      <strong>
        {{ totalInjection | number: '1.2-2' }}
        {{ 'pages.result.consumptionPerYearUnit' | translate }}
      </strong>
    </li>
  </ul>
  <ng-container *ngIf="siteDisplay?.bundles?.length">
    <div *ngFor="let product of siteDisplay?.bundles" class="flex bg-grey-light rounded-4xl p-8 mt-10 items-center">
      <span class="flex-1 text-grey-dark60 text-sm font-medium mr-8">
        {{ 'pages.result.pricePerProduct' | translate: { value: product.name } }}
      </span>
      <span class="text-right font-display font-normal te-text-gradient-home inline-flex">
        <h4 class="text-xl" style="line-height: 90%">
          {{ ((product.electricityAmount || 0) + (product.gasAmount || 0)) / 12 | currency: 'EUR':'symbol':'1.2-2' }}
        </h4>
        <span class="text-sm ml-2">{{ 'pages.result.perMonth' | translate }}</span>
      </span>
    </div>
  </ng-container>
</div>
