import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { CustomFormParentComponent } from '../custom-form-parent.component';
import * as uuid from 'uuid';

@Component({
  selector: 'app-input-text',
  templateUrl: 'input-text.component.html',
  styleUrls: ['input-text.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputTextComponent),
      multi: true,
    },
  ],
})
export class InputTextComponent extends CustomFormParentComponent<number> {
  @Input() inputTextConfig: InputTextConfig;
  @Input() id: string = uuid.v4();
  @Output() inputTextBlur = new EventEmitter<void>();
}

export interface InputTextConfig {
  labelKey: string;
  placeHolder?: string;
  placeHolderValues?: object;
  errors: Map<string, string>;
  pattern?: string;
  info?: string;
  inputSuffix?: string;
  multisiteEnabled?: boolean;
  maxLength?: number;
}
