import { Component } from '@angular/core';
import { map } from 'rxjs/operators';
import { Type } from '../../state/state';
import { QuestionFacade } from '../../facade/question.facade';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  year = new Date().getFullYear();
  b2b$: Observable<boolean>;

  constructor(private readonly questionFacade: QuestionFacade) {
    this.b2b$ = this.questionFacade.findData(state => state.type).pipe(
      map(type => type === Type.B2B));
  }
}
