<app-input-radio
  *ngFor="let config of configurations"
  [id]="config.id"
  [attr.data-cy]="config.id"
  [value]="config.value"
  [image]="config.image"
  [caption]="config.caption | translate"
  [checked]="config.value === value"
  (emitValue)="updateValue($event)"
  class="w-full mb-8 sm:flex-1 sm:max-w-md sm:w-auto {{ config?.image && square ? 'sm:mx-8' : 'sm:mx-4' }}"
  [ngClass]="{'sm:aspect-square': config?.image && square}"
>
</app-input-radio>
