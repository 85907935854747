import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ActivationStart, NavigationStart, Router, Event } from '@angular/router';
import { routerAnimation } from './shared/animations';
import { distinct, distinctUntilChanged, filter, map, mergeMap } from 'rxjs/operators';
import { QuestionFacade } from './core/facade/question.facade';
import { Observable, combineLatest, of } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [routerAnimation()],
})
export class AppComponent {
  step: number;
  navigationStart: Observable<Event>;

  constructor(
    public translate: TranslateService,
    private readonly router: Router,
    private readonly questionFacade: QuestionFacade,
  ) {
    this.navigationStart = this.router.events.pipe(
      filter(this.filterNavigationStartOnPopState),
      map((event: NavigationStart) => event)
    );

    this.router.events.pipe(distinctUntilChanged()).subscribe(() => {
      const newStep = this.router.getCurrentNavigation()?.extras.state?.step;
      // Set step only when needed otherwise animation between steps doesn't work
      if (newStep >= 0 && newStep !== this.step) {
        this.step = this.router.getCurrentNavigation()?.extras.state?.step;
      }
    });

    this.router.events
      .pipe(
        filter(this.isEventActivationStart),
        distinctUntilChanged(),
        map((event) => combineLatest([of(event), this.navigationStart])),
        mergeMap((item) => item),
        distinct(([, navigationStart]) => navigationStart['id'])
      )
      .subscribe(() => this.questionFacade.previous());
  }

  private filterNavigationStartOnPopState(event: Event): boolean {
    return event instanceof NavigationStart && event.navigationTrigger === 'popstate';
  }

  isEventActivationStart(event: Event): event is ActivationStart {
    return event instanceof ActivationStart;
  }
}
