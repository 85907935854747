<div [formGroup]="form" class="grid sm:grid-cols-2 md:grid-cols-3 gap-12">
    <div
      *ngFor="let entry of profils | keyvalue"
      class="box-shadow-small transition-colors duration-500 bg-white rounded-3xl p-8 cursor-pointer"
    >
      <input
        type="radio"
        class="hidden"
        [id]="entry.key"
        [value]="entry.key"
        name="profil"
        formControlName="profil"
      />
      <label for="{{ entry.key }}" class="cursor-pointer">
        <h3 class="job-title">{{ profils[entry.key].title | translate }}</h3>
        <div>
          <span class="text-sm inline-block w-full">{{ 'pages.consumptionsProfilsList.consumption' | translate }}</span>
          <span *ngIf="energy === Energy.ELEC || energy === Energy.ELEC_GAS" class="text-sm font-medium inline-block w-full">
            {{ profils[entry.key].elecConsumptionMono }}
            {{ 'pages.consumptionsProfilsList.unit' | translate }}
            ({{ 'pages.consumptionsProfilsList.electricity' | translate }})
          </span>
          <span *ngIf="energy === Energy.GAS || energy === Energy.ELEC_GAS" class="text-sm font-medium inline-block w-full">
            {{ profils[entry.key].gasConsumption }}
            {{ 'pages.consumptionsProfilsList.unit' | translate }}
            ({{ 'pages.consumptionsProfilsList.gas' | translate }})
          </span>
        </div>
      </label>
    </div>
</div>
