<div class="card {{ card.modifiers }}" *ngIf="card" [ngClass]="{ 'card--clickable': card?.isClickable }">
  <div
    *ngIf="card.background"
    class="media__content"
    role="presentation"
    [ngStyle]="{ 'background-image': 'url(' + card.background + ')', 'min-height': '170px' }"
  ></div>
  <div class="card__content">
    <h3 class="card__title" [innerHTML]="card.title | translate"></h3>
    <div
      class="flex flex-col items-center text-center border-solid pt-8 mt-8 border-t border-grey break-anywhere"
      (click)="contentClicked.emit()"
      [innerHTML]="card.content | translate: card.contentParams"
    ></div>
    <div *ngIf="card?.linkURL && card?.linkLabel" class="text-center mt-8">
      <a class="btn btn-primary" [href]="card.linkURL | translate">
        {{ card.linkLabel | translate }}
      </a>
    </div>
  </div>
</div>
