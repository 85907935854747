import { Bundles, UnknownConsumptions } from '../bundle/bundle';
import { TypeDetectorService } from '../service/type-detector.service';

export interface State {
  readonly currentStepUrl: string;
  readonly numberOfSites: string;
  readonly numberOfStep: number;
  readonly numberOfStepExecuted: number;
  readonly simulationId: string;
  readonly promo: string;
  readonly sites: Site[];
  readonly type: Type;
  readonly currentSiteIndex: number;
}

export enum Type {
  B2B = 'B2B',
  B2C = 'B2C',
}

export const INITIAL_STATE: State = {
  currentStepUrl: TypeDetectorService.detectType() === Type.B2B ? '/site-number' : '/energy',
  numberOfStep: 10,
  numberOfStepExecuted: 0,
  numberOfSites: undefined,
  simulationId: undefined,
  promo: undefined,
  type: TypeDetectorService.detectType(),
  currentSiteIndex: 0,
  sites: [
    {
      siteName: '',
      energy: undefined,
      knowConsumption: undefined,
      zipCode: undefined,
      town: undefined,
      smartMeter: undefined,
      hasSolarPanels: undefined,
      solarPanelsInstallationDateStarting2024: undefined,
      injectionTariff: undefined,
      solarPanelKva: undefined,
      hasElectricVehicle: undefined,
      averagePeak: undefined,
      meterType: undefined,
      consumptions: {
        consumptionHigh: undefined,
        consumptionLow: undefined,
        consumptionMono: undefined,
        consumptionExclusiveNight: undefined,
        gasConsumption: undefined,
        injectionHigh: undefined,
        injectionLow: undefined,
        injectionMono: undefined,
      },
      exclusiveNight: undefined,
      consumptionHelp: undefined,
      simulation: undefined,
      stepsReached: new Set<string>(),
    },
  ],
};

export function findCurrentSite(state: State): Site {
  return state.sites[state.currentSiteIndex];
}

export interface Site {
  readonly siteName: string;
  readonly energy: Energy;
  readonly knowConsumption: boolean;
  readonly zipCode?: number;
  readonly town?: string;
  readonly smartMeter?: boolean;
  readonly hasElectricVehicle?: boolean;
  readonly hasSolarPanels?: boolean;
  readonly solarPanelsInstallationDateStarting2024?: boolean;
  readonly injectionTariff?: boolean;
  readonly solarPanelKva?: number;
  readonly averagePeak?: number;
  readonly meterType: MeterType;
  consumptions: Consumptions;
  readonly consumptionHelp: string;
  readonly exclusiveNight: boolean;
  readonly simulation: Bundles[];
  readonly unknownConsumption?: UnknownConsumptions;
  readonly stepsReached: Set<string>;
}

export interface Consumptions {
  consumptionHigh: number;
  consumptionLow: number;
  consumptionMono: number;
  consumptionExclusiveNight: number;
  gasConsumption: number;
  injectionHigh: number;
  injectionLow: number;
  injectionMono: number;
}

export enum HabitationType {
  FLAT = 'FLAT',
  TWO = '2',
  THREE = '3',
  FOUR = '4',
}

export enum HabitantNumber {
  ONE = '1',
  TWO = '2',
  THREE = '3',
  FOUR = '4',
  FIVE = '5',
}

export enum HabitationVolume {
  LESS_THAN_50 = 'LESS_THAN_50',
  BETWEEN_50_AND_60 = 'BETWEEN_50_AND_60',
  BETWEEN_61_AND_80 = 'BETWEEN_61_AND_80',
  BETWEEN_81_AND_100 = 'BETWEEN_81_AND_100',
  BETWEEN_101_AND_120 = 'BETWEEN_101_AND_120',
  BETWEEN_121_AND_150 = 'BETWEEN_121_AND_150',
  MORE_THAN_150 = 'MORE_THAN_150',

  // 2 facade
  LESS_THAN_60 = 'LESS_THAN_60',
  BETWEEN_81_AND_110 = 'BETWEEN_81_AND_110',
  BETWEEN_111_AND_150 = 'BETWEEN_111_AND_150',
  BETWEEN_151_AND_200 = 'BETWEEN_151_AND_200',
  BETWEEN_201_AND_250 = 'BETWEEN_201_AND_250',
  MORE_THAN_250 = 'MORE_THAN_250',

  // 4 facade
  LESS_THAN_80 = 'LESS_THAN_80',
}

export enum HabitationYear {
  UNKNOWN = 'UNKNOWN',
  BEFORE_1960 = 'BEFORE_1960',
  BETWEEN_1960_AND_1970 = 'BETWEEN_1960_AND_1970',
  BETWEEN_1971_AND_1980 = 'BETWEEN_1971_AND_1980',
  BETWEEN_1981_AND_2000 = 'BETWEEN_1981_AND_2000',
  BETWEEN_2001_AND_2012 = 'BETWEEN_2001_AND_2012',
  AFTER_2012 = 'AFTER_2012',
}

export enum HeatingType {
  ELECTRICITY = 'ELECTRICITY',
  GAS = 'GAS',
  HEATING_PUMP = 'HEATING_PUMP',
  FUEL_OIL = 'FUEL_OIL',
  WOOD = 'WOOD',
  PELLET = 'PELLET',
  COLLECTIVE = 'COLLECTIVE',
}

export enum HeatingPumpType {
  AIR_TO_AIR = 'AIR_TO_AIR',
  AIR_TO_WATER = 'AIR_TO_WATER',
  GROUND_TO_WATER = 'GROUND_TO_WATER',
  UNKNOWN = 'UNKNOWN',
}

export enum BoilerYear {
  UNKNOWN = 'UNKNOWN',
  BEFORE_1985 = 'BEFORE_1985',
  BETWEEN_1986_2000 = 'BETWEEN_1986_2000',
  BETWEEN_2001_2015 = 'BETWEEN_2001_2015',
  AFTER_2016 = 'AFTER_2016',
}

export enum BoilerFuelYear {
  BEFORE_1975 = 'BEFORE_1975',
  BETWEEN_1976_AND_1985 = 'BETWEEN_1976_AND_1985',
  BETWEEN_1986_AND_2000 = 'BETWEEN_1986_AND_2000',
  AFTER_2001 = 'AFTER_2001',
  UNKNOWN = 'UNKNOWN',
}

export enum MeterType {
  MONO = 'MONO',
  DUAL = 'DUAL',
}

export enum Energy {
  ELEC = 'ELEC',
  GAS = 'GAS',
  ELEC_GAS = 'ELEC_GAS',
}

export enum NumberOfSite {
  MORE_THAN_8 = 'MORE_THAN_8',
  LESSER_OR_EQUAL_THAN_8 = 'LESSER_OR_EQUAL_THAN_8',
}

export function totalElec(consumptions: Consumptions) {
  return (
    (Number(consumptions.consumptionLow) || 0) +
    (Number(consumptions.consumptionHigh) || 0) +
    (Number(consumptions.consumptionMono) || 0) +
    (Number(consumptions.consumptionExclusiveNight) || 0)
  );
}
export function totalInjection(consumptions: Consumptions) {
  return (
    (Number(consumptions.injectionMono) || 0) +
    (Number(consumptions.injectionLow) || 0) +
    (Number(consumptions.injectionHigh) || 0)
  );
}

export function totalAllSiteConsumptionsGaz(state: State): number {
  return state.sites
    .map(
      (site) =>
        site.consumptions || {
          gasConsumption: 0,
        },
    )
    .reduce((acc, consumptions) => {
      acc += Number(consumptions.gasConsumption) || 0;
      return acc;
    }, 0);
}

export function totalAllSiteConsumptionsElec(state: State): number {
  return state.sites
    .map(
      (site) =>
        site.consumptions || {
          consumptionHigh: 0,
          consumptionLow: 0,
          consumptionMono: 0,
          consumptionExclusiveNight: 0,
          gasConsumption: 0,
          injectionHigh: 0,
          injectionLow: 0,
          injectionMono: 0,
        },
    )
    .reduce((acc, consumptions) => {
      acc += totalElec(consumptions);
      return acc;
    }, 0);
}

export function maxConsumptionReached(state: State): boolean {
  const maxConsumptionAllSites = 100000;
  return (
    totalAllSiteConsumptionsGaz(state) > maxConsumptionAllSites ||
    totalAllSiteConsumptionsElec(state) > maxConsumptionAllSites
  );
}
