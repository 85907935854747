<div class="site-details sm:p-12 sm:pt-6">
    <div>
        {{ 'pages.result.changeNamePopUp.namingDescription' | translate }}
    </div>
    <div class="sm:pt-6">
        <app-input-text
                id="siteName"
                [formControl]="formControl"
                [inputTextConfig]="INPUT_CONFIG">
        </app-input-text>
    </div>
</div>
