import { Energy } from '../../state/state';

export class DeprecatedOlregMapperService {
  static toLegacyOlRegQueryParams(urlParams) {
    const [productType, energy] = DeprecatedOlregMapperService.getProductAndEnergyFromBundle(urlParams.get('bundleCode'));
    urlParams.delete('bundleCode');
    urlParams.append('productType', productType);
    urlParams.set('energy', energy === 'DUAL' ? Energy.ELEC_GAS : energy);
    return urlParams;
  }

  private static getProductAndEnergyFromBundle(bundleCode: string) {
    const lastIndex = bundleCode.lastIndexOf('_');
    return [bundleCode.slice(0, lastIndex), bundleCode.slice(lastIndex + 1)];
  }
}
