<div class="relative mx-0">
    <app-site-title [siteDisplay]="siteDisplay"
                    [displayChevron]="false"
                    [fullRadius]="fullRoundedTitle"
                    [numberToDisplay]="siteNumber"></app-site-title>

</div>
<div class="bg-white rounded-b-4xl box-shadow-small">
    <div class="site-body">
        <app-site-info [siteDisplay]="siteDisplay"
                       [totalElec]="consumptions.elecTotalConsumptions"
                       [totalInjection]="consumptions.elecTotalInjection"
                       [totalGas]="consumptions.gasTotalConsumptions"
        ></app-site-info>
    </div>
    <div class="site-buttons">
        <ng-content></ng-content>
    </div>
</div>
