import { Injectable } from '@angular/core';
import { QuestionOrderHandlerService } from '../service/question-order-handler.service';
import {
  Consumptions,
  Energy,
  findCurrentSite,
  INITIAL_STATE,
  MeterType,
  NumberOfSite,
  Site,
  State,
  Type,
} from '../state/state';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, distinctUntilChanged, filter, first, map, mapTo, skip, switchMap, tap } from 'rxjs/operators';
import { OlregApiService } from '../api/olreg.api';
import { TranslateService } from '@ngx-translate/core';
import { Localities, LocalityApi } from '../api/locality.api';
import { InjectionService } from '../service/injection.service';
import { TrackingMapperService } from './mapper/tracking-mapper.service';
import { TrackingApi } from '../api/tracking.api';
import { SimulationEngineResponse, SimulatorEngineApi } from '../api/simulator-engine.api';
import { ZipCodeService } from '../service/zip-code.service';
import { DeprecatedOlregMapperService } from './mapper/deprecated-olreg-mapper.service';
import { HttpErrorResponse } from '@angular/common/http';
import { QueryParamsMapperService } from './mapper/query-params-mapper.service';
import { OlregB2bMapperService } from './mapper/olreg-b2b-mapper.service';
import { OlregB2CMapperService } from './mapper/olreg-b2c-mapper.service';
import { SimulatorEngineMapperService } from './mapper/simulator-engine-mapper.service';
import { ProductCatalogApi } from '../api/product-catalog.api';
import { CookieService } from 'ngx-cookie-service';
import { BundleDetails, Bundles, PromotionDetails } from '../bundle/bundle';

@Injectable({
  providedIn: 'root',
})
export class QuestionFacade {
  readonly state$ = new BehaviorSubject<State>(INITIAL_STATE);
  readonly sites$ = this.state$.asObservable().pipe(
    map((state) => state.sites),
    distinctUntilChanged(),
  );
  readonly currentStep$ = this.state$.asObservable().pipe(
    map((state) => state.currentStepUrl),
    distinctUntilChanged(),
  );
  readonly simulations$ = this.state$.asObservable().pipe(
    filter((state) => (state.sites || []).length > 0),
    map((state) => state.sites[0]),
    filter((firstSite) => !!firstSite.simulation),
    distinctUntilChanged(),
  );

  constructor(
    private readonly questionOrderHandlerService: QuestionOrderHandlerService,
    private readonly olregApi: OlregApiService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly localityApi: LocalityApi,
    private readonly router: Router,
    private readonly trackingApi: TrackingApi,
    private readonly translateService: TranslateService,
    private readonly productCatalogApi: ProductCatalogApi,
    private readonly simulatorEngineApi: SimulatorEngineApi,
    private readonly cookieService: CookieService,
  ) {
    this.currentStep$
      .pipe(filter(() => this.state$.value !== INITIAL_STATE || !window.location.href.includes('simulationId')))
      .subscribe((currentStep) =>
        this.router.navigate([currentStep], {
          queryParamsHandling: 'merge',
          queryParams: QueryParamsMapperService.getQueryParamsObject(),
          state: { step: this.state$.value.numberOfStepExecuted },
        }),
      );
    this.handleRefreshResultPage();
    this.handlePromotion();
    this.handleTrackingServiceEvent();
  }

  redirectFromWebsiteToOlreg(bundleCode: string): void {
    const sites = this.state$.value.sites;
    if (sites[0]?.simulation) {
      this.goToOlreg(bundleCode);
    }
  }

  trackAndGoToOlreg(bundleCode: string, price: number): void {
    this.sendSimulatorInteractionProductTrackingData(bundleCode, price / 12);
    // setTimeout to ensure the tracking data is updated before redirect to olreg
    setTimeout(() => {
      this.goToOlreg(bundleCode);
    });
  }

  goToOlreg(bundleCode: string): void {
    if (this.state$.value.type === Type.B2B) {
      this.goToOlregB2B(bundleCode);
    } else {
      this.gotToOlregB2C(bundleCode);
    }
  }

  next(): void {
    const state = this.state$.value;

    const nextUrl = this.questionOrderHandlerService.getNextQuestionUrl(state);
    const remainingStep = this.questionOrderHandlerService.remainingSteps({ ...state, currentStepUrl: nextUrl });
    const siteUpdated = this.updateStepsReached(state);
    this.setState((currentState) => ({
      ...currentState,
      currentStepUrl: nextUrl,
      sites: [...state.sites].map((site) => (site === this.getCurrentSite() ? siteUpdated : site)),
      numberOfStep: state.numberOfStepExecuted + remainingStep,
      numberOfStepExecuted: state.numberOfStepExecuted + 1,
    }));
  }

  previous(): void {
    const state = this.state$.value;
    const previousUrl = this.questionOrderHandlerService.getPreviousQuestionUrl(state);
    const remainingStep = this.questionOrderHandlerService.remainingSteps({ ...state, currentStepUrl: previousUrl });
    const updatedNumberOfStepExecuted = state.numberOfStepExecuted - 1;
    this.setState((currentState) => ({
      ...currentState,
      currentStepUrl: previousUrl,
      numberOfStep: remainingStep + updatedNumberOfStepExecuted,
      numberOfStepExecuted: updatedNumberOfStepExecuted,
    }));
  }

  updateSiteData(data: Partial<Site>): void {
    const siteUpdated = { ...findCurrentSite(this.state$.value), ...data };
    this.setState((state) => ({
      ...state,
      sites: [...state.sites].map((site) => (site === findCurrentSite(this.state$.value) ? siteUpdated : site)),
    }));
  }

  updateConsumption(consumptions: Partial<Consumptions>, consumptionHelp: string): void {
    const currentSite = findCurrentSite(this.state$.value);
    this.updateSiteData({
      consumptionHelp,
      consumptions: {
        consumptionHigh: currentSite.meterType === MeterType.MONO ? null : consumptions.consumptionHigh,
        consumptionLow: currentSite.meterType === MeterType.MONO ? null : consumptions.consumptionLow,
        consumptionMono: currentSite.meterType === MeterType.MONO ? consumptions.consumptionMono : null,
        consumptionExclusiveNight: currentSite.exclusiveNight ? consumptions.consumptionExclusiveNight : null,
        gasConsumption:
          currentSite.energy === Energy.GAS || currentSite.energy === Energy.ELEC_GAS
            ? consumptions.gasConsumption
            : null,
      } as Consumptions,
    });
  }

  reset(): void {
    this.state$.next(INITIAL_STATE);
    this.router.navigate([this.state$.value.currentStepUrl], {
      queryParamsHandling: 'merge',
      queryParams: QueryParamsMapperService.getQueryParamsObject(),
      state: { step: this.state$.value.numberOfStepExecuted },
    });
  }

  updateData(data: Partial<State>): void {
    this.setState((state) => ({
      ...state,
      ...data,
    }));
  }

  updateStepsReached(state: State): Site {
    const currentSite = this.getCurrentSite();
    currentSite.stepsReached.add(state.currentStepUrl);
    return currentSite;
  }

  addSite(): void {
    const stateUpdated = {
      ...this.state$.value,
      currentSiteIndex: this.state$.value.sites.length,
      currentStepUrl: INITIAL_STATE.currentStepUrl,
      sites: [
        ...this.state$.value.sites,
        {
          ...INITIAL_STATE.sites[0],
          stepsReached: new Set<string>(),
        },
      ],
    };
    const stateCurrentStepUpdated = {
      ...stateUpdated,
      currentStepUrl: this.questionOrderHandlerService.getNextQuestionUrl(stateUpdated),
    };
    this.updateData(stateCurrentStepUpdated);
    this.sendSimulatorInteractionTrackingData('Add delivery point');
  }

  deleteSite(siteIndex: number): void {
    const sites = [...this.state$.value.sites].filter((currentSite, index) => index !== siteIndex);
    this.updateData({
      sites: sites,
    });
    this.sendSimulatorInteractionTrackingData('Remove delivery point');
  }

  saveSiteName(siteIndex: number, name: string): void {
    this.updateData({
      currentSiteIndex: siteIndex,
    });
    this.updateSiteData({ siteName: name });
  }

  findSiteData<T>(mapFn: (data: Site) => T): Observable<T> {
    return this.sites$.pipe(
      map((_) => mapFn(findCurrentSite(this.state$.value))),
      distinctUntilChanged(),
    );
  }

  findData<T>(mapFn: (state: State) => T): Observable<T> {
    return this.state$.pipe(
      map((state) => mapFn(state)),
      distinctUntilChanged(),
    );
  }

  isLastQuestion(): boolean {
    return this.questionOrderHandlerService.isLast(this.state$.value);
  }

  isFirstQuestion(): boolean {
    return this.questionOrderHandlerService.isFirst(this.state$.value);
  }

  getLocalities(zipCode: number): Observable<Localities> {
    return this.localityApi.get(zipCode);
  }

  hasInjectionFields(): boolean {
    const currentSite = this.getCurrentSite();
    return InjectionService.hasInjectionFields(currentSite);
  }

  isElecOrDual(): [isElec: boolean, isDual: boolean] {
    const { hasSolarPanels = false, smartMeter = false, energy, zipCode = 0 } = this.getCurrentSite();

    const isElec: boolean =
      energy != null &&
      energy.includes(Energy.ELEC) &&
      hasSolarPanels &&
      ((ZipCodeService.isFlanders(zipCode) && smartMeter) || ZipCodeService.isBrussels(zipCode));
    const isDual = isElec && energy.includes(Energy.ELEC_GAS);
    return [isElec, isDual];
  }

  getDefaultInjectionValues(): number[] {
    const currentSite = this.getCurrentSite();
    return InjectionService.getDefaultInjectionValues(currentSite);
  }

  onErrorPageReached(): void {
    const trackingData = TrackingMapperService.toErrorDisplayTrackingData(
      this.state$.value,
      this.translateService.currentLang as 'fr' | 'nl',
      this.state$.value.type === Type.B2B,
    );
    this.trackingApi.sendDisplayEvent(trackingData);
  }

  onPhoneClicked(): void {
    const trackingData = TrackingMapperService.toContactInteractionTrackingData('Phone');
    this.trackingApi.sendSimulatorInteractionEvent(trackingData);
  }

  onEmailClicked(): void {
    const trackingData = TrackingMapperService.toContactInteractionTrackingData('Email');
    this.trackingApi.sendSimulatorInteractionEvent(trackingData);
  }

  isProsumerInWalloniaOrFlanders(): Observable<boolean> {
    return this.sites$.pipe(
      map((sites) =>
        sites.some(
          (site) => site.hasSolarPanels && site.solarPanelKva <= 10 && !ZipCodeService.isBrussels(site.zipCode),
        ),
      ),
    );
  }

  isProsumerInBrussels(): Observable<boolean> {
    return this.sites$.pipe(
      map((sites) =>
        sites.some((site) => site.hasSolarPanels && site.solarPanelKva <= 5 && ZipCodeService.isBrussels(site.zipCode)),
      ),
    );
  }

  isBrussels(): Observable<boolean> {
    return this.sites$.pipe(map((sites) => sites.some((site) => ZipCodeService.isBrussels(site.zipCode))));
  }

  runSimulation(): Observable<State> {
    if (this.state$.value.simulationId || this.isInitialData()) {
      return of(void 0);
    }

    const { sites, type } = this.state$.value;
    const { promoCode } = QueryParamsMapperService.getQueryParamsObject();
    const payload = sites.map((site) => SimulatorEngineMapperService.toSimulatorEngineRequest(site, type, promoCode));

    return this.simulatorEngineApi.post(payload).pipe(
      map((simulationResponse) =>
        this.setState((state) => ({
          ...state,
          simulationId: simulationResponse.simulationId,
          sites: simulationResponse.simulations.map((sim) =>
            SimulatorEngineMapperService.toSiteState(sim.simulationRequest, sim.bundles),
          ),
        })),
      ),
      mapTo(this.state$.value),
      tap((state: State) => {
        this.cookieService.delete(`totalenergies_simulator_${state.type.toLowerCase()}`, '/');
        const today = new Date();
        const endOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0, 23, 59, 59);
        const firstSite = this.state$.value.sites[0];
        const simulatorCookies = {
          simulationId: this.state$.value.simulationId,
          hasSolarPanels: firstSite.hasSolarPanels,
          electricVehicle: firstSite.hasElectricVehicle ? 1 : 0,
          assistancePrice: '',
        };
        this.cookieService.set(
          `totalenergies_simulator_${state.type.toLowerCase()}`,
          JSON.stringify(simulatorCookies),
          endOfMonth,
          '/',
        );
      }),
      catchError((err: HttpErrorResponse) => this.handleError(err)),
    );
  }

  getBundlesDetails(): Observable<BundleDetails[]> {
    return this.getBundles().pipe(
      map((codes) => this.getBundleCodes(codes)),
      switchMap((codes) => (codes.length > 0 ? this.productCatalogApi.getBundlesDetails(codes) : of([]))),
    );
  }

  getBundles(): Observable<Bundles[]> {
    return this.findData((state) => state.sites.map((site) => site.simulation)).pipe(
      filter(() => !this.isInitialData()),
      map((codes: Bundles[][]) => codes?.flat() || []),
      map((bundles) => bundles.filter((bundle) => !!bundle)),
      filter((bundles) => !!bundles && bundles.length > 0),
    );
  }

  getPromotionDetails(promoCode: string): Observable<PromotionDetails> {
    return this.productCatalogApi.getPromoDetails(promoCode);
  }

  sendSimulatorInteractionTrackingData(trackingEventValue: string): void {
    const trackingData = TrackingMapperService.toSimulatorInteractionTrackingData(trackingEventValue);
    this.trackingApi.sendSimulatorInteractionEvent(trackingData);
  }

  sendSimulatorInteractionProductTrackingData(bundleCode: string, price: number): void {
    const trackingData = TrackingMapperService.toSimulatorInteractionProductTrackingData(bundleCode, price);
    this.trackingApi.sendSimulatorInteractionEvent(trackingData);
  }

  getCurrentSite(): Site {
    return findCurrentSite(this.state$.value);
  }

  sortBundleDetailsByWeighting(bundles: BundleDetails[], descending = true): BundleDetails[] {
    return bundles.sort((firstBundle: BundleDetails, secondBundle: BundleDetails) =>
      (!descending && firstBundle.weighting > secondBundle.weighting) ||
      (descending && firstBundle.weighting < secondBundle.weighting)
        ? 1
        : -1,
    );
  }

  private splitLastOccurrence(str: string, substring: string): string[] {
    const lastIndex = str.lastIndexOf(substring);
    const before = str.slice(0, lastIndex);
    const after = str.slice(lastIndex + 1);
    return [before, after];
  }

  private isInitialData(): boolean {
    return JSON.stringify({ ...this.state$.value, promo: undefined }) === JSON.stringify(INITIAL_STATE);
  }

  /**
   * should be extracted into a service and tested
   * @param bundles
   * @private
   */
  private getBundleCodes(bundles?: Bundles[]): string[] {
    if (!bundles || bundles.length === 0 || bundles[0] === undefined) {
      return [];
    }

    const codeSet = new Set<string>();
    bundles.forEach((bundles: Bundles) => codeSet.add(bundles.code));
    if (codeSet.size === 1) {
      return Array.from(codeSet);
    }

    const splitedCodeArray = Array.from(codeSet).map((code: string) => this.splitLastOccurrence(code, '_'));

    const codeArray = splitedCodeArray.map(([product, energy]: string[]) => {
      if (energy.toUpperCase() === 'DUAL') {
        return `${product}_${energy}`;
      }
      if (splitedCodeArray.find((code: string[]) => code[0] === product && code[1].toUpperCase() === 'DUAL')) {
        return `${product}_DUAL`;
      }
      if (
        splitedCodeArray.find((code: string[]) => code[0] === product && code[1].toUpperCase() !== energy.toUpperCase())
      ) {
        return `${product}_DUAL`;
      }
      return `${product}_${energy}`;
    });

    return Array.from(new Set(codeArray)); // return & remove duplicates
  }

  private handleError(error: HttpErrorResponse): Observable<never> {
    console.error('Error ! ', error);
    this.router.navigate(['/error']);
    return throwError(error);
  }

  private handleTrackingServiceEvent(): void {
    this.currentStep$
      .pipe(
        switchMap((step) => {
          return step === '/result' ? this.simulations$.pipe(first(), mapTo(true)) : of(true);
        }),
      )
      .subscribe(() => this.sendDisplayTrackingData());

    this.translateService.onLangChange
      .pipe(
        skip(1),
        map((langEvent) => langEvent.lang),
      )
      .subscribe((lang) => {
        const simulatorInteractionTrackingData = TrackingMapperService.toNavigationInteractionTrackingData(lang);
        this.trackingApi.sendSimulatorInteractionEvent(simulatorInteractionTrackingData);
      });
  }

  private sendDisplayTrackingData(): void {
    const trackingStepName = this.questionOrderHandlerService.getCurrentStepDisplayTrackingValue(this.state$.value);
    const trackingData =
      this.state$.value.type === Type.B2C
        ? TrackingMapperService.toDisplayTrackingDataB2c(
            this.state$.value,
            this.translateService.currentLang as 'fr' | 'nl',
            trackingStepName,
          )
        : TrackingMapperService.toDisplayTrackingDataB2b(
            this.state$.value,
            this.translateService.currentLang as 'fr' | 'nl',
            trackingStepName,
          );
    this.trackingApi.sendDisplayEvent(trackingData);
  }

  private setState(updateFn: (currentState: State) => State): void {
    const newState = updateFn(this.state$.value);
    this.state$.next(newState);
  }

  private goToOlregB2B(bundleCode: string): void {
    const sites = this.state$.value.sites;
    let urlSearchParams = OlregB2bMapperService.toOlregQueryParams(
      bundleCode,
      sites,
      this.state$.value.promo,
      this.state$.value.simulationId,
    );
    urlSearchParams = DeprecatedOlregMapperService.toLegacyOlRegQueryParams(urlSearchParams); // to be removed once PC integrated
    this.olregApi.redirectB2B(this.translateService.currentLang, urlSearchParams);
  }

  private gotToOlregB2C(bundleCode: string): void {
    const sites = this.state$.value.sites;
    this.activatedRoute.queryParams.pipe(first()).subscribe((params) => {
      const urlSearchParams = OlregB2CMapperService.toOlregQueryParams(
        bundleCode,
        sites[0],
        params['promoCode'],
        params['sponsorReference'],
        params['isGeep'],
        params['agentUsername'],
        this.state$.value.simulationId,
      );
      this.olregApi.redirectB2C(this.translateService.currentLang, urlSearchParams);
    });
  }

  private addQueryParamsSimulationId(simulationId: string): void {
    this.router.navigate(['/result'], {
      queryParams: { simulationId: simulationId },
      queryParamsHandling: 'merge',
    });
  }

  private handleRefreshResultPage(): void {
    this.state$
      .pipe(
        filter((state) => !!state.simulationId && !window.location.href.includes('simulationId')),
        first(),
      )
      .subscribe((state) => this.addQueryParamsSimulationId(state.simulationId));

    this.activatedRoute.queryParams
      .pipe(
        filter((params) => !!params),
        filter((params) => !!params['simulationId']),
        first(),
        switchMap((params) => this.simulatorEngineApi.get(params['simulationId'])),
        catchError((err: HttpErrorResponse) => this.handleError(err)),
      )
      .subscribe((simulationResponse: SimulationEngineResponse) =>
        this.setState((state) => ({
          ...state,
          currentStepUrl: this.questionOrderHandlerService.getLastStep(state),
          numberOfSites: NumberOfSite.LESSER_OR_EQUAL_THAN_8,
          simulationId: simulationResponse.simulationId,
          sites: simulationResponse.simulations.map((sim) =>
            SimulatorEngineMapperService.toSiteState(sim.simulationRequest, sim.bundles),
          ),
        })),
      );
  }

  private handlePromotion(): void {
    this.activatedRoute.queryParams
      .pipe(
        filter((params) => !!params),
        filter((params) => !!params['promoCode']),
        map((params) => params['promoCode']),
        first(),
      )
      .subscribe((promoCode) => {
        this.setState((state) => ({
          ...state,
          promo: promoCode,
        }));
      });
  }
}
