import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { InputTextConfig } from '../../shared/custom-form-control/zip-code-input/input-text.component';
import { QuestionFacade } from '../../core/facade/question.facade';
import { max, min, toNumber } from '../../core/utils';
import { takeUntil } from 'rxjs/operators';
import { CompleteObservables } from 'src/app/shared/complete-observables';

@Component({
  selector: 'app-peak-info',
  templateUrl: './average-peak.component.html',
  styleUrls: ['./average-peak.component.scss'],
})
export class AveragePeakComponent extends CompleteObservables implements OnInit {
  private readonly MIN_VALUE = 2.5;
  private readonly MAX_VALUE = 30;
  STEP_CONFIG: InputTextConfig = {
    labelKey: 'pages.averagePeak.formElements.power.label',
    placeHolder: 'pages.averagePeak.formElements.power.unit',
    errors: new Map([
      ['required', 'pages.averagePeak.formElements.power.error'],
      ['min', 'pages.averagePeak.formElements.power.min'],
      ['pattern', 'pages.averagePeak.formElements.power.min'],
      ['max', 'pages.averagePeak.formElements.power.max'],
    ]),
    inputSuffix: 'pages.averagePeak.formElements.power.unit',
  };
  formControl: UntypedFormControl;
  numericNumberReg = '^[0-9]\\d*((\\.|\\,)+\\d{1,2})?$';

  constructor(private readonly questionFacade: QuestionFacade) {
    super();
  }

  ngOnInit(): void {
    this.questionFacade
      .findSiteData((site) => site.averagePeak)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (value) =>
          (this.formControl = new UntypedFormControl(value, [
            Validators.required,
            min(this.MIN_VALUE),
            max(this.MAX_VALUE),
            Validators.pattern(this.numericNumberReg),
          ])),
      );
  }

  defaultAveragePeak() {
    this.formControl.setValue(4.0);
  }

  onPreviousClicked() {
    this.questionFacade.previous();
  }

  onNextClicked() {
    this.questionFacade.updateSiteData({ averagePeak: toNumber(String(this.formControl.value)) });
    this.questionFacade.next();
  }
}
