import { findCurrentSite, maxConsumptionReached, NumberOfSite, State } from '../../../state/state';
import {
  AveragePeak,
  CertificationPV,
  ConsumptionHelp,
  Consumptions,
  Energy,
  HasSolarPanels,
  KnowConsumption,
  MeterType,
  Question,
  Result,
  SmartMeter,
  SolarPanelKva,
  ZipCode,
} from '../questions-common';
import { ZipCodeService } from '../../zip-code.service';

export class SiteNumberB2B extends Question {
  displayCondition(state: State): boolean {
    return state.currentSiteIndex === 0;
  }

  getUrl(): string {
    return '/site-number';
  }

  getDisplayTackingDataValue(): string {
    return 'site-number';
  }

  getFrTranslatedUrl(): string {
    return 'nombre-site';
  }

  getNlTranslatedUrl(): string {
    return 'aantal-locatie';
  }
}

export class ZipCodeB2B extends ZipCode {
  displayCondition(state: State): boolean {
    return state.numberOfSites === NumberOfSite.LESSER_OR_EQUAL_THAN_8 && state.sites.length < 9;
  }
}

export class EnergyB2B extends Energy {
  displayCondition(state: State): boolean {
    return state.numberOfSites === NumberOfSite.LESSER_OR_EQUAL_THAN_8 && state.sites.length < 9;
  }
}

export class KnowConsumptionB2B extends KnowConsumption {
  displayCondition(state: State): boolean {
    return state.numberOfSites === NumberOfSite.LESSER_OR_EQUAL_THAN_8 && state.sites.length < 9;
  }
}

export class HasSolarPanelsB2B extends HasSolarPanels {
  displayCondition(state: State): boolean {
    return (
      state.numberOfSites === NumberOfSite.LESSER_OR_EQUAL_THAN_8 &&
      (findCurrentSite(state).energy || '' || '').includes('ELEC') &&
      state.sites.length < 9
    );
  }
}

export class CertificationPVB2B extends CertificationPV {
  displayCondition(state: State): boolean {
    return (
      (findCurrentSite(state).energy || '').includes('ELEC') &&
      ZipCodeService.isWallonia(findCurrentSite(state).zipCode || undefined) &&
      findCurrentSite(state).hasSolarPanels
    );
  }
}

export class SolarPanelKvaB2B extends SolarPanelKva {
  displayCondition(state: State): boolean {
    return (
      state.numberOfSites === NumberOfSite.LESSER_OR_EQUAL_THAN_8 &&
      (findCurrentSite(state).hasSolarPanels || '') &&
      (findCurrentSite(state).energy || '').includes('ELEC') &&
      state.sites.length < 9
    );
  }
}

export class SmartMeterB2B extends SmartMeter {
  displayCondition(state: State): boolean {
    return (
      state.numberOfSites === NumberOfSite.LESSER_OR_EQUAL_THAN_8 &&
      (findCurrentSite(state).energy || '').includes('ELEC') &&
      state.sites.length < 9 &&
      !(ZipCodeService.isBrussels(findCurrentSite(state).zipCode || undefined) && findCurrentSite(state).hasSolarPanels)
    );
  }
}

export class AveragePeakB2B extends AveragePeak {
  displayCondition(state: State): boolean {
    return (
      state.numberOfSites === NumberOfSite.LESSER_OR_EQUAL_THAN_8 &&
      ZipCodeService.isFlanders(findCurrentSite(state).zipCode || undefined) &&
      (findCurrentSite(state).smartMeter || '') &&
      (findCurrentSite(state).energy || '').includes('ELEC') &&
      state.sites.length < 9
    );
  }
}

export class MeterTypeB2B extends MeterType {
  displayCondition(state: State): boolean {
    return (
      state.numberOfSites === NumberOfSite.LESSER_OR_EQUAL_THAN_8 &&
      (findCurrentSite(state).energy || '').includes('ELEC') &&
      state.sites.length < 9
    );
  }
}

export class ConsumptionHelpB2B extends ConsumptionHelp {
  displayCondition(state: State): boolean {
    return (
      state.numberOfSites === NumberOfSite.LESSER_OR_EQUAL_THAN_8 &&
      !(findCurrentSite(state).knowConsumption || '') &&
      state.sites.length < 9
    );
  }
}

export class ConsumptionsB2B extends Consumptions {
  displayCondition(state: State): boolean {
    return state.numberOfSites === NumberOfSite.LESSER_OR_EQUAL_THAN_8 && state.sites.length < 9;
  }
}
export class Multisite extends Question {
  getFrTranslatedUrl(): string {
    return 'multisite';
  }

  getNlTranslatedUrl(): string {
    return 'multisite';
  }

  displayCondition(state: State): boolean {
    return (
      state.numberOfSites === NumberOfSite.LESSER_OR_EQUAL_THAN_8 &&
      !maxConsumptionReached(state) &&
      state.sites.length < 9
    );
  }

  getUrl(): string {
    return '/multisite';
  }

  getDisplayTackingDataValue(): string {
    return 'multi-site';
  }
}

export class MoreThan8Sites extends Question {
  displayCondition(state: State): boolean {
    return state.numberOfSites === NumberOfSite.MORE_THAN_8 || maxConsumptionReached(state) || state.sites.length > 8;
  }

  getUrl(): string {
    return '/more-than-8';
  }

  getDisplayTackingDataValue(): string {
    return 'contact-form';
  }

  getFrTranslatedUrl(): string {
    return 'plus-que-8';
  }

  getNlTranslatedUrl(): string {
    return 'meer-dan-8';
  }
}

export const ORDERED_QUESTION_B2B_CONFIG: Question[] = [
  new SiteNumberB2B(),
  new ZipCodeB2B(),
  new EnergyB2B(),
  new KnowConsumptionB2B(),
  new HasSolarPanelsB2B(),
  new CertificationPVB2B(),
  new SolarPanelKvaB2B(),
  new SmartMeterB2B(),
  new AveragePeakB2B(),
  new MeterTypeB2B(),
  new ConsumptionHelpB2B(),
  new ConsumptionsB2B(),
  new MoreThan8Sites(),
  new Multisite(),
  new Result(),
];
