import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { PromotionDetails, BundleDetails } from '../bundle/bundle';

@Injectable({
  providedIn: 'root',
})
export class ProductCatalogApi {
  private readonly BUNDLES_DETAILS_URL = `${environment.PRODUCT_CATALOG_URL}/public/api/bundles-sales-details`;
  private readonly PROMO_DETAILS_URL = (promoCode) =>
    `${environment.PRODUCT_CATALOG_URL}/public/api/promotions/${promoCode}/description`;

  constructor(private readonly http: HttpClient) {}

  getPromoDetails(promoCode: string): Observable<PromotionDetails> {
    return this.http
      .get<PromotionDetails>(this.PROMO_DETAILS_URL(promoCode))
      .pipe(catchError((err) => throwError(err)));
  }

  getBundlesDetails(codes: string[]): Observable<BundleDetails[]> {
    return this.http.post<BundleDetails[]>(this.BUNDLES_DETAILS_URL, codes).pipe(
      catchError((err) => throwError(err)),
      map((result: BundleDetails[]) => this.addColorToProducts(result)),
    );
  }

  /**
   * this parameter musts be provided by the product catalog - temporary solution
   * @param bundleDetails
   * @private
   */
  private addColorToProducts(bundleDetails: BundleDetails[]): BundleDetails[] {
    return bundleDetails.map((bundle) => {
      if (
        bundle.commercialCode === 'PIXEL' ||
        bundle.commercialCode === 'PIXEL-FIXED' ||
        bundle.commercialCode === 'PIXEL PRO' ||
        bundle.commercialCode === 'PIXEL-PRO'
      ) {
        return {
          ...bundle,
          color: '#FDF9FB',
          gradient: 'linear-gradient(270deg, #964B96 0%, #462882 100%)',
        };
      }
      if (
        bundle.commercialCode === 'PIXIE' ||
        bundle.commercialCode === 'PIXIE PRO' ||
        bundle.commercialCode === 'PIXIE-PRO'
      ) {
        return {
          ...bundle,
          color: '#fffdf3',
          gradient: 'linear-gradient(91.76deg, #DC6014 1.41%, #EF6801 48.2%, #D6B500 98.89%)',
        };
      }
      if (
        bundle.commercialCode === 'PIXEL NEXT' ||
        bundle.commercialCode === 'PIXEL-NEXT' ||
        bundle.commercialCode === 'PIXEL NEXT PRO' ||
        bundle.commercialCode === 'PIXEL-NEXT-PRO' ||
        bundle.commercialCode === 'PIXEL NEXT FIXED' ||
        bundle.commercialCode === 'PIXEL-NEXT-FIXED' ||
        bundle.commercialCode === 'PIXEL BLUE' ||
        bundle.commercialCode === 'PIXEL-BLUE' ||
        bundle.commercialCode === 'PIXEL BLUE PRO' ||
        bundle.commercialCode === 'PIXEL-BLUE-PRO' ||
        bundle.commercialCode === 'PIXEL BLUE FIXED' ||
        bundle.commercialCode === 'PIXEL-BLUE-FIXED'
      ) {
        return {
          ...bundle,
          color: '#f3fcff',
          gradient: 'linear-gradient(271.31deg, #0185FF 1.59%, #005596 99.33%)',
        };
      }
      if (
        bundle.commercialCode === 'PIXEL EDRIVE' ||
        bundle.commercialCode === 'PIXEL-EDRIVE' ||
        bundle.commercialCode === 'PIXEL EDRIVE PRO' ||
        bundle.commercialCode === 'PIXEL-EDRIVE-PRO' ||
        bundle.commercialCode === 'PIXEL EDRIVE FIXED' ||
        bundle.commercialCode === 'PIXEL-EDRIVE-FIXED' ||
        bundle.commercialCode === 'EMOBILITY' ||
        bundle.commercialCode === 'EMOBILITY-FIXED'
      ) {
        return {
          ...bundle,
          color: '#F9FEFA',
          gradient: 'linear-gradient(270deg, #32D458 0%, #00955B 100%)',
        };
      }
      return bundle;
    });
  }
}
