import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SiteTitleComponent } from './site-details/site-title/site-title.component';
import { SiteInfoComponent } from './site-details/site-info/site-info.component';
import { RenameSiteComponent } from './site-details/rename-site/rename-site.component';
import { SiteDetailsModalComponent } from './site-details/site-details-modal/site-details-modal.component';
import { SiteRenameWrapperComponent } from '../../features/multisite/site-rename-wrapper/site-rename-wrapper.component';
import { TranslateModule } from '@ngx-translate/core';
import { CustomFormControlModule } from '../custom-form-control/custom-form-control.module';



@NgModule({
  declarations: [
    SiteTitleComponent,
    SiteInfoComponent,
    RenameSiteComponent,
    SiteDetailsModalComponent,
    SiteRenameWrapperComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    CustomFormControlModule
  ],
  exports: [
    SiteTitleComponent,
    SiteInfoComponent,
    RenameSiteComponent,
    SiteDetailsModalComponent,
    SiteRenameWrapperComponent,
  ]
})
export class SiteModule { }
