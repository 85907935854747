import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { CHAT_ENV } from '@totalenergiescode/springbox-salesforce-chat';
import * as Sentry from '@sentry/angular-ivy';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

Sentry.init({
  dsn: environment.sentry,
  environment: environment.envName,

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic([[{ provide: CHAT_ENV, useValue: environment.envName }]])
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
