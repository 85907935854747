import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { elecMaxConsumptionsValidator, gasMaxConsumptionsValidator } from '../multisite/validators';
import { InputTextConfig } from '../../shared/custom-form-control/zip-code-input/input-text.component';
import { QuestionFacade } from '../../core/facade/question.facade';
import { Energy, MeterType, Type } from 'src/app/core/state/state';
import { Observable } from 'rxjs';
import { TypeDetectorService } from '../../core/service/type-detector.service';
import { min, toNumber } from '../../core/utils';
import { CompleteObservables } from 'src/app/shared/complete-observables';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-consumptions',
  templateUrl: './consumptions.component.html',
  styleUrls: ['./consumptions.component.scss'],
})
export class ConsumptionsComponent extends CompleteObservables implements OnInit {
  readonly ELEC_CONSUMPTION_MONO_CONFIG: InputTextConfig = {
    labelKey: 'pages.consumptions.electricity.formElements.mono.label',
    placeHolder: 'pages.consumptions.electricity.formElements.mono.placeholder',
    errors: new Map([
      ['required', 'pages.consumptions.electricity.formElements.mono.error'],
      ['min', 'pages.consumptions.electricity.formElements.mono.min'],
      ['pattern', 'pages.consumptions.electricity.formElements.mono.min'],
    ]),
    inputSuffix: 'pages.consumptions.electricity.formElements.mono.unit',
  };

  readonly ELEC_CONSUMPTION_HIGH_CONFIG: InputTextConfig = {
    labelKey: 'pages.consumptions.electricity.formElements.day.label',
    placeHolder: 'pages.consumptions.electricity.formElements.day.placeholder',
    errors: new Map([
      ['required', 'pages.consumptions.electricity.formElements.day.error'],
      ['min', 'pages.consumptions.electricity.formElements.day.min'],
      ['pattern', 'pages.consumptions.electricity.formElements.day.min'],
    ]),
    inputSuffix: 'pages.consumptions.electricity.formElements.day.unit',
  };

  readonly ELEC_CONSUMPTION_LOW_CONFIG: InputTextConfig = {
    labelKey: 'pages.consumptions.electricity.formElements.night.label',
    placeHolder: 'pages.consumptions.electricity.formElements.night.placeholder',
    errors: new Map([
      ['required', 'pages.consumptions.electricity.formElements.night.error'],
      ['min', 'pages.consumptions.electricity.formElements.night.min'],
      ['pattern', 'pages.consumptions.electricity.formElements.night.min'],
    ]),
    inputSuffix: 'pages.consumptions.electricity.formElements.night.unit',
  };

  readonly INJECTION_CONSUMPTION_MONO_CONFIG: InputTextConfig = {
    labelKey: 'pages.consumptions.electricity.formElements.injectionMono.label',
    placeHolder: 'pages.consumptions.electricity.formElements.mono.placeholder',
    errors: new Map([['min', 'pages.consumptions.electricity.formElements.injectionMono.min']]),
    inputSuffix: 'pages.consumptions.electricity.formElements.injectionMono.unit',
  };

  readonly INJECTION_CONSUMPTION_HIGH_CONFIG: InputTextConfig = {
    labelKey: 'pages.consumptions.electricity.formElements.injectionDay.label',
    placeHolder: 'pages.consumptions.electricity.formElements.night.placeholder',
    errors: new Map([['min', 'pages.consumptions.electricity.formElements.injectionDay.min']]),
    inputSuffix: 'pages.consumptions.electricity.formElements.injectionDay.unit',
  };

  readonly INJECTION_CONSUMPTION_LOW_CONFIG: InputTextConfig = {
    labelKey: 'pages.consumptions.electricity.formElements.injectionNight.label',
    placeHolder: 'pages.consumptions.electricity.formElements.night.placeholder',
    errors: new Map([['min', 'pages.consumptions.electricity.formElements.injectionNight.min']]),
    inputSuffix: 'pages.consumptions.electricity.formElements.injectionNight.unit',
  };

  readonly GAS_CONSUMPTION_CONFIG: InputTextConfig = {
    labelKey: 'pages.consumptions.gas.formElements.gas.label',
    placeHolder: 'pages.consumptions.electricity.formElements.night.placeholder',
    errors: new Map([
      ['required', 'pages.consumptions.gas.formElements.gas.error'],
      ['min', 'pages.consumptions.gas.formElements.gas.min'],
      ['pattern', 'pages.consumptions.electricity.formElements.excluNight.min'],
    ]),
    inputSuffix: 'pages.consumptions.gas.formElements.gas.unit',
  };

  readonly ELEC_NIGHT_CONSUMPTION_CONFIG: InputTextConfig = {
    labelKey: 'pages.consumptions.electricity.formElements.excluNight.label',
    placeHolder: 'pages.consumptions.electricity.formElements.night.placeholder',
    errors: new Map([
      ['required', 'pages.consumptions.electricity.formElements.excluNight.error'],
      ['min', 'pages.consumptions.electricity.formElements.excluNight.min'],
      ['pattern', 'pages.consumptions.electricity.formElements.excluNight.min'],
    ]),
    inputSuffix: 'pages.consumptions.electricity.formElements.excluNight.unit',
  };

  readonly Energy = Energy;
  readonly MeterType = MeterType;
  energy: Energy = null;
  meter: MeterType = null;
  showInjectionFields = false;
  defaultConsumptionValidators: ValidatorFn[] = [Validators.required, min(1)];
  formGroup: UntypedFormGroup;
  exclusiveNight$: Observable<boolean>;
  knowConsumption$: Observable<boolean>;
  isB2C = TypeDetectorService.detectType() === Type.B2C;

  constructor(
    private readonly fb: UntypedFormBuilder,
    private readonly questionFacade: QuestionFacade,
  ) {
    super();
  }

  ngOnInit(): void {
    this.exclusiveNight$ = this.questionFacade.findSiteData((site) => site.exclusiveNight);
    this.knowConsumption$ = this.questionFacade.findSiteData((site) => site.knowConsumption);
    this.questionFacade
      .findSiteData((site) => site)
      .pipe(takeUntil(this.destroy$))
      .subscribe((currentSite) => {
        const maxConsumptionValidator: ValidatorFn[] = [];
        const group = {};
        this.energy = currentSite.energy;
        this.meter = currentSite.meterType;
        this.showInjectionFields = this.questionFacade.hasInjectionFields();
        const [defaultInjectionHigh, defaultInjectionLow] = this.questionFacade.getDefaultInjectionValues() || ['', ''];

        if (this.hasEnergy(Energy.ELEC)) {
          group['consumptionHigh'] = new UntypedFormControl(
            currentSite.consumptions.consumptionHigh,
            this.hasMeter(MeterType.DUAL)
              ? [...this.defaultConsumptionValidators, Validators.pattern('^[0-9]\\d*((\\.|\\,)+\\d{1,2})?$')]
              : [],
          );
          group['consumptionLow'] = new UntypedFormControl(
            currentSite.consumptions.consumptionLow,
            this.hasMeter(MeterType.DUAL)
              ? [...this.defaultConsumptionValidators, Validators.pattern('^[0-9]\\d*((\\.|\\,)+\\d{1,2})?$')]
              : [],
          );
          group['consumptionMono'] = new UntypedFormControl(
            currentSite.consumptions.consumptionMono,
            this.hasMeter(MeterType.MONO)
              ? [...this.defaultConsumptionValidators, Validators.pattern('^[0-9]\\d*((\\.|\\,)+\\d{1,2})?$')]
              : [],
          );
          group['injectionHigh'] = new UntypedFormControl(
            this.hasMeter(MeterType.DUAL) ? currentSite.consumptions.injectionHigh || defaultInjectionHigh : null,
            this.showInjectionFields && this.hasMeter(MeterType.DUAL) ? this.defaultConsumptionValidators : [],
          );
          group['injectionLow'] = new UntypedFormControl(
            this.hasMeter(MeterType.DUAL) ? currentSite.consumptions.injectionLow || defaultInjectionLow : null,
            this.hasMeter(MeterType.DUAL) && this.showInjectionFields ? this.defaultConsumptionValidators : [],
          );
          group['injectionMono'] = new UntypedFormControl(
            this.hasMeter(MeterType.MONO) ? currentSite.consumptions.injectionMono || defaultInjectionHigh : null,
            this.hasMeter(MeterType.MONO) && this.showInjectionFields ? this.defaultConsumptionValidators : [],
          );
          group['consumptionExclusiveNight'] = new UntypedFormControl(
            currentSite.consumptions.consumptionExclusiveNight,
            currentSite.exclusiveNight
              ? [...this.defaultConsumptionValidators, Validators.pattern('^[0-9]\\d*((\\.|\\,)+\\d{1,2})?$')]
              : [],
          );
          maxConsumptionValidator.push(elecMaxConsumptionsValidator);
        }

        if (this.hasEnergy(Energy.GAS)) {
          group['gasConsumption'] = new UntypedFormControl(currentSite.consumptions.gasConsumption, [
            ...this.defaultConsumptionValidators,
            Validators.pattern('^[0-9]\\d*((\\.|\\,)+\\d{1,2})?$'),
          ]);
          maxConsumptionValidator.push(gasMaxConsumptionsValidator);
        }
        this.formGroup = new UntypedFormGroup(group);
        if (this.isB2C) {
          this.formGroup.setValidators(maxConsumptionValidator);
        }
      });
  }

  raiseErrors(): void {
    this.formGroup.markAllAsTouched();
  }

  hasEnergy(energy: Energy): boolean {
    return this.energy.includes(energy);
  }

  hasMeter(meter: MeterType): boolean {
    return (this.meter || MeterType.MONO).includes(meter);
  }

  get elecConsumptionHigh(): UntypedFormControl {
    return this.formGroup.get('consumptionHigh') as UntypedFormControl;
  }

  get elecConsumptionMono(): UntypedFormControl {
    return this.formGroup.get('consumptionMono') as UntypedFormControl;
  }

  get elecConsumptionLow(): UntypedFormControl {
    return this.formGroup.get('consumptionLow') as UntypedFormControl;
  }

  get elecInjectionHigh(): UntypedFormControl {
    return this.formGroup.get('injectionHigh') as UntypedFormControl;
  }

  get elecInjectionMono(): UntypedFormControl {
    return this.formGroup.get('injectionMono') as UntypedFormControl;
  }

  get elecInjectionLow(): UntypedFormControl {
    return this.formGroup.get('injectionLow') as UntypedFormControl;
  }

  get elecConsumptionExclusiveNight(): UntypedFormControl {
    return this.formGroup.get('consumptionExclusiveNight') as UntypedFormControl;
  }

  get gasConsumption(): UntypedFormControl {
    return this.formGroup.get('gasConsumption') as UntypedFormControl;
  }

  onPreviousClicked() {
    this.questionFacade.previous();
  }
  isDual(): boolean {
    return this.showInjectionFields && this.meter === MeterType.DUAL;
  }
  isGasOnly(): boolean {
    return this.hasEnergy(Energy.GAS) && !this.hasEnergy(Energy.ELEC);
  }

  isElec(): boolean {
    return this.showInjectionFields;
  }

  get getSubtitleText(): string {
    const [isElec, isDual] = this.questionFacade.isElecOrDual();
    if (isDual) return 'pages.consumptions.dual';
    if (isElec) return 'pages.consumptions.elec';
    if (this.isGasOnly()) return 'pages.consumptions.gas.subtitle';
    return 'pages.consumptions.subtitle';
  }

  onNextClicked() {
    this.questionFacade.updateSiteData({
      consumptions: {
        consumptionHigh: toNumber(this.formGroup.value.consumptionHigh),
        consumptionLow: toNumber(this.formGroup.value.consumptionLow),
        consumptionMono: toNumber(this.formGroup.value.consumptionMono),
        consumptionExclusiveNight: toNumber(this.formGroup.value.consumptionExclusiveNight),
        gasConsumption: toNumber(this.formGroup.value.gasConsumption),
        injectionHigh: toNumber(this.formGroup.value.injectionHigh),
        injectionLow: toNumber(this.formGroup.value.injectionLow),
        injectionMono: toNumber(this.formGroup.value.injectionMono),
      },
    });
    this.questionFacade.next();
  }
}
