<ng-container *ngIf="bundlesDetails$ | async as bundlesDetails">
  <div
    *ngIf="bundlesDetails.length > 0; else errorTemplate"
    class="flex flex-col mt-[10rem] md:mt-0 [&>*:nth-child(2)]:-mt-[10rem] {{
      bundlesDetails.length === 1 ? 'md:flex-row md:max-w-6xl md:mx-auto' : 'md:grid md:grid-cols-6'
    }} md:gap-16"
  >
    <div
      class="order-2 mt-0 description col-span-6 md:mt-0"
      [ngClass]="{
        'md:col-start-2 md:order-none': bundlesDetails.length === 1,
        'md:col-span-2 md:order-none': bundlesDetails.length < 3,
        'md:flex md:col-span-6': bundlesDetails.length >= 3
      }"
    >
      <h3
        class="mb-4 md:mt-0 mt-12 font-display font-normal text-4xl te-text-gradient-home inline-bltitle-containerock w-full"
        [class.md:hidden]="bundlesDetails.length >= 3"
      >
        {{ (isB2c ? 'pages.result.features.b2c.title' : 'pages.result.features.title') | translate }}
      </h3>
      <ul
        class="features"
        [class.extend]="bundlesDetails.length >= 3"
        [innerHTML]="(isB2c ? 'pages.result.features.b2c.content' : 'pages.result.features.content') | translate"
      ></ul>
    </div>
    <ng-container *ngFor="let bundleDetails of bundlesDetails">
      <div
        class="bundle relative box-gradient mt-16"
        [ngStyle]="{
          'background-image':
            'linear-gradient(' + bundleDetails.color + ',' + bundleDetails.color + '), ' + bundleDetails.gradient
        }"
      >
        <app-bundle
          [bundleDetails]="bundleDetails"
          (becomeCustomerClicked)="gotToOlReg($event, bundleDetails)"
        ></app-bundle>
      </div>
    </ng-container>
  </div>
</ng-container>

<ng-template #errorTemplate>
  <app-error-background errorTitle="pages.no-product.title">
    <div class="flex flex-direction-up-down items-center justify-center mt-4">
      <button class="btn block discover-offer" (click)="onDiscoverOfferClick()">
        <span>{{ 'pages.result.products.discover-offer' | translate }}</span>
      </button>
      <button class="block btn btn-primary w-full md:w-auto mt-4" (click)="onRetrySimulationCLik()" type="button">
        <span>{{ 'pages.result.products.retry-simulator' | translate }}</span>
      </button>
    </div>
  </app-error-background>
</ng-template>
