<div class="flex items-center justify-center w-full margin-top-header">
    <app-error-background>
        <p>
            {{ 'pages.404.utilsLink' | translate }}
        </p>
        <div class="md:flex mt-8 text-left md:justify-center" [style.margin-left]="'65px'">
            <a class="w-full md:w-auto btn btn-text text-primary mx-6"
               href="{{ 'pages.404.simu.simulatorLink_'+type | translate }}">
                {{ 'pages.404.simu.simulatorLabel' | translate }}
            </a>
            <a class="w-full md:w-auto btn btn-text text-primary mx-6"
               href="{{ 'pages.404.mylampiris.mylampirisLink_'+type | translate }}">
                {{ 'pages.404.mylampiris.mylampirisLabel' | translate }}
            </a>
            <a class="w-full md:w-auto btn btn-text text-primary mx-6"
               href="{{ 'pages.404.invoice.invoinceLink_'+type | translate }}">
                {{ 'pages.404.invoice.invoiceLabel' | translate }}
            </a>
        </div>
    </app-error-background>
</div>
