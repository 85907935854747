<div class="px-8 xl:max-w-screen-xl xl:w-full xl:m-auto"
>
  <app-title class="block w-full max-w-5xl mx-auto text-center"
             title="pages.profil-not-found.title"
             subtitle="pages.profil-not-found.subtitle"></app-title>
  <app-card class="block w-full my-12 mx-auto md:max-w-3xl md:flex-1 md:mt-8" [card]="profilNotFoundCard"></app-card>
  <app-navigation [stepValidity]="true"
                  (nextClicked)="onNextClicked()"
                  (previousClicked)="onPreviousClicked()">
  </app-navigation></div>
