<div class="tooltip">
  <div class="tooltip__symbol">
    <span class="tooltip__icon" *ngIf="!label">?</span>
    <span class="tooltip__label" *ngIf="label">{{ label | translate }}</span>
  </div>
  <div
    [class]="'tooltip__wrap tooltip__wrap--' + position + ' tooltip__wrap--' + verticalPosition"
    [ngClass]="{ 'tooltip__wrap--withLabel': label, 'tooltip__wrap--biggerWidth': showLongerText }"
    [innerHTML]="text | translate: params"
  ></div>
</div>
