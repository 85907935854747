import { NumberOfSite, Site, State } from '../../state/state';
import { DisplayTrackingData, SimulatorInteractionTrackingData } from '../../api/tracking.api';
import { snakeToCamel } from '../../utils';
import { Bundles } from '../../bundle/bundle';

export class TrackingMapperService {
  static toNavigationInteractionTrackingData(lang: string): SimulatorInteractionTrackingData {
    return {
      event: 'Navigation-interaction',
      eventCategory: 'Navigation',
      eventAction: 'Click language',
      component: {
        name: 'Navigation',
      },
      eventLabel: lang,
    };
  }

  static toContactInteractionTrackingData(contactType: 'Phone' | 'Email'): SimulatorInteractionTrackingData {
    return {
      event: 'Contact-interaction',
      eventCategory: 'Contact',
      eventAction: 'Click',
      component: {
        name: 'Contact',
      },
      eventLabel: contactType,
    };
  }

  static toSimulatorInteractionTrackingData(trackingEventValue: string): SimulatorInteractionTrackingData {
    return {
      event: 'Simulator-interaction',
      eventCategory: 'Simulator',
      eventAction: 'Click',
      component: {
        name: 'Simulator',
      },
      eventLabel: trackingEventValue,
    };
  }

  static toSimulatorInteractionProductTrackingData(
    bundleCode: string,
    price: number,
  ): SimulatorInteractionTrackingData {
    return {
      event: 'Simulator-interaction',
      eventCategory: 'Simulator',
      eventAction: 'Click',
      component: {
        name: 'Simulator',
      },
      clickedProduct: bundleCode,
      clickedProductPrice: price + '',
      eventLabel: 'Choose-' + bundleCode,
    };
  }

  static toDisplayTrackingDataB2c(state: State, currentLang: 'fr' | 'nl', stepName: string): DisplayTrackingData {
    const unknowConsumptionData = state.sites[0].unknownConsumption
      ? {
          homeType: state.sites[0].unknownConsumption?.building?.buildingType,
          homeArea: state.sites[0].unknownConsumption?.building?.area,
          constructionDate: state.sites[0].unknownConsumption?.building?.constructionDate,
          numberOfLiving: state.sites[0].unknownConsumption?.building?.occupiedBy,
          heatingType: state.sites[0].unknownConsumption?.heatingEnergy?.type,
          heatingInstallationYear: state.sites[0].unknownConsumption?.heatingEnergy?.installationDate,
        }
      : {};
    const allOffers = this.getAllOffers(state.sites);

    const data: DisplayTrackingData = {
      event: 'simulatorStep',
      page: {
        title: 'TotalEnergies - Online simulation',
        publicTarget: 'b-to-c',
        language: currentLang,
        branch: 'gasElec',
        category: 'simulator',
      },
      simulator: {
        ...unknowConsumptionData,
        simulationStep: stepName,
        flowType: this.mapFlowTypeB2c(state),
        zipCode: this.convertEmptyStringToUndefined(state.sites.map((site) => site.zipCode).join(' | ')),
        energyType: this.convertEmptyStringToUndefined(state.sites.map((site) => site.energy).join(' | ')),
        consumptionKnow: this.convertEmptyStringToUndefined(
          state.sites
            .map((site) => (site.knowConsumption === undefined ? '' : site.knowConsumption ? 'yes' : 'no'))
            .join(' | '),
        ),
        hasSolarPanel: this.convertEmptyStringToUndefined(state.sites.map((site) => site.hasSolarPanels).join(' | ')),
        solarPanelsInstallationDateStarting2024: this.convertEmptyStringToUndefined(
          state.sites.map((site) => site.solarPanelsInstallationDateStarting2024).join(' | '),
        ),
        hasInjectionTariff: this.convertEmptyStringToUndefined(
          state.sites.map((site) => site.injectionTariff).join(' | '),
        ),
        hasElectricVehicle: this.convertEmptyStringToUndefined(
          state.sites.map((site) => site.hasElectricVehicle).join(' | '),
        ),
        solarPanel: this.convertEmptyStringToUndefined(state.sites.map((site) => site.solarPanelKva).join(' | ')),
        smartMeter: this.convertEmptyStringToUndefined(state.sites.map((site) => site.smartMeter).join(' | ')),
        meterType: this.convertEmptyStringToUndefined(state.sites.map((site) => site.meterType).join(' | ')),
        exclusiveNight: this.convertEmptyStringToUndefined(state.sites.map((site) => site.exclusiveNight).join(' | ')),
        offerPrice: allOffers.length > 0 ? allOffers[0]?.offerPrice : undefined,
        offerName: this.convertEmptyStringToUndefined(allOffers.length > 0 ? allOffers[0]?.offerName : undefined),
      },
    };
    allOffers.forEach(
      (offer) =>
        (data['simulator'][snakeToCamel(offer.offerName.toUpperCase().split(' ').join('_')) + 'Price'] =
          offer.offerPrice / 12),
    );
    return data;
  }

  static toDisplayTrackingDataB2b(state: State, currentLang: 'fr' | 'nl', stepName: string): DisplayTrackingData {
    const allOffers = this.getAllOffers(state.sites);
    const data: DisplayTrackingData = {
      event: 'simulatorStep',
      page: {
        title: 'TotalEnergies - Online simulation',
        publicTarget: 'pro-sme',
        language: currentLang,
        branch: 'gasElec',
        category: 'simulator',
      },
      simulator: {
        simulationStep: stepName,
        flowType: this.mapFlowTypeB2b(state),
        deliveryPointIndex: '' + state.currentSiteIndex,
        zipCode: this.convertEmptyStringToUndefined(state.sites.map((site) => site.zipCode).join(' | ')),
        energyType: this.convertEmptyStringToUndefined(state.sites.map((site) => site.energy).join(' | ')),
        consumptionKnow: this.convertEmptyStringToUndefined(
          state.sites
            .map((site) => (site.knowConsumption === undefined ? '' : site.knowConsumption ? 'yes' : 'no'))
            .join(' | '),
        ),
        companyType: this.convertEmptyStringToUndefined(
          state.sites.map((site) => (site.knowConsumption ? 'consumptionKnown' : site.consumptionHelp)).join(' | '),
        ),
        hasSolarPanel: this.convertEmptyStringToUndefined(state.sites.map((site) => site.hasSolarPanels).join(' | ')),
        solarPanelsInstallationDateStarting2024: this.convertEmptyStringToUndefined(
          state.sites.map((site) => site.solarPanelsInstallationDateStarting2024).join(' | '),
        ),
        hasInjectionTariff: this.convertEmptyStringToUndefined(
          state.sites.map((site) => site.injectionTariff).join(' | '),
        ),
        solarPanel: this.convertEmptyStringToUndefined(state.sites.map((site) => site.solarPanelKva).join(' | ')),
        smartMeter: this.convertEmptyStringToUndefined(state.sites.map((site) => site.smartMeter).join(' | ')),
        meterType: this.convertEmptyStringToUndefined(state.sites.map((site) => site.meterType).join(' | ')),
        exclusiveNight: this.convertEmptyStringToUndefined(state.sites.map((site) => site.exclusiveNight).join(' | ')),
        numberSite:
          state.currentStepUrl === '/multisite' || state.currentStepUrl === '/result' ? state.sites.length : undefined,
        offerPrice: allOffers.length > 0 ? allOffers[0]?.offerPrice : undefined,
        offerName: this.convertEmptyStringToUndefined(allOffers.length > 0 ? allOffers[0]?.offerName : undefined),
      },
    };
    allOffers.forEach(
      (offer) =>
        (data['simulator'][snakeToCamel(offer.offerName.toUpperCase().split(' ').join('_')) + 'Price'] =
          offer.offerPrice / 12),
    );
    return data;
  }

  static toErrorDisplayTrackingData(state: State, currentLang: 'fr' | 'nl', b2b: boolean): DisplayTrackingData {
    return {
      ...(b2b
        ? this.toDisplayTrackingDataB2b(state, currentLang, 'error')
        : this.toDisplayTrackingDataB2c(state, currentLang, 'error')),
      error: {
        notFound: 'https://gas-power.total.be/' + currentLang + '/',
        referrer: 'https://gas-power.total.be/' + currentLang + '/',
      },
    };
  }

  static mapFlowTypeB2b(state: State): 'low' | 'medium' | 'high' | 'all' {
    switch (state.numberOfSites) {
      case NumberOfSite.LESSER_OR_EQUAL_THAN_8:
        return 'low';
      case NumberOfSite.MORE_THAN_8:
        return 'high';
    }
    return 'all';
  }

  static mapFlowTypeB2c(state: State): 'direct' | 'light' {
    if (state.sites[0].knowConsumption === undefined) {
      return undefined;
    }
    return state.sites[0].knowConsumption ? 'direct' : 'light';
  }

  private static getLowerOffers(sites: Site[]): { offerName: string; offerPrice: number } {
    if (sites.map((site) => site.simulation).filter((val) => !!val).length === 0) {
      return { offerName: undefined, offerPrice: undefined };
    }

    const bundlesByName: Map<string, Bundles[]> = sites
      .map((site) => site.simulation)
      .flat()
      .reduce((acc, curr) => {
        acc.has(curr.name) ? acc.get(curr.name).push(curr) : acc.set(curr.name, [curr]);
        return acc;
      }, new Map<string, Bundles[]>());

    const offerNamePrices = Array.from(bundlesByName.keys()).map((key: string) => ({
      offerName: key,
      offerPrice: bundlesByName.get(key).reduce((acc, bundles) => {
        acc += (bundles.electricityAmount || 0) + (bundles.gasAmount || 0);
        return acc;
      }, 0),
    }));

    return offerNamePrices.length > 0
      ? offerNamePrices.sort((n1, n2) => n1.offerPrice - n2.offerPrice)[0]
      : { offerName: undefined, offerPrice: undefined };
  }

  private static getAllOffers(sites: Site[]): { offerName: string; offerPrice: number }[] {
    if (sites.map((site) => site.simulation).filter((val) => !!val).length === 0) {
      return [];
    }

    const bundlesByName: Map<string, Bundles[]> = sites
      .map((site) => site.simulation)
      .flat()
      .reduce((acc, curr) => {
        acc.has(curr.name) ? acc.get(curr.name).push(curr) : acc.set(curr.name, [curr]);
        return acc;
      }, new Map<string, Bundles[]>());

    const offerNamePrices = Array.from(bundlesByName.keys()).map((key: string) => ({
      offerName: key,
      offerPrice: bundlesByName.get(key).reduce((acc, bundles) => {
        acc += (bundles.electricityAmount || 0) + (bundles.gasAmount || 0);
        return acc;
      }, 0),
    }));

    return offerNamePrices.length > 0 ? offerNamePrices.sort((n1, n2) => n1.offerPrice - n2.offerPrice) : [];
  }

  private static convertEmptyStringToUndefined(str: string): string {
    return str === '' ? undefined : str;
  }
}
