import { Component, OnInit } from '@angular/core';
import { InputRadioConfig } from '../../shared/custom-form-control/input-radio-wrapper/input-radio-wrapper.component';
import { HeatingType, Site } from '../../core/state/state';
import { QuestionFacade } from '../../core/facade/question.facade';
import { UntypedFormControl, Validators } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { CompleteObservables } from 'src/app/shared/complete-observables';

@Component({
  selector: 'app-heating-type',
  templateUrl: './heating-type.component.html',
})
export class HeatingTypeComponent extends CompleteObservables implements OnInit {
  options: InputRadioConfig[] = [
    {
      id: HeatingType.ELECTRICITY,
      value: HeatingType.ELECTRICITY,
      image: './assets/img/unknown-consumption/plug-ico.svg',
      caption: `pages.unknownConsumptions.heatingEnergy.type.choices.${HeatingType.ELECTRICITY.toLowerCase()}`,
    },
    {
      id: HeatingType.GAS,
      value: HeatingType.GAS,
      image: './assets/img/unknown-consumption/gas-ico.svg',
      caption: `pages.unknownConsumptions.heatingEnergy.type.choices.${HeatingType.GAS.toLowerCase()}`,
    },
    {
      id: HeatingType.HEATING_PUMP,
      value: HeatingType.HEATING_PUMP,
      image: './assets/img/unknown-consumption/waste-energy-ico.svg',
      caption: `pages.unknownConsumptions.heatingEnergy.type.choices.${HeatingType.HEATING_PUMP.toLowerCase()}`,
    },
    {
      id: HeatingType.FUEL_OIL,
      value: HeatingType.FUEL_OIL,
      image: './assets/img/unknown-consumption/fuel-tank-ico.svg',
      caption: `pages.unknownConsumptions.heatingEnergy.type.choices.${HeatingType.FUEL_OIL.toLowerCase()}`,
    },
    {
      id: HeatingType.WOOD,
      value: HeatingType.WOOD,
      image: './assets/img/unknown-consumption/wood-log-ico.svg',
      caption: `pages.unknownConsumptions.heatingEnergy.type.choices.${HeatingType.WOOD.toLowerCase()}`,
    },
    {
      id: HeatingType.PELLET,
      value: HeatingType.PELLET,
      image: './assets/img/unknown-consumption/pellet-ico.svg',
      caption: `pages.unknownConsumptions.heatingEnergy.type.choices.${HeatingType.PELLET.toLowerCase()}`,
    },
    {
      id: HeatingType.COLLECTIVE,
      value: HeatingType.COLLECTIVE,
      image: './assets/img/unknown-consumption/heater-ico.svg',
      caption: `pages.unknownConsumptions.heatingEnergy.type.choices.${HeatingType.COLLECTIVE.toLowerCase()}`,
    },
  ];
  formControl: UntypedFormControl;
  site: Site;

  constructor(private readonly questionFacade: QuestionFacade) {
    super();
  }

  ngOnInit(): void {
    this.questionFacade
      .findSiteData((site) => site)
      .pipe(takeUntil(this.destroy$))
      .subscribe((site) => {
        this.site = site;
        this.formControl = new UntypedFormControl(site?.unknownConsumption?.heatingEnergy?.type, Validators.required);
      });
  }

  onPreviousClicked(): void {
    this.questionFacade.previous();
  }

  onNextClicked(): void {
    const site = {
      ...this.site,
      unknownConsumption: {
        ...this.site?.unknownConsumption,
        heatingEnergy: {
          ...this.site?.unknownConsumption?.heatingEnergy,
          type: this.formControl.value,
        },
      },
    };
    this.questionFacade.updateSiteData(site);
    this.questionFacade.next();
  }
}
