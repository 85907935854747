import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, Injectable, LOCALE_ID, NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { SiteNumberComponent } from './features/site-number/site-number.component';
import { ZipCodeComponent } from './features/zip-code/zip-code.component';
import { EnergyComponent } from './features/energy/energy.component';
import { ConsumptionsComponent } from './features/consumptions/consumptions.component';
import { MeterTypeComponent } from './features/meter-type/meter-type.component';
import { KnowConsumptionsComponent } from './features/know-consumptions/know-consumptions.component';
import { ResultsComponent } from './features/results/results.component';
import { MultisiteComponent } from './features/multisite/multisite.component';
import { AddSiteComponent } from './features/multisite/add-site/add-site.component';
import { ConsumptionsHelpComponent } from './features/consumptions-help/consumptions-help.component';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { DatePipe, registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import localeNl from '@angular/common/locales/nl';
import { AppRoutingModule } from './app-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ProfilsComponent } from './features/consumptions-help/profils/profils.component';
import { ErrorComponent } from './features/error/error.component';
import { TooMuchSitesComponent } from './features/too-much-sites/too-much-sites.component';
import { ProfilNotFoundComponent } from './features/profil-not-found/profil-not-found.component';
import * as Sentry from '@sentry/angular-ivy';
import { HttpErrorInterceptor } from './interceptor';
import { HasSolarPanelComponent } from './features/solar/has-solar-panel/has-solar-panel.component';
import { SolarPanelKvaComponent } from './features/solar/solar-panel-kva/solar-panel-kva.component';
import { DedicatedAccessPointComponent } from './features/solar/dedicated-access-point/dedicated-access-point.component';
import { environment } from '../environments/environment';
import { SmartMeterComponent } from './features/smart-meter/smart-meter.component';
import { AveragePeakComponent } from './features/peak-info/average-peak.component';
import { CoreModule } from './core/core.module';
import { LayoutModule } from './core/layout/layout.module';
import { SitesComponent } from './features/results/sites/sites.component';
import { SiteDetailsComponent } from './shared/site/site-details/site-details.component';
import { i18nInitializer, I18nService } from './core/service/i18n/i18n.service';
import { PromoComponent } from './features/results/promo/promo.component';
import { HasElectricVehicleComponent } from './features/has-electric-vehicle/has-electric-vehicle.component';
import { HabitationVolumeComponent } from './features/habitation-volume/habitation-volume.component';
import { HabitationYearComponent } from './features/habitation-year/habitation-year.component';
import { HabitantNumberComponent } from './features/habitant-number/habitant-number.component';
import { HabitationTypeComponent } from './features/habitation-type/habitation-type.component';
import { HeatingTypeComponent } from './features/heating-type/heating-type.component';
import { BoilerYearComponent } from './features/boiler-year/boiler-year.component';
import { HeatingPumpTypeComponent } from './features/heating-pump-type/heating-pump-type.component';
import { SiteDetailsMultisiteWrapperComponent } from './features/multisite/site-details-multisite-wrapper/site-details-multisite-wrapper.component';
import { CustomFormControlModule } from './shared/custom-form-control/custom-form-control.module';
import { SharedModule } from './shared/shared.module';
import { ResultsB2cComponent } from './features/results/results-b2c/results-b2c.component';
import { ResultsB2bComponent } from './features/results/result-b2b/results-b2b.component';
import { B2cContactComponent } from './features/results/results-b2c/b2c-contact/b2c-contact.component';
import { ProductSummaryComponent } from './features/results/results-b2c/product-summary/product-summary.component';
import { SimulationInfoCardComponent } from './features/results/results-b2c/product-summary/simulation-info-card/simulation-info-card.component';
import { CookieService } from 'ngx-cookie-service';
import { register } from 'swiper/element/bundle';
import { BundlesListComponent } from './features/results/bundles/bundles-list.component';
import { BundleComponent } from './features/results/bundles/bundle/bundle.component';
import { CustomFormParentComponent } from './shared/custom-form-control/custom-form-parent.component';
import { PvCertificationComponent } from './features/pv-certification/pv-certification.component';

// register swiper objects
register();

registerLocaleData(localeFr, 'fr');
registerLocaleData(localeNl, 'nl');

export function createTranslateLoader(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, environment.languageAssetsPath, '.json');
}

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  handleError(error): void {
    Sentry.captureException(error.originalError || error);
  }
}
export function getErrorHandler(): ErrorHandler {
  if (environment.production) {
    return new SentryErrorHandler();
  }
  return new ErrorHandler();
}

@NgModule({
  declarations: [
    AppComponent,
    SiteNumberComponent,
    ZipCodeComponent,
    EnergyComponent,
    ConsumptionsComponent,
    MeterTypeComponent,
    KnowConsumptionsComponent,
    ResultsComponent,
    MultisiteComponent,
    ConsumptionsHelpComponent,
    ProfilsComponent,
    ErrorComponent,
    TooMuchSitesComponent,
    ProfilNotFoundComponent,
    HasSolarPanelComponent,
    SolarPanelKvaComponent,
    DedicatedAccessPointComponent,
    SmartMeterComponent,
    AveragePeakComponent,
    CustomFormParentComponent,
    SitesComponent,
    SiteDetailsComponent,
    AddSiteComponent,
    PromoComponent,
    HasElectricVehicleComponent,
    HabitationVolumeComponent,
    HabitationYearComponent,
    HabitantNumberComponent,
    HabitationTypeComponent,
    HeatingTypeComponent,
    BoilerYearComponent,
    HeatingPumpTypeComponent,
    SiteDetailsMultisiteWrapperComponent,
    ResultsB2cComponent,
    ResultsB2bComponent,
    B2cContactComponent,
    ProductSummaryComponent,
    SimulationInfoCardComponent,
    BundlesListComponent,
    BundleComponent,
    PvCertificationComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    CustomFormControlModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    SharedModule,
    CoreModule,
    LayoutModule,
  ],
  providers: [
    DatePipe,
    CookieService,
    { provide: LOCALE_ID, useValue: 'fr-BE' },
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
    { provide: ErrorHandler, useFactory: getErrorHandler },
    {
      provide: 'LANGUAGES',
      useValue: ['nl', 'fr'],
    },
    {
      provide: 'DEFAULT_LANGUAGE',
      useValue: 'nl',
    },
    {
      provide: APP_INITIALIZER,
      useFactory: i18nInitializer,
      multi: true,
      deps: [I18nService, TranslateService, HttpClient],
    },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
