export const profils = {
  SMALL_SHOP: {
    title: 'pages.consumptionsProfilsList.formElements.SMALL_SHOP.label',
    elecConsumptionMono: 12200,
    elecConsumptionHigh: null,
    elecConsumptionLow: null,
    elecConsumptionExclusiveNight: null,
    gasConsumption: 13200,
    id: 'SMALL_SHOP',
  },
  LIBERAL_PROFESSION: {
    title: 'pages.consumptionsProfilsList.formElements.LIBERAL_PROFESSION.label',
    elecConsumptionMono: 6700,
    elecConsumptionHigh: null,
    elecConsumptionLow: null,
    elecConsumptionExclusiveNight: null,
    gasConsumption: 14000,
    id: 'LIBERAL_PROFESSION',
  },
  RESTAURNT_OR_CAFE: {
    title: 'pages.consumptionsProfilsList.formElements.RESTAURNT_OR_CAFE.label',
    elecConsumptionMono: 13000,
    elecConsumptionHigh: null,
    elecConsumptionLow: null,
    elecConsumptionExclusiveNight: null,
    gasConsumption: 19000,
    id: 'RESTAURNT_OR_CAFE',
  },
  PRATICE_AREA: {
    title: 'pages.consumptionsProfilsList.formElements.PRATICE_AREA.label',
    elecConsumptionMono: 5900,
    elecConsumptionHigh: null,
    elecConsumptionLow: null,
    elecConsumptionExclusiveNight: null,
    gasConsumption: 15000,
    id: 'PRATICE_AREA',
  },
  OFFICE: {
    title: 'pages.consumptionsProfilsList.formElements.OFFICE.label',
    elecConsumptionMono: 8200,
    elecConsumptionHigh: null,
    elecConsumptionLow: null,
    elecConsumptionExclusiveNight: null,
    gasConsumption: 13300,
    id: 'OFFICE',
  },
  CONSTRUCTION: {
    title: 'pages.consumptionsProfilsList.formElements.CONSTRUCTION.label',
    elecConsumptionMono: 6100,
    elecConsumptionHigh: null,
    elecConsumptionLow: null,
    elecConsumptionExclusiveNight: null,
    gasConsumption: 10600,
    id: 'CONSTRUCTION',
  },
  AGRICULTURE: {
    title: 'pages.consumptionsProfilsList.formElements.AGRICULTURE.label',
    elecConsumptionMono: 14800,
    elecConsumptionHigh: null,
    elecConsumptionLow: null,
    elecConsumptionExclusiveNight: null,
    gasConsumption: 2500,
    id: 'AGRICULTURE',
  },
  ENTERTAINMENT_AND_RECREATION: {
    title: 'pages.consumptionsProfilsList.formElements.ENTERTAINMENT_AND_RECREATION.label',
    elecConsumptionMono: 9400,
    elecConsumptionHigh: null,
    elecConsumptionLow: null,
    elecConsumptionExclusiveNight: null,
    gasConsumption: 14200,
    id: 'ENTERTAINMENT_AND_RECREATION',
  },
  INDUSTRY: {
    title: 'pages.consumptionsProfilsList.formElements.INDUSTRY.label',
    elecConsumptionMono: 21300,
    elecConsumptionHigh: null,
    elecConsumptionLow: null,
    elecConsumptionExclusiveNight: null,
    gasConsumption: 43300,
    id: 'INDUSTRY',
  },
};

export type ProfilName =
  | 'SMALL_SHOP'
  | 'LIBERAL_PROFESSION'
  | 'RESTAURNT_OR_CAFE'
  | 'PRATICE_AREA'
  | 'OFFICE'
  | 'CONSTRUCTION'
  | 'AGRICULTURE'
  | 'ENTERTAINMENT_AND_RECREATION'
  | 'INDUSTRY'
  | '';
