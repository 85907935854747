import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { QuestionFacade } from '../../core/facade/question.facade';
import { InputRadioConfig } from '../../shared/custom-form-control/input-radio-wrapper/input-radio-wrapper.component';
import { HabitationType, HabitationVolume, Site } from '../../core/state/state';
import { takeUntil } from 'rxjs/operators';
import { CompleteObservables } from 'src/app/shared/complete-observables';

@Component({
  selector: 'app-habitation-volume',
  templateUrl: './habitation-volume.component.html',
})
export class HabitationVolumeComponent extends CompleteObservables implements OnInit {
  readonly flat = [
    HabitationVolume.LESS_THAN_50,
    HabitationVolume.BETWEEN_50_AND_60,
    HabitationVolume.BETWEEN_61_AND_80,
    HabitationVolume.BETWEEN_81_AND_100,
    HabitationVolume.BETWEEN_101_AND_120,
    HabitationVolume.BETWEEN_121_AND_150,
    HabitationVolume.MORE_THAN_150,
  ];
  readonly house = [
    HabitationVolume.LESS_THAN_60,
    HabitationVolume.BETWEEN_61_AND_80,
    HabitationVolume.BETWEEN_81_AND_110,
    HabitationVolume.BETWEEN_111_AND_150,
    HabitationVolume.BETWEEN_151_AND_200,
    HabitationVolume.BETWEEN_201_AND_250,
    HabitationVolume.MORE_THAN_250,
  ];
  readonly bigHouse = [
    HabitationVolume.LESS_THAN_80,
    HabitationVolume.BETWEEN_81_AND_110,
    HabitationVolume.BETWEEN_111_AND_150,
    HabitationVolume.BETWEEN_151_AND_200,
    HabitationVolume.BETWEEN_201_AND_250,
    HabitationVolume.MORE_THAN_250,
  ];
  options: InputRadioConfig[] = [];
  formControl: UntypedFormControl;
  site: Site;

  constructor(private readonly questionFacade: QuestionFacade) {
    super();
  }

  ngOnInit(): void {
    this.questionFacade
      .findSiteData((site) => site)
      .pipe(takeUntil(this.destroy$))
      .subscribe((site) => {
        this.site = site;
        const { buildingType } = site.unknownConsumption.building;
        const choices = this.getOptions(buildingType);
        this.options = this.setOptions(choices);
        this.formControl = new UntypedFormControl(site?.unknownConsumption?.building?.area, Validators.required);
      });
  }

  onPreviousClicked(): void {
    this.questionFacade.previous();
  }

  onNextClicked(): void {
    const site = {
      ...this.site,
      unknownConsumption: {
        ...this.site?.unknownConsumption,
        building: {
          ...this.site?.unknownConsumption?.building,
          area: this.formControl.value,
        },
      },
    };
    this.questionFacade.updateSiteData(site);
    this.questionFacade.next();
  }

  private getOptions(buildingType: HabitationType | string): HabitationVolume[] {
    if (buildingType === HabitationType.FLAT) {
      return this.flat;
    }
    if (buildingType === HabitationType.FOUR) {
      return this.bigHouse;
    }
    return this.house;
  }

  private setOptions(choices: HabitationVolume[]): InputRadioConfig[] {
    return choices.map((choice: HabitationVolume) => ({
      id: choice,
      value: choice,
      caption: `pages.unknownConsumptions.building.area.choices.${choice.toLowerCase()}`,
    }));
  }
}
