import { Injectable } from '@angular/core';
import { Question } from './questions/questions-common';
import { State, Type } from '../state/state';
import { ORDERED_QUESTION_B2C_CONFIG } from './questions/config/questions-config-b2c';
import { ORDERED_QUESTION_B2B_CONFIG } from './questions/config/questions-config-b2b';

@Injectable({
    providedIn: 'root'
})
export class QuestionOrderHandlerService {


    getNextQuestionUrl(state: State): string | null {
        const type = state.type;
        const currentQuestion = this.findQuestion(state.currentStepUrl, type);
        const nextQuestion = currentQuestion.getNext(state, this.findQuestionsConfig(type));
        return nextQuestion ? nextQuestion.getUrl() : undefined;
    }

    getPreviousQuestionUrl(state: State): string | null {
        const type = state.type;
        const currentQuestion = this.findQuestion(state.currentStepUrl, type);
        const previous = currentQuestion.getPrevious(state, this.findQuestionsConfig(type));
        return previous ? previous.getUrl() : undefined;
    }

    isLast(state: State): boolean {
        return this.getNextQuestionUrl(state) === undefined;
    }

    getLastStep(state: State): string {
        const questions = this.findQuestionsConfig(state.type);
        return questions[questions.length - 1].getUrl();
    }

    isFirst(state: State): boolean {
        return this.getPreviousQuestionUrl(state) === undefined;
    }

    remainingSteps(state: State): number {
        const type = state.type;
        const questionConfig = this.findQuestionsConfig(state.type);
        let currentQuestion = this.findQuestion(state.currentStepUrl, type);
        let remainingSteps = 0;
        while (currentQuestion !== undefined) {
            remainingSteps++;
            currentQuestion = currentQuestion.getNext(state, questionConfig);
        }
        return remainingSteps;
    }

    getCurrentStepDisplayTrackingValue(state: State): string {
        const currentQuestion = this.findQuestion(state.currentStepUrl, state.type);
        return currentQuestion.getDisplayTackingDataValue(state);
    }

    private findQuestion(currentStep: string, type: Type) {
        return this.findQuestionsConfig(type).find(question => question.getUrl() === currentStep);
    }

    private findQuestionsConfig(type: Type): Question[] {
        return type === Type.B2C ? ORDERED_QUESTION_B2C_CONFIG : ORDERED_QUESTION_B2B_CONFIG;
    }

}
