import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { InputRadioConfig } from '../../../shared/custom-form-control/input-radio-wrapper/input-radio-wrapper.component';
import { QuestionFacade } from '../../../core/facade/question.facade';
import { CompleteObservables } from 'src/app/shared/complete-observables';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-solar-panels',
  templateUrl: './has-solar-panel.component.html',
})
export class HasSolarPanelComponent extends CompleteObservables implements OnInit {
  options: InputRadioConfig[] = [
    {
      id: 'true',
      value: true,
      image: './assets/img/yes-check.svg',
      caption: 'pages.hasSolarPanels.formElements.yes.label',
      trackingValue: 'hasSolarPanels',
    },
    {
      id: 'false',
      value: false,
      image: './assets/img/no-cross.svg',
      caption: 'pages.hasSolarPanels.formElements.no.label',
      trackingValue: 'hasNoSolarPanel',
    },
  ];

  formControl: UntypedFormControl;

  constructor(private readonly questionFacade: QuestionFacade) {
    super();
  }

  ngOnInit(): void {
    this.questionFacade
      .findSiteData((site) => site.hasSolarPanels)
      .pipe(takeUntil(this.destroy$))
      .subscribe((value) => (this.formControl = new UntypedFormControl(value, Validators.required)));
  }

  onPreviousClicked() {
    this.questionFacade.previous();
  }

  onNextClicked() {
    this.questionFacade.updateSiteData({ hasSolarPanels: this.formControl.value });
    this.questionFacade.next();
  }
}
