import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-simulation-info-card',
  templateUrl: './simulation-info-card.component.html',
  styleUrls: ['./simulation-info-card.component.scss']
})
export class SimulationInfoCardComponent {
   @Input() title: string;
   @Input() img: string;
}
