import { Injectable } from '@angular/core';

declare let dataLayer;

@Injectable({
  providedIn: 'root',
})
export class TrackingApi {
  sendDisplayEvent(trackingData: DisplayTrackingData) {
    setTimeout(() => dataLayer.push(trackingData));
  }

  sendSimulatorInteractionEvent(trackingData: SimulatorInteractionTrackingData) {
    dataLayer.push(trackingData);
  }
}

export interface SimulatorInteractionTrackingData {
  event: 'Contact-interaction' | 'Navigation-interaction' | 'Simulator-interaction';
  eventCategory: 'Navigation' | 'Simulator' | 'Contact';
  eventAction: 'Click' | 'Click language' | 'Select';
  component: {
    name: 'Contact' | 'Navigation' | 'Simulator';
  };
  clickedProduct?: string;
  clickedProductPrice?: string;
  eventLabel: string;
}

export interface DisplayTrackingData {
  event: 'simulatorStep' | 'OnboardingError';
  page: Page;
  simulator?: Simulator;
  error?: Error;
}

export interface Page {
  title: string;
  branch: string;
  publicTarget: 'pro-sme' | 'b-to-c';
  language: 'fr' | 'nl';
  category: 'simulator';
}

export interface Error {
  notFound: string;
  referrer: string;
}

export interface Workflow {
  stepName: string;
}

export class Simulator {
  simulationStep: string;
  flowType?: 'low' | 'medium' | 'high' | 'all' | 'direct' | 'light';
  zipCode?: string;
  energyType?: string;
  consumptionKnow?: string;
  companyType?: string;
  hasSolarPanel?: string;
  solarPanelsInstallationDateStarting2024?: string;
  hasInjectionTariff?: string;
  hasElectricVehicle?: string;
  solarPanel?: string;
  smartMeter?: string;
  meterType?: string;
  exclusiveNight?: string;
  numberSite?: number;
  homeType?: string;
  deliveryPointIndex?: string;
  offerPrice: number;
  offerName: string;
}
