import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Site } from '../../../core/state/state';
import { SiteNameData } from '../../multisite/multisite.component';
import { swiperDefaultConfig } from 'src/app/shared/swiper/swiper.directive';
import { SwiperOptions } from 'swiper/types';
import { Bundles } from 'src/app/core/bundle/bundle';

@Component({
  selector: 'app-sites',
  templateUrl: './sites.component.html',
  styleUrls: ['./sites.component.scss'],
})
export class SitesComponent {
  @Input() sitesDisplay: SiteDisplay[] = [];
  @Output() saveClicked = new EventEmitter<SiteNameData>();
  @Output() deleteClicked = new EventEmitter<number>();
  @Output() siteDetailsClicked = new EventEmitter<number>();

  displayChevron = true;
  renameSiteDisplay: number = null;
  siteNameData: SiteNameData;
  selectedSiteNumber: number = null;
  swiperConfig: SwiperOptions = {
    ...swiperDefaultConfig,
    pagination: false,
    spaceBetween: 20,
    breakpoints: {
      680: {
        slidesPerView: 2,
        // IMPORTANT!!!
        // The default values are important to set when the resolution is changed from higher to smaller
        grid: {
          rows: 1,
          fill: 'column',
        },
      },
      1024: {
        slidesPerView: 3,
        grid: {
          rows: 3,
          fill: 'row',
        },
      },
    },
  };

  showSiteDetails(i: number) {
    this.selectedSiteNumber = i;
    this.siteDetailsClicked.emit();
    this.displayChevron = false;
  }

  resetSelectedSiteSimulation() {
    this.selectedSiteNumber = null;
    this.displayChevron = true;
  }
}

export interface SiteDisplay {
  bundles?: Bundles[];
  simulationId?: string;
  site: Site;
}
