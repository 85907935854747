import { Component, Input } from '@angular/core';
import { fadeInFadeOut } from '../animations';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  animations: [fadeInFadeOut],
})
export class LoaderComponent {
  @Input() isDisplay ? = true;
}
