import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlertComponent } from './alert/alert.component';
import { CardComponent } from './card/card.component';
import { CustomFormControlModule } from './custom-form-control/custom-form-control.module';
import { FormCardComponent } from './form-card/form-card.component';
import { NavigationComponent } from './navigation/navigation.component';
import { TitleComponent } from './title/title.component';
import { LoaderModule } from './loader/loader.module';
import { IconsComponent } from './icons/icons.component';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { SiteModule } from './site/site.module';
import { ErrorBackgroundComponent } from './error-background/error-background.component';
import { SwitcherComponent } from './switcher/switcher.component';
import { SwiperDirective } from './swiper/swiper.directive';
import { TooltipComponent } from './tooltip/tooltip.component';

@NgModule({
  declarations: [
    AlertComponent,
    CardComponent,
    FormCardComponent,
    NavigationComponent,
    TitleComponent,
    IconsComponent,
    ErrorBackgroundComponent,
    SwitcherComponent,
    SwiperDirective,
    TooltipComponent
  ],
  exports: [
    AlertComponent,
    CardComponent,
    FormCardComponent,
    NavigationComponent,
    TitleComponent,
    IconsComponent,
    LoaderModule,
    SiteModule,
    CustomFormControlModule,
    ErrorBackgroundComponent,
    SwitcherComponent,
    SwiperDirective,
    TooltipComponent
  ],
  imports: [CommonModule, TranslateModule, ReactiveFormsModule, LoaderModule, SiteModule, CustomFormControlModule],
})
export class SharedModule {}
