import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-error-background',
  templateUrl: './error-background.component.html',
  styleUrls: ['./error-background.component.scss']
})
export class ErrorBackgroundComponent {

  @Input() errorTitle = 'pages.404.unavailable';


}
