import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { InputTextConfig } from '../../../custom-form-control/zip-code-input/input-text.component';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-rename-site',
  templateUrl: './rename-site.component.html',
  styleUrls: ['./rename-site.component.scss'],
})
export class RenameSiteComponent implements OnInit {
  @Output() formReady = new EventEmitter<UntypedFormControl>();
  @Input() siteIndex: number;

  INPUT_CONFIG: InputTextConfig = {
    labelKey: 'pages.result.changeNamePopUp.renameSite',
    placeHolder: 'pages.result.changeNamePopUp.renameSitePlaceholder',
    errors: new Map([['required', 'pages.result.changeNamePopUp.error']]),
    maxLength: 30,
  };
  formControl = new UntypedFormControl('');

  ngOnInit(): void {
    this.formReady.emit(this.formControl);
  }
}
