import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ProfilName, profils } from './profils';
import { TranslateService } from '@ngx-translate/core';
import { Energy, MeterType } from 'src/app/core/state/state';

@Component({
  selector: 'app-profils',
  templateUrl: './profils.component.html',
  styleUrls: ['./profils.component.scss'],
})
export class ProfilsComponent implements OnInit, OnChanges {
  readonly Energy = Energy;
  @Output() getProfil: EventEmitter<{ name: ProfilName; profil: Profil; site: number }> = new EventEmitter<{
    name: ProfilName;
    profil: Profil;
    site: number;
  }>();
  @Input() energy: Energy;
  @Input() site: number;
  @Input() flow: string;
  @Input() value: ProfilName;
  @Input() meterType: MeterType;
  @Input() exclusiveNight: boolean;
  colNumber: number;
  profils = profils;
  form: UntypedFormGroup = new UntypedFormGroup({
    profil: new UntypedFormControl('', [Validators.required]),
  });

  constructor(public translate: TranslateService) {}

  static calculePercent(value: number, percent: number): number {
    value = Number(value);
    percent = Number(percent);
    return (value * percent) / 100;
  }

  static hasValues(object: object): boolean {
    return Object.keys(object).some((property) => object[property]);
  }

  /*
   * This method will remove properties that don't contain the word "consumption" in their key
   * ie: { elecConsumptionHigh: 3, elecInjectionHigh: 4 } becomes { elecConsumptionHigh: 3 }
   */
  static filterConsumption(consumptionProfil: Profil): Profil | object {
    const consumptions = Object.keys(consumptionProfil).filter((property) =>
      property.toLowerCase().includes('consumption'),
    );
    return consumptions.reduce((obj, key) => {
      obj[key] = consumptionProfil[key];
      return obj;
    }, {});
  }

  static applyMeterTypeOnProfilConsumptions(
    consumptionProfil: Profil,
    meterType: MeterType,
    exclusiveNight = false,
  ): Profil {
    if (
      typeof consumptionProfil === 'undefined' ||
      !ProfilsComponent.hasValues(ProfilsComponent.filterConsumption(consumptionProfil))
    ) {
      return;
    }
    switch (meterType) {
      case MeterType.MONO:
        if (exclusiveNight) {
          // Mono + Exclusif Nuit : Jour = 56%, Exclusif Nuit = 44%
          consumptionProfil.elecConsumptionHigh =
            ProfilsComponent.calculePercent(consumptionProfil.elecConsumptionMono, 56) || null;
          consumptionProfil.elecConsumptionExclusiveNight =
            ProfilsComponent.calculePercent(consumptionProfil.elecConsumptionMono, 44) || null;
        } else {
          // Mono-horaire: Jour = 100%
          consumptionProfil.elecConsumptionHigh = consumptionProfil.elecConsumptionMono;
        }
        break;

      case MeterType.DUAL:
        if (exclusiveNight) {
          // Bi-horaire + Exclusif Nuit : Jour = 29%, Nuit = 28%, Exclusif nuit = 42%
          consumptionProfil.elecConsumptionHigh =
            ProfilsComponent.calculePercent(consumptionProfil.elecConsumptionMono, 29) || null;
          consumptionProfil.elecConsumptionLow =
            ProfilsComponent.calculePercent(consumptionProfil.elecConsumptionMono, 28) || null;
          consumptionProfil.elecConsumptionExclusiveNight =
            ProfilsComponent.calculePercent(consumptionProfil.elecConsumptionMono, 42) || null;
        } else {
          // Bi-horaire: Jour = 56%, Nuit = 44%
          consumptionProfil.elecConsumptionHigh =
            ProfilsComponent.calculePercent(consumptionProfil.elecConsumptionMono, 56) || null;
          consumptionProfil.elecConsumptionLow =
            ProfilsComponent.calculePercent(consumptionProfil.elecConsumptionMono, 44) || null;
        }
        break;
    }

    return consumptionProfil;
  }

  ngOnInit(): void {
    this.form.get('profil').patchValue(this.value);
    this.form.valueChanges.subscribe((formElement) => {
      if (formElement.profil === null) {
        return;
      }
      setTimeout(
        () => {
          this.getProfil.emit({
            name: this.profils[formElement.profil].id,
            profil: ProfilsComponent.applyMeterTypeOnProfilConsumptions(
              this.profils[formElement.profil],
              this.meterType,
              this.exclusiveNight,
            ),
            site: this.flow === 'ONE' ? null : this.site,
          });
        },
        this.flow === 'ONE' ? 0 : 300,
      );
    });
    this.colNumber = this.flow === 'ONE' ? 3 : 2;
  }

  ngOnChanges(data: SimpleChanges): void {
    if (Object.prototype.hasOwnProperty.call(data, 'site')) {
      this.form.get('profil').patchValue(null);
    }
  }
}

export interface Profil {
  title: string;
  elecConsumptionMono: number;
  elecConsumptionHigh: number;
  elecConsumptionLow: number;
  elecConsumptionExclusiveNight: number;
  gasConsumption: number;
  id: string;
}
